import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/models/fo/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-signup',
  templateUrl: './fo-signup.component.html',
  styleUrls: ['./fo-signup.component.css']
})
export class FoSignupComponent implements OnInit {
  public Client: ClientModel;
  public submitted = false;
  public validatePassword = "";
  public acceptedConditions = false;
  constructor(
    private router: Router,
    private clientService: ClientsService,
    private userService: UsersBOService
  ) { }

  ngOnInit(): void {
    if (this.clientService.setLoginValue()) {
      this.router.navigate(['fo/client/account']);
    }
    this.Client = new ClientModel();
  }

  // public openLoginModal() {
  //   this.loginModal.open(FoLoginComponent, {
  //     height: 'auto',
  //     width: 'auto',
  //     minWidth: '400px',
  //     maxHeight: '650px',
  //     panelClass: 'modal-transparent',
  //     disableClose: false,
  //   });
  // }

  public saveClient() {
    if (!this.acceptedConditions) {
      Swal.fire({
        icon: 'info',
        title: 'Atención!',
        text:
          'Debes aceptar los términos y condiciones para crear tu cuenta en Carros Fácil',
      });
      return;
    }
    this.submitted = true;
    this.clientService.postClient(this.Client).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        title: 'En hora buena!',
        text:
          'Registro completado. Te envíamos un correo de confirmacion para activar tu cuenta.',
      });
      this.router.navigate(['/fo/rent-cars']);

    }, (error) => {
      this.submitted = false;
      Swal.fire({
        icon: error.error.statusCode !== 400 ? 'info' : 'error',
        title: error.error.title,
        text: error.error.message,
      });
    });
  }

  public openDocument() {
    this.userService.getDocumentByName('Términos-y-condiciones-Carros-Facil Clientes.html').subscribe((data: any) => {
      var binary = atob(data.object.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      var blob = new Blob([view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      window.open(url);
    })

  }

  public checkedChange(event) {
    this.acceptedConditions = event.checked;
  }
}
