import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient, private globals: Globals) { }

  public getByCompany() {
    const url_api =
      this.globals.urlApi + `companies-payments/by-company`;
    return this.http.get(url_api);
  }

  public getByClient() {
    const url_api =
      this.globals.urlApi + `client-payments/by-client`;
    return this.http.get(url_api);
  }

  public async createPaypalOrder(amount: number, coinType: string): Promise<any> {
    const url_api =
      this.globals.urlApi + `client-payments/create-order`;
    return this.http.post(url_api, { amount: amount, coinType: coinType }).toPromise();
  }

  public captureOrder(orderId: string) {
    const url_api =
      this.globals.urlApi + `client-payments/capture-order`;
    return this.http.post(url_api, {orderId: orderId});
  }
}
