<div class="row pull-right" style="margin-right: 3px">
  <button
    type="button"
    (click)="closeDialog()"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    data-toggle="tooltip"
    data-placement="top"
    title="Cerrar"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form
  name="vehicleForm"
  #vehicleForm="ngForm"
  autocomplete="off"
  (ngSubmit)="
    vehicleForm.valid &&
      selectMake.valid &&
      selectModels.valid &&
      selectYears.valid &&
      selectFuel.valid &&
      selectCoins.valid &&
      saveCar()
  "
>
  <div class="row" style="margin-top: 24px">
    <div class="col">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Marca"
          name="marca"
          [(ngModel)]="vehicle.makeId"
          required
          (selectionChange)="getModelsByMakeId(selectMake.value)"
          [formControl]="selectMake"
          [disabled]="hasBookings || hasBeenBookings"
        >
          <mat-option *ngFor="let m of makes" [value]="m?._id">
            {{ m?.name | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="selectMake.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Modelo"
          name="Modelo"
          required
          [(ngModel)]="vehicle.modelId"
          [formControl]="selectModels"
          required
          [disabled]="hasBookings || hasBeenBookings"
        >
          <mat-option *ngFor="let m of models" [value]="m?._id">
            {{ m?.name | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="selectModels.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Año"
          name="year"
          [formControl]="selectYears"
          required
          [(ngModel)]="vehicle.year"
          [disabled]="hasBookings || hasBeenBookings"
        >
          <mat-option *ngFor="let y of years" [value]="y?.year">{{
            y?.year
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="selectYears.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="example-full-width">
        <mat-label>Placa</mat-label>
        <input
          matInput
          name="placa"
          appearance="legacy"
          required
          name="Placa"
          #Placa="ngModel"
          [(ngModel)]="vehicle.placa"
          [disabled]="hasBookings"
        />
        <mat-error *ngIf="Placa.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="example-full-width">
        <mat-label>Precio diario $</mat-label>
        <input
          matInput
          appearance="legacy"
          required
          name="Price"
          #Price="ngModel"
          [(ngModel)]="vehicle.priceByDay"
          type="number"
        />
        <mat-error *ngIf="Price.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Moneda"
          name="moneda"
          required
          [(ngModel)]="vehicle.coinType"
          [formControl]="selectCoins"
          required
          [disabled]="hasBookings"
        >
          <mat-option value="USD"> USD </mat-option>
        </mat-select>
        <mat-error *ngIf="selectCoins.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Tipo de vehículo"
          name="tipo"
          [formControl]="selectTypes"
          required
          [(ngModel)]="vehicle.vehicleTypeId"
          [disabled]="hasBookings"
        >
          <mat-option *ngFor="let t of types" [value]="t?._id">
            {{ t?.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="selectTypes.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="example-full-width">
        <mat-select
          placeholder="Combustible"
          name="combustible"
          required
          [(ngModel)]="vehicle.fuelTypeId"
          [formControl]="selectFuel"
          required
          [disabled]="hasBookings"
        >
          <mat-option *ngFor="let f of fuelTypes" [value]="f?._id">
            {{ f?.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="selectFuel.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div>
    <div class="row notes-div">
        <p *ngIf="hasBookings" class="booking-alerts">
          Este vehículo tiene reservas programadas. Solo puede editar el precio.
        </p>
        <p *ngIf="!hasBookings && hasBeenBookings" class="booking-alerts">
          Los vehículos que ya han sido reservados algunos campos no pueden ser
          editados.
        </p>
    </div>
    <hr />
    <div class="text-center">
      <button
        class="btn btn-primary btn-round bo-save-btn"
        mat-button
        type="submit"
      >
        Guardar
      </button>
    </div>
    <hr />
    <file-drop-component
      [savedFiles]="chargedImg"
      (addedImageEvent)="addImage($event)"
      (deleteImageEvent)="deleteImage($event)"
    ></file-drop-component>
  </div>
</form>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
