<div class="wrapper wrapper-full-page">
  <div class="container" style="margin-top: 40px">
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <div class="card card-signup">
          <div class="card-header card-header-success text-center">
            <h2 class="card-title">Registrate</h2>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 ml-auto">
                <img
                  src="https://res.cloudinary.com/carros-facil/image/upload/v1651626867/assets/logo-big_ktnbla.jpg"
                  class="img-fluid set-responsive-img"
                  alt="Responsive image"
                />
              </div>
              <div class="col-md-8 mr-auto">
                <form
                  name="UserForm"
                  #UserForm="ngForm"
                  autocomplete="off"
                  (ngSubmit)="
                    UserForm.valid &&
                      selectCountries.valid &&
                      selectCities.valid &&
                      createBOUser()
                  "
                >
                  <div class="row set-resposive-form">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Nombre Rent a Car</mat-label>
                        <input
                          matInput
                          [(ngModel)]="user.name"
                          name="name"
                          #name="ngModel"
                          required
                          type="text"
                        />
                        <mat-icon matSuffix>home</mat-icon>
                        <mat-error *ngIf="name.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field class="example-full-width">
                        <mat-label>RNC</mat-label>
                        <input
                          matInput
                          [(ngModel)]="user.rnc"
                          name="rnc"
                          #rnc="ngModel"
                          appearance="legacy"
                          required
                          style="font-family: Poppins"
                          minlength="9"
                          maxlength="11"
                          pattern="^[0-9]*$"
                          appNumbersOnly
                        />
                        <mat-icon matSuffix>contact_page</mat-icon>
                        <mat-error *ngIf="rnc.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="rnc.errors?.minlength">
                          Este campo debe contener al menos 9 dígitos
                        </mat-error>
                        <mat-error *ngIf="rnc.errors?.pattern">
                          Ingresa solo números en este campo
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Correo</mat-label>
                        <input
                          matInput
                          type="email"
                          name="email"
                          #email="ngModel"
                          email="true"
                          [(ngModel)]="user.email"
                          required
                        />
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="email.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="email.errors?.email">
                          Este correo no es válido
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Contraseña</mat-label>
                        <input
                          matInput
                          type="password"
                          name="password"
                          #password="ngModel"
                          [(ngModel)]="user.password"
                          required
                          minlength="8"
                        />
                        <mat-icon matSuffix>lock</mat-icon>
                        <mat-error *ngIf="password.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="password.errors?.minlength">
                          Su clave debe contener al menos 8 dígitos
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Repetir Contraseña</mat-label>
                        <input
                          matInput
                          type="password"
                          [(ngModel)]="samePasswords"
                          name="SecondPassword"
                          #SecondPassword="ngModel"
                          required
                        />
                        <mat-icon matSuffix>lock</mat-icon>
                        <mat-error *ngIf="SecondPassword.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                      </mat-form-field>
                      <div
                        *ngIf="
                          samePasswords.length > 0 &&
                          user.password != samePasswords
                        "
                        style="color: red; font-size: 10.5px; margin-top: -18px"
                      >
                        Las contraseñas no coinciden
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Direccion</mat-label>
                        <input
                          name="Direccion"
                          #Direccion="ngModel"
                          [(ngModel)]="user.address"
                          matInput
                          required
                        />
                        <mat-icon matSuffix>place</mat-icon>
                        <mat-error *ngIf="Direccion.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field class="example-full-width">
                        <mat-select
                          placeholder="Pais"
                          name="country"
                          [(ngModel)]="countryId"
                          [formControl]="selectCountries"
                          required
                          (selectionChange)="GetCities(countrySelect.value)"
                          #countrySelect
                          disabled
                        >
                          <mat-option
                            *ngFor="let c of countries"
                            [value]="c._id"
                          >
                            {{ c?.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="selectCountries.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field class="example-full-width">
                        <mat-select
                          placeholder="Ciudad"
                          name="city"
                          [(ngModel)]="user.cityId"
                          [formControl]="selectCities"
                          required
                        >
                          <mat-option *ngFor="let b of cities" [value]="b._id">
                            {{ b?.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="selectCities.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Nombre Propietario</mat-label>
                        <input
                          matInput
                          type="text"
                          name="Owner"
                          #Owner="ngModel"
                          [(ngModel)]="user.ownerName"
                          required
                          pattern="[a-zA-Z ]*"
                        />
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="Owner.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="Owner.errors?.pattern">
                          Solo se permiten letras y espacios en este campo
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Cédula</mat-label>
                        <input
                          matInput
                          name="cedula"
                          #cedula="ngModel"
                          [(ngModel)]="user.document"
                          required
                          mask="000-0000000-0"
                          pattern="^[0-9]*$"
                        />

                        <mat-icon matSuffix>portrait</mat-icon>
                        <mat-error *ngIf="cedula.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="cedula.errors?.pattern">
                          Ingresa solo números en este campo
                        </mat-error>
                        <mat-error *ngIf="cedula.errors?.mask">
                          Esta cédula no es válida
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Telefono</mat-label>
                        <input
                          matInput
                          name="PhoneNumber"
                          #PhoneNumber="ngModel"
                          [(ngModel)]="user.phoneNumber"
                          required
                          mask="000-000-0000"

                        />
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error *ngIf="PhoneNumber.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="PhoneNumber.errors?.mask">
                          Este teléfono no es válido
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-form-field
                        class="example-full-width"
                        appearance="legacy"
                      >
                        <mat-label>Celular</mat-label>
                        <input
                          matInput
                          name="MobileNumber"
                          #MobileNumber="ngModel"
                          [(ngModel)]="user.mobileNumber"
                          mask="000-000-0000"
                          required
                        />
                        <mat-icon matSuffix>phone_iphone</mat-icon>
                        <mat-error *ngIf="MobileNumber.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
                        <mat-error *ngIf="MobileNumber.errors?.mask">
                          Este teléfono no es válido
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-12 mt-3 text-center">
                    <mat-checkbox
                      style="color: black"
                      (change)="checkedChange($event)"
                    >
                      <span style="color: black">Acepto los </span>
                      <a
                        href="../../../assets/Términos-y-condiciones-Carros-Facil Clientes.html"
                        class="term-service"
                        style="text-decoration: underline; color: #0f387a"
                        target="_blank"
                        >Términos y Condiciones</a
                      >
                    </mat-checkbox>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-primary btn-round mt-4"
                      style="
                        background-color: #0f387a;
                        font-weight: 600;
                        color: white;
                      "
                      mat-button
                      type="submit"
                      [disabled]="!acceptedConditions"
                    >
                      Continuar
                    </button>
                  </div>
                  <div class="card-footer justify-content-center">
                    <a
                      routerLink="/bo/login/"
                      style="margin-top: 10px; color: #0f387a"
                      >¿Tienes usuario? Inicia sesión</a
                    >
                  </div>
                  <div class="text-center" style="margin-top: 10px">
                    <button
                      class="float-buttom-RB hvr-icon-grow"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Ir a inicio"
                      (click)="goHome()"
                    >
                      <mat-icon class="hvr-icon">home</mat-icon>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
