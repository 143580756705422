import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fohome',
  templateUrl: './fohome.component.html',
  styleUrls: ['./fohome.component.css']
})
export class FohomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

 

  
}
