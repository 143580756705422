import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UsersBOService } from '../services/users-bo.service';
// import { MatDialog } from '@angular/material/dialog';
import { ClientsService } from '../services/clients.service';

@Injectable()
export class Http_Interceptor implements HttpInterceptor {
  constructor(
    private userService: UsersBOService,
    private clientService: ClientsService,
    // private dialog: MatDialog
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let test = localStorage.getItem('Token');
    if (!test)
      test = localStorage.getItem('TokenBO');
    if (test) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${test}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => { },
        (err: Response) => {
          if (err.status === 401 || err.status === 403) {
            console.log("Unhautorized");
            console.log(err);
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'warning',
              title: 'Acceso Denegado. Por favor inicie sesión.',
            });
            setTimeout(() => {
              if (this.userService.isUserBOLoggedIn) {
                this.userService.logout();
              }
              else if (this.clientService.isUserFOLoggedIn)
                this.clientService.logout();
            }, 0);
          }

        }
      )
    );
  }
}
