import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import actionsHelpers from "../../helper/actions-helper"

@Component({
  selector: 'fo-rentcar-vehicles',
  templateUrl: './fo-rentcar-vehicles.component.html',
  styleUrls: ['./fo-rentcar-vehicles.component.css'],
})
export class FoRentcarVehiclesComponent implements OnInit {
  @Input() Vehicles: any;
  @Input() Params: any;
  public readonly error = "No se han encontrado vehículos"
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToVehicleProfile(id: string) {
    this.router.navigate(['fo/rent-cars/vehicle-profile', id], {
      queryParams: actionsHelpers.cleanObject(this.Params)
    });
  }
}
