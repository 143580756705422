import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-fo-clients-account-confirm',
  templateUrl: './fo-clients-account-confirm.component.html',
  styleUrls: ['./fo-clients-account-confirm.component.css']
})
export class FoClientsAccountConfirmComponent implements OnInit {
  public error = 'Enlace no encontrado o expirado';
  public isError = null;
  public submitted = true;
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => (
        this.clientService.clientConfirmation(params['code']).subscribe((data: any) => {
          this.isError = false;
          this.submitted = false;
          console.log(data)
        }, err => {
          console.log(err);
          this.isError = true;
          this.submitted = false;

        })

      )
    );
  }

}
