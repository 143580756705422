<section class="sign-in">
  <div class="container">
    <div class="signin-content">
      <!-- <div class="signin-image">
        <figure>
          <img src="../../../assets/img/signin-image.jpg" alt="sing up image" />
        </figure>
      </div> -->

      <div class="signin-form">
        <h2 class="form-title text-center">Inicia sesión</h2>
        <form
          id="login-form"
          name="loginForm"
          #loginForm="ngForm"
          (ngSubmit)="loginForm.valid && login()"
        >
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Correo</mat-label>
                <input
                  matInput
                  [(ngModel)]="Username"
                  required
                  type="email"
                  name="email"
                  #email="ngModel"
                  email="true"
                />
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="email.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="email.errors?.email">
                  Debe escribir un correo válido
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Contraseña</mat-label>
                <input
                  [(ngModel)]="Password"
                  matInput
                  type="password"
                  name="password"
                  #password="ngModel"
                  required
                />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error *ngIf="password.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 text-center">
              <button
                class="btn btn-primary btn-round mt-4"
                style="background-color: #0F387A; width:250px; font-weight: bold;"
                mat-button
                type="submit"
              >
                Adelante
              </button>
            </div>
          </div>
          <div class="col-12 text-center mt-5">
            <a
              style="
                color: #0f387a;
                text-decoration: underline;
                cursor: pointer;
              "
              (click)="createAccount()"
              >¿No eres usuario? Crea tu cuenta</a
            >
          </div>
          <div class="col-12 text-center mt-2">
            <a
              style="
                color: #0f387a;
                text-decoration: underline;
                cursor: pointer;
              "
              (click)="forgotPassword()"
              >¿Olvidaste tu contraseña?</a
            >
          </div>
        </form>
        
        <!-- <div class="row justify-content-center mt-4">
          <button type="button" class="social-btn" (click)="googleLogin()">
            <span class="social-label">Continúa con Google </span>
            <span> <a
              class="hvr-icon"
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              title="Google"
            >
              <img
                src="../../../../assets//img/icons/google-icon.svg"
                alt="google icon"
              /> </a
          ></span>
          </button>  
          
        </div> -->

        <!-- <div class="social-login">
          <span class="social-label">Continúa con</span>
          <ul class="socials">
            <li>
              <a
                href="#"
                data-toggle="tooltip"
                data-placement="top"
                title="Facebook"
                ><i class="display-flex-center zmdi-facebook fa fa-facebook"></i
              ></a>
            </li>
            <li>
              <a
                href="#"
                data-toggle="tooltip"
                data-placement="top"
                title="Twitter"
                ><i class="display-flex-center zmdi-twitter fa fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                href="#"
                data-toggle="tooltip"
                data-placement="top"
                title="Google"
                ><i class="display-flex-center zmdi-google fa fa-google"></i
              ></a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</section>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
