import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FonavbarComponent } from './fo/fo-navbar/fonavbar.component';
import { FohomeComponent } from './fo/fo-home/fohome.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { CommonModule } from '@angular/common';
import { BoNavbarComponent } from './bo/bo-navbar/bo-navbar.component';
import { FoLayoutComponent } from './fo/fo-layout/fo-layout.component';
import { BoLayoutComponent } from './bo/bo-layout/bo-layout.component';
import { FoFooterComponent } from './fo/fo-footer/fo-footer.component';
import { FoLoginComponent } from './fo/modals/fo-login/fo-login.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import { FoInsSectionComponent } from './fo/fo-ins-section/fo-ins-section.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Globals } from './global';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InputValidatorDirective } from './helper/input-validator.directive';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { Http_Interceptor } from './helper/http-interceptor';
import { RentCarComponent } from './fo/rent-car/rent-car.component';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { FoRentcarVehiclesComponent } from './fo/fo-rentcar-vehicles/fo-rentcar-vehicles.component';
import { FoRentcarsSearchComponent } from './fo/fo-rentcars-search/fo-rentcars-search.component';
import { FoSignupComponent } from './fo/fo-signup/fo-signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FoClientLayoutComponent } from './fo/fo-clients/fo-client-layout/fo-client-layout.component';
import { FoAccountComponent } from './fo/fo-clients/fo-account/fo-account.component';
import { PersonalInfoComponent } from './fo/fo-clients/personal-info/personal-info.component';
import { FoRentcarVehiclesProfileComponent } from './fo/fo-rentcar-vehicles-profile/fo-rentcar-vehicles-profile.component';
import { FoDealersComponent } from './fo/fo-dealers/fo-dealers.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FoRentcarConfirmAndPayComponent } from './fo/fo-rentcar-confirm-and-pay/fo-rentcar-confirm-and-pay.component';
import { FoClientBookingsComponent } from './fo/fo-clients/fo-client-bookings/fo-client-bookings.component';
import { FoClientsAccountConfirmComponent } from './fo/fo-clients-account-confirm/fo-clients-account-confirm.component';
import { FoChangePasswordComponent } from './fo/modals/fo-change-password/fo-change-password.component';
import { FoForgotPasswordComponent } from './fo/fo-forgot-password/fo-forgot-password.component';
import { FoClientsResetPasswordComponent } from './fo/fo-clients-reset-password/fo-clients-reset-password.component'
import { TemplatesModule } from './templates/templates.module';
import { FoClientPaymentsComponent } from './fo/fo-clients/fo-client-payments/fo-client-payments.component';
import { FoCompanyDetailsComponent } from './fo/modals/fo-company-details/fo-company-details.component';
import { SwiperModule } from 'swiper/angular';
import { FoRentcarsVehiclesRecentsComponent } from './fo/fo-rentcars-vehicles-recents/fo-rentcars-vehicles-recents.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
    declarations: [
        AppComponent,
        FoLayoutComponent,
        FonavbarComponent,
        FohomeComponent,
        FoFooterComponent,
        BoLayoutComponent,
        BoNavbarComponent,
        FoInsSectionComponent,
        FoLoginComponent,
        RentCarComponent,
        FoRentcarVehiclesComponent,
        FoRentcarsSearchComponent,
        FoSignupComponent,
        FoClientLayoutComponent,
        FoAccountComponent,
        PersonalInfoComponent,
        FoRentcarVehiclesProfileComponent,
        FoDealersComponent,
        FoRentcarConfirmAndPayComponent,
        FoClientBookingsComponent,
        FoClientsAccountConfirmComponent,
        FoChangePasswordComponent,
        FoForgotPasswordComponent,
        FoClientsResetPasswordComponent,
        FoClientPaymentsComponent,
        FoCompanyDetailsComponent,
        FoRentcarsVehiclesRecentsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        NgxPaginationModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce,
            // backdropBorderRadius: '4px',
            primaryColour: 'blue',
            secondaryColour: 'white',
            tertiaryColour: 'red',
        }),
        [
            MatFormFieldModule,
            MatInputModule,
            MatButtonModule,
            MatToolbarModule,
            MatSidenavModule,
            MatListModule,
            MatIconModule,
            MatDialogModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatSelectModule,
            MatCheckboxModule,
            MatSliderModule,
            MatAutocompleteModule,
            MatRadioModule
        ],
        TemplatesModule,
        SwiperModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        Globals,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HTTP_INTERCEPTORS, useClass: Http_Interceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})  
export class AppModule { }
