import { Component, ViewChild, ElementRef, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "file-drop-component",
  templateUrl: "./file-drop.component.html",
  styleUrls: ["./file-drop.component.scss"],
})
export class FileDropComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Input() savedFiles: any;
  @Output() addedImageEvent = new EventEmitter<any>();
  @Output() deleteImageEvent = new EventEmitter<any>();
  files: any[] = [];
  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.savedFiles = changes.savedFiles.currentValue;
    for (let image of this.savedFiles) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.src = image.urlThumbnail;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        const imageName = image.urlThumbnail.substring(image.urlThumbnail.lastIndexOf("/") + 1, image.urlThumbnail.length);
        var file = this.dataURLtoFile(dataURL,imageName );
        this.files.push({img: dataURL, doc: file, publicId: image.publicId ?? ""});
        console.log(this.files);

      }
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, fileName) {
    this.files.splice(index, 1);
    this.deleteImageEvent.emit(fileName);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        this.files.push({doc: item, img: reader.result});
        this.addedImageEvent.emit({FileName: item.name, FileImage: reader.result});
      };
    }
    this.fileDropEl.nativeElement.value = "";

  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
