<div class="container justify-content-center">
  <div class="row">
    <div *ngFor="let vehicle of Vehicles; let i = index" class="col-md-10 col-sm-12">
      <div class="card card-body mt-1" style="min-width: 332px">
        <div
          class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row"
        >
          <div class="mr-3 mb-3 mb-lg-0 mt-2">
            <div
              [id]="'carouselControls' + i"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div
                  *ngFor="let image of vehicle.images; let counter = index"
                  class="carousel-item"
                  [ngClass]="{ active: counter === 0 }"
                >
                  <img
                    [src]="image.urlThumbnail"
                    alt="Slide img"
                    width="170"
                    height="130"
                  />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                [href]="'#carouselControls' + i"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                [href]="'#carouselControls' + i"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div class="media-body" (click)="goToVehicleProfile(vehicle._id)">
            <span class="media-title font-weight-semibold">
              <a class="vehicle-title" data-abc="true">
                {{ vehicle.name | titlecase }}
              </a>
            </span>
            <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
              <li class="list-inline-item">
                <a class="text-muted" data-abc="true">{{
                  vehicle.vehiclesTypes.name
                }}</a>
              </li>
            </ul>
            <div class="mb-3 mt-3">
              <!-- <div class="row mb-2">
                <div class="col-6">

                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/user.png"
                    />
                    <span  data-toggle="tooltip"
                    data-placement="left"
                    title="Asientos" class="img-title">5 As.</span>
                </div>
                <div class="col-6">
                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/gas-station.png"
                    />
                    <span  data-toggle="tooltip"
                    data-placement="left"
                    title="Combustible" class="img-title">{{
                      vehicle.vehicleFuelType.name
                    }}</span>
                </div>
              </div>
                <div class="row">
                    <div class="col-6">
                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/transmission.svg"
                    />
                    <span  data-toggle="tooltip"
                    data-placement="left"
                    title="Transmisión" class="img-title">Aut.</span>
                </div>
                <div class="col-6">
                    <svg
                      style="margin-bottom: -3px; width: 18px; height: 18px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 200"
                      class="nwdG-icon"
                      role="img"
                      cleanup=""
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M173.484 133.824l-11.015-6.488l7.75-2.119c10.241-2.802 6.103-18.612-4.147-15.807l-23.249 6.357c-24.647-14.513-11.332-3.975-15.563-22.369l15.563-9.164l23.249 6.357c10.243 2.803 14.4-13.002 4.147-15.807l-7.75-2.119l11.015-6.488c9.188-5.399 1.197-19.589-8.022-14.171l-11.015 6.488l2.078-7.904c2.748-10.467-12.752-14.683-15.499-4.238l-6.226 23.71l-15.564 9.164c-13.458-12.955-11.215 4.381-11.215-24.93l17.016-17.354c3.137-3.199 3.137-8.378 0-11.569c-6.561-6.708-12.765 1.45-17.016 5.785c0-9.357 2.051-21.158-8.022-21.158c-10.06 0-8.022 11.879-8.022 21.158c-4.146-4.228-10.41-12.539-17.016-5.785c-7.567 7.698 3.024 14.654 17.016 28.923c0 29.291 2.242 11.983-11.207 24.93l-15.563-9.164l-6.234-23.711c-2.748-10.446-18.23-6.237-15.499 4.238l2.078 7.904l-11.015-6.488c-9.194-5.403-17.218 8.767-8.022 14.171l11.015 6.488l-7.75 2.119c-10.254 2.806-6.095 18.612 4.156 15.807l23.249-6.357c24.691 14.539 11.267 4.004 15.563 22.369l-15.563 9.164l-23.249-6.357c-10.248-2.804-14.414 13-4.156 15.807l7.75 2.119l-11.015 6.488c-9.183 5.396-1.193 19.587 8.022 14.171l11.015-6.488l-2.078 7.904c-2.733 10.462 12.749 14.692 15.499 4.238l6.234-23.711l15.563-9.164c13.428 12.927 11.207-4.356 11.207 24.93l-17.016 17.354c-3.137 3.199-3.137 8.378 0 11.569c6.561 6.708 12.765-1.45 17.016-5.785c0 9.357-2.051 21.158 8.022 21.158c10.06 0 8.022-11.879 8.022-21.158c4.141 4.223 10.41 12.539 17.016 5.785c7.567-7.698-3.024-14.654-17.016-28.923c0-29.312-2.243-11.975 11.215-24.93l15.564 9.164l6.225 23.711c2.751 10.457 18.249 6.216 15.499-4.238l-2.078-7.904l11.015 6.488c9.227 5.422 17.2-8.778 8.023-14.17zm-63.064-27.688c-7.965 14.053-28.805 1.791-20.842-12.272c7.952-14.047 28.817-1.795 20.842 12.272z"
                      ></path>
                    </svg>
                    <span  data-toggle="tooltip"
                    data-placement="right"
                    title="Aire acondicionado" class="img-title">A/C</span>
                </div>
                </div> -->
              
              
              <div class="row details-grid" >
                <div class="col">
                  <p  data-toggle="tooltip"
                  data-placement="left"
                  title="Asientos">
                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/user.png"
                    />
                    <span class="img-title">5 Asientos</span>
                  </p>
                  <p  data-toggle="tooltip"
                  data-placement="left"
                  title="Combustible">
                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/gas-station.png"
                    />
                    <span class="img-title">{{
                      vehicle.vehiclesFuel.name
                    }}</span>
                  </p>
                </div>
                <div class="col">
                  <p  data-toggle="tooltip"
                  data-placement="left"
                  title="Transmisión">
                    <img
                      class="icon-style"
                      src="../../../assets/img/icons/transmission.svg"
                    />
                    <span class="img-title">Aut.</span>
                  </p>
                  <p  data-toggle="tooltip"
                  data-placement="left"
                  title="Aire acondicionado">
                    <svg
                    style="margin-bottom: -3px; width: 18px; height: 18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    class="nwdG-icon"
                    role="img"
                    cleanup=""
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M173.484 133.824l-11.015-6.488l7.75-2.119c10.241-2.802 6.103-18.612-4.147-15.807l-23.249 6.357c-24.647-14.513-11.332-3.975-15.563-22.369l15.563-9.164l23.249 6.357c10.243 2.803 14.4-13.002 4.147-15.807l-7.75-2.119l11.015-6.488c9.188-5.399 1.197-19.589-8.022-14.171l-11.015 6.488l2.078-7.904c2.748-10.467-12.752-14.683-15.499-4.238l-6.226 23.71l-15.564 9.164c-13.458-12.955-11.215 4.381-11.215-24.93l17.016-17.354c3.137-3.199 3.137-8.378 0-11.569c-6.561-6.708-12.765 1.45-17.016 5.785c0-9.357 2.051-21.158-8.022-21.158c-10.06 0-8.022 11.879-8.022 21.158c-4.146-4.228-10.41-12.539-17.016-5.785c-7.567 7.698 3.024 14.654 17.016 28.923c0 29.291 2.242 11.983-11.207 24.93l-15.563-9.164l-6.234-23.711c-2.748-10.446-18.23-6.237-15.499 4.238l2.078 7.904l-11.015-6.488c-9.194-5.403-17.218 8.767-8.022 14.171l11.015 6.488l-7.75 2.119c-10.254 2.806-6.095 18.612 4.156 15.807l23.249-6.357c24.691 14.539 11.267 4.004 15.563 22.369l-15.563 9.164l-23.249-6.357c-10.248-2.804-14.414 13-4.156 15.807l7.75 2.119l-11.015 6.488c-9.183 5.396-1.193 19.587 8.022 14.171l11.015-6.488l-2.078 7.904c-2.733 10.462 12.749 14.692 15.499 4.238l6.234-23.711l15.563-9.164c13.428 12.927 11.207-4.356 11.207 24.93l-17.016 17.354c-3.137 3.199-3.137 8.378 0 11.569c6.561 6.708 12.765-1.45 17.016-5.785c0 9.357-2.051 21.158 8.022 21.158c10.06 0 8.022-11.879 8.022-21.158c4.141 4.223 10.41 12.539 17.016 5.785c7.567-7.698-3.024-14.654-17.016-28.923c0-29.312-2.243-11.975 11.215-24.93l15.564 9.164l6.225 23.711c2.751 10.457 18.249 6.216 15.499-4.238l-2.078-7.904l11.015 6.488c9.227 5.422 17.2-8.778 8.023-14.17zm-63.064-27.688c-7.965 14.053-28.805 1.791-20.842-12.272c7.952-14.047 28.817-1.795 20.842 12.272z"
                    ></path>
                  </svg>
                    <!-- <img
                      class="icon-style"
                      src="../../../assets/img/icons/free.png"
                    /> -->
                    <span class="img-title">A/C</span>
                  </p>
                  <!-- <p>
                    <svg style="   margin-bottom: 2px;
                    width: 18px;
                    height: 18px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" class="nwdG-icon" role="img" cleanup=""><path fill-rule="evenodd" clip-rule="evenodd" d="M173.484 133.824l-11.015-6.488l7.75-2.119c10.241-2.802 6.103-18.612-4.147-15.807l-23.249 6.357c-24.647-14.513-11.332-3.975-15.563-22.369l15.563-9.164l23.249 6.357c10.243 2.803 14.4-13.002 4.147-15.807l-7.75-2.119l11.015-6.488c9.188-5.399 1.197-19.589-8.022-14.171l-11.015 6.488l2.078-7.904c2.748-10.467-12.752-14.683-15.499-4.238l-6.226 23.71l-15.564 9.164c-13.458-12.955-11.215 4.381-11.215-24.93l17.016-17.354c3.137-3.199 3.137-8.378 0-11.569c-6.561-6.708-12.765 1.45-17.016 5.785c0-9.357 2.051-21.158-8.022-21.158c-10.06 0-8.022 11.879-8.022 21.158c-4.146-4.228-10.41-12.539-17.016-5.785c-7.567 7.698 3.024 14.654 17.016 28.923c0 29.291 2.242 11.983-11.207 24.93l-15.563-9.164l-6.234-23.711c-2.748-10.446-18.23-6.237-15.499 4.238l2.078 7.904l-11.015-6.488c-9.194-5.403-17.218 8.767-8.022 14.171l11.015 6.488l-7.75 2.119c-10.254 2.806-6.095 18.612 4.156 15.807l23.249-6.357c24.691 14.539 11.267 4.004 15.563 22.369l-15.563 9.164l-23.249-6.357c-10.248-2.804-14.414 13-4.156 15.807l7.75 2.119l-11.015 6.488c-9.183 5.396-1.193 19.587 8.022 14.171l11.015-6.488l-2.078 7.904c-2.733 10.462 12.749 14.692 15.499 4.238l6.234-23.711l15.563-9.164c13.428 12.927 11.207-4.356 11.207 24.93l-17.016 17.354c-3.137 3.199-3.137 8.378 0 11.569c6.561 6.708 12.765-1.45 17.016-5.785c0 9.357-2.051 21.158 8.022 21.158c10.06 0 8.022-11.879 8.022-21.158c4.141 4.223 10.41 12.539 17.016 5.785c7.567-7.698-3.024-14.654-17.016-28.923c0-29.312-2.243-11.975 11.215-24.93l15.564 9.164l6.225 23.711c2.751 10.457 18.249 6.216 15.499-4.238l-2.078-7.904l11.015 6.488c9.227 5.422 17.2-8.778 8.023-14.17zm-63.064-27.688c-7.965 14.053-28.805 1.791-20.842-12.272c7.952-14.047 28.817-1.795 20.842 12.272z"></path>
                    </svg>
                    <span class="img-title">Seguro</span>
                  </p> -->
                </div>
              </div>
            </div>
            
            <!-- <ul class="list-inline list-inline-dotted mb-0">
                            <li class="list-inline-item">All items from <a href="#" data-abc="true">Mobile point</a>
                            </li>
                        </ul> -->
          </div>
          <div class="mt-3 mt-lg-0 ml-lg-3 text-center">
            <h3 class="mb-0 font-weight-semibold price-style">
              {{ vehicle.priceByDay | number }}
              {{ " " + vehicle.coinType + "$" }}
            </h3>
            <!-- <div>
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div> -->
            <!-- <div class="text-muted">2349 reviews</div> -->
            <!-- <button type="button"
                            class="btn btn-warning mt-4 text-white"><i class="icon-cart-add mr-2"></i> Add to
                            cart</button> -->
            <button
              class="btn btn-primary mt-3"
              style="
                background-color: #5c8fc9;
                font-size: 14px;
                font-weight: 500;
              "
              mat-button
              type="button"
              (click)="goToVehicleProfile(vehicle._id)"
            >
              Adelante
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!this.Vehicles || this.Vehicles?.length === 0">
    <app-fo-not-found [ErrorMessage]="error"></app-fo-not-found>
  </div>
</div>
