import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsService } from 'src/app/services/payments.service';
import Swal from 'sweetalert2';
import { BookingDetailComponent } from '../../../templates/modals/booking-detail/booking-detail.component';

@Component({
  selector: 'app-bo-payments',
  templateUrl: './bo-payments.component.html',
  styleUrls: ['./bo-payments.component.css']
})
export class BoPaymentsComponent implements OnInit {
  dateControl = new FormControl('', [Validators.required]);
  public startDate = new Date();
  public endDate = new Date();
  public filterDate = false;
  public payments = [];
  public paymentsCopy = [];
  public submitted = false;
  public paymentsTotal = 0;
  constructor(
    private service: PaymentsService,
    private helperModal: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getPayments();
  }


  getPaymentsTotal() {
    this.paymentsTotal = this.paymentsCopy.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
  }

  getPayments() {
    this.submitted = true;
    this.service.getByCompany().subscribe((data: any) => {
      this.submitted = false;
      this.payments = data.object;
      this.paymentsCopy = this.payments;
      this.getPaymentsTotal();
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })
  }
  public checkedChange() {
    this.setFilters();
  }

  public setFilters() {
    if (this.filterDate) {
      if (this.startDate <= this.endDate) {
        this.paymentsCopy = this.payments.filter(
          d => (new Date(d.createdDate) >= this.startDate && new Date(d.createdDate) <= this.endDate)
        )
      }
    } else {
      this.paymentsCopy = this.payments;
    }
    this.getPaymentsTotal();
  }

  public viewBookingDetail(id: string) {
    this.helperModal.open(BookingDetailComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      minWidth: '350px',
      data: { bookingId: id, isRentCar: true },
    });
  }
}
