import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BoChangePasswordComponent } from '../modals/bo-change-password/bo-change-password.component';

@Component({
  selector: 'app-bo-account',
  templateUrl: './bo-account.component.html',
  styleUrls: ['./bo-account.component.css']
})
export class BoAccountComponent implements OnInit {

  constructor(
    private helperModal: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  public openModal() {
     this.helperModal.open(BoChangePasswordComponent, {
      height: 'auto',
      // maxWidth: '650px',
      // maxHeight: '700px',
      // minWidth: '350px',
    });
  }


}
