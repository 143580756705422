<section class="signup">
  <div class="container">
    <div class="signup-content">
      <div class="signup-form">
        <h2 class="form-title">Crea tu cuenta</h2>
        <form
          method="POST"
          autocomplete="off"
          class="register-form"
          id="register-form"
          name="clientForm"
          #clientForm="ngForm"
          (ngSubmit)="clientForm.valid && saveClient()"
        >
          <div class="row text-center">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Nombres</mat-label>
                <input
                  [(ngModel)]="Client.firstName"
                  matInput
                  required
                  type="text"
                  name="firstName"
                  #firstName="ngModel"
                  minlength="3"
                />
                <mat-icon matSuffix>home</mat-icon>
                <mat-error *ngIf="firstName.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="firstName.errors?.minlength">
                  Este campo debe contener al menos 3 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Apellidos</mat-label>
                <input
                  [(ngModel)]="Client.lastName"
                  matInput
                  required
                  type="text"
                  name="lastName"
                  #lastName="ngModel"
                  minlength="3"
                />
                <mat-icon matSuffix>home</mat-icon>
                <mat-error *ngIf="lastName.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="lastName.errors?.minlength">
                  Este campo debe contener al menos 3 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Correo</mat-label>
                <input
                  matInput
                  [(ngModel)]="Client.email"
                  required
                  type="email"
                  name="email"
                  #email="ngModel"
                  email="true"
                />
                <mat-icon matSuffix>email</mat-icon>
                <mat-error *ngIf="email.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="email.errors?.email">
                  Debe escribir un correo válido
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Celular</mat-label>
                <input
                  [(ngModel)]="Client.phone"
                  name="phone"
                  matInput
                  required
                  type="text"
                  #phone="ngModel"
                  mask="000-000-0000"
                />
                <mat-icon matSuffix>phone_iphone</mat-icon>
                <mat-error *ngIf="phone.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="phone.errors?.mask">
                  Este teléfono no es válido
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Contraseña</mat-label>
                <input
                  [(ngModel)]="Client.password"
                  matInput
                  type="password"
                  name="password"
                  #password="ngModel"
                  minlength="8"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}"
                  required
                />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error *ngIf="password.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="password.errors?.minlength">
                  Su clave debe contener al menos 8 dígitos
                </mat-error>
                <mat-error *ngIf="password.errors?.pattern">
                  Su clave debe contener al menos una minúscula, una mayúscula,
                  y un número
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Repetir Contraseña</mat-label>
                <input
                  matInput
                  type="password"
                  [(ngModel)]="validatePassword"
                  name="passwordValidate"
                  #passwordValidate="ngModel"
                  required
                />
                <mat-icon matSuffix>lock</mat-icon>
                <mat-error *ngIf="passwordValidate.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
              <div
                *ngIf="
                  validatePassword.length > 0 &&
                  Client.password !== validatePassword
                "
                style="
                  color: red;
                  font-size: 10.5px;
                  margin-top: -18px;
                  text-align: left;
                "
              >
                Las contraseñas no coinciden
              </div>
            </div>
            <div class="col-12 mt-3 text-center">
              <mat-checkbox
                style="color: black"
                (change)="checkedChange($event)"
              >
                <span style="color: black">Acepto los </span>
                <a
                  href="../../../assets/Términos-y-condiciones-Carros-Facil Clientes.html"
                  class="term-service"
                  target="_blank"
                  style="text-decoration: underline; color: #0f387a"
                  >Términos y Condiciones</a
                >
              </mat-checkbox>
            </div>
            <div class="col-12 text-center">
              <button
                class="btn btn-primary btn-round mt-4"
                style="background-color: #0f387a; color: white"
                mat-button
                type="submit"
                [disabled]="
                  !acceptedConditions ||
                  (validatePassword.length > 0 &&
                    Client.password !== validatePassword)
                "
              >
                Adelante
              </button>
            </div>
            <!-- <div class="col-12 text-center mt-4">
              <i class="fa fa-facebook-official fa-2x" aria-hidden="true" style="color: #3b5998"></i>
              <i class="fa fa-google fa-2x" aria-hidden="true"></i>

            </div> -->
          </div>
        </form>
        <!-- <div class="social-login">
          <span class="social-label">Continúa con</span>
          <ul class="socials">
            <li>
              <a
                href="#"
                data-toggle="tooltip"
                data-placement="top"
                title="Facebook"
                ><i class="display-flex-center zmdi-facebook fa fa-facebook"></i
              ></a>
            </li>
            <li>
              <a
                href="#"
                data-toggle="tooltip"
                data-placement="top"
                title="Twitter"
                ><i class="display-flex-center zmdi-twitter fa fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
              (click)="loginWithGoogle()"
                data-toggle="tooltip"
                data-placement="top"
                title="Google"
                ><i class="display-flex-center zmdi-google fa fa-google"></i
              ></a>
            </li>
          </ul>
        </div> -->
      </div>
      <!--  <div class="signup-image">
        <figure>
          <img src="../../../assets/img/signup-image.jpg" alt="sing up image" />
        </figure>
         <a href="javascript:;" (click)="openLoginModal()" class="signup-image-link" style="color: #0F387A; margin-top: -10px; cursor: pointer;">¿Tienes usuario? Inicia
                    sesión</a> 
      </div>-->
    </div>
  </div>
</section>

<!-- <div>
  {{htmlTemplate}}
</div> -->

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
