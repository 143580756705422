<div class="wrapper">
  <div
    class="img-fluid"
    style="
      background-image: url('https://res.cloudinary.com/carros-facil/image/upload/v1658924649/assets/rent-car_ce1z3r.png');
      background-size: cover;
      background-position: top center;
      overflow-x: hidden;
    "
  >
    <div class="row">
      <form
        name="rcForm"
        #rcForm="ngForm"
        autocomplete="off"
        (ngSubmit)="rcForm.valid && stateCtrl.valid && search()"
      >
        <div class="card card-style" style="max-width: 350px">
          <div class="card-body">
            <div class="row mt-2">
              <h2 class="hvr-icon-bounce" style="text-align: center">
                Carros Facil
                <img
                  class="hvr-icon"
                  src="../../../assets/img/verified-badge.png"
                  style="width: 15px; height: 15px; margin-bottom: 2px"
                  alt="verified image"
                />
              </h2>
              <span class="text-muted" style="margin-top: -7px"
                >La forma más segura de rentar tu vehículo</span
              >
            </div>
            <!-- <div class="row">
              
            </div> -->
            <div class="row mt-3">
              <mat-form-field class="example-full-width">
                <mat-label>Punto de retiro/retorno</mat-label>
                <input
                  matInput
                  aria-label="State"
                  [matAutocomplete]="auto"
                  [formControl]="stateCtrl"
                  required
                  [(ngModel)]="searchText"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="setSelectedSite($event.option.value)"
                >
                  <mat-option
                    *ngFor="let location of filteredLocations"
                    [value]="location.name"
                  >
                    <span>{{ location.name }}</span>
                  </mat-option>
                  <mat-option *ngIf="filteredLocations.length === 0 && searchText.length > 0" value="0">
                    <span>Sin resultados</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="stateCtrl.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-6">
                <mat-form-field style="width: 105%" class="date-style">
                  <mat-label>Fecha desde</mat-label>
                  <input
                    matInput
                    [min]="today"
                    [(ngModel)]="startDate"
                    [ngModelOptions]="{ standalone: true }"
                    [matDatepicker]="picker"
                    name="fromDate"
                    #fromDate="ngModel"
                    required
                  />
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="fromDate.errors?.required">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field style="width: 110%" class="date-style">
                  <mat-label>Fecha hasta</mat-label>
                  <input
                    matInput
                    [min]="startDate"
                    [matDatepicker]="pickerTo"
                    [(ngModel)]="endDate"
                    [ngModelOptions]="{ standalone: true }"
                    required
                    name="toDate"
                    #toDate="ngModel"
                  />
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerTo"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo></mat-datepicker>
                  <mat-error *ngIf="toDate.errors?.required">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- <mat-form-field style="width: 105%" class="date-style">
                  <mat-label>Elige un rango de fecha</mat-label>
                  <mat-date-range-input
                    [rangePicker]="rangePicker"
                    [min]="today"
                  >
                    <input
                      matStartDate
                      placeholder="Fecha desde"
                      [(ngModel)]="startDate"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <input
                      matEndDate
                      placeholder="Fecha hasta"
                      [(ngModel)]="endDate"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="rangePicker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>
                        Cancelar
                      </button>
                      <button
                        mat-raised-button
                        style="background-color: #0f387a; color: white"
                        matDateRangePickerApply
                        (click)="changedDateEvent()"
                      >
                        Aplicar
                      </button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                </mat-form-field> -->
            </div>
            <!-- <div class="row">
              <div class="col-6">
                <mat-form-field style="width: 100%; margin-left: -15px">
                  <mat-select placeholder="Hora recojida" name="since" required>
                    <mat-option>10:00</mat-option>
                    <mat-option>11:00</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field style="width: 100%">
                  <mat-select placeholder="Hora entrega" name="since" required>
                    <mat-option>10:00</mat-option>
                    <mat-option>11:00</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->

            <div class="text-center mt-1">
              <button
                class="btn btn-primary btn-round mt-4"
                style="background-color: #0f387a"
                mat-button
                type="submit"
              >
                Adelante
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="row justify-content-center mt-5 mb-5" style="padding: 10px">
  <div class="col-12 text-center">
    <h1 class="recents">Anuncios recientes</h1>
  </div>
  <div class="col-12">
    <fo-rentcars-vehicles-recents></fo-rentcars-vehicles-recents>
  </div>
</div>

<!-- <div class="row justify-content-center mt-3">
  <div class="col-10">
    <swiper
      [slidesPerView]="slidesPerGroup"
      [spaceBetween]="30"
      [slidesPerGroup]="slidesPerGroup"
      [loop]="true"
      [loopFillGroupWithBlank]="true"
      [autoplay]="{
        delay: 1000,
        disableOnInteraction: false
      }"
      class="mySwiper"
      style="text-align: center"
    >
      <ng-template swiperSlide>
        <img
          src="http://res.cloudinary.com/carros-facil/image/upload/v1657501750/282x188/lxjnijziyaswfmlkbycl.jpg"
        />
        <h3>Honda civic 2016</h3>
        <div class="row" style="text-align: left">
          <div class="col-9">
            <p>
              <b>Precio: </b>300 USD$
            </p>
            <p><b>Comb.:</b> Gasolina</p>
            <p><b>Tipo:</b> Sedan</p>
          </div>
        </div> </ng-template
      ><ng-template swiperSlide>
        <img
          src="http://res.cloudinary.com/carros-facil/image/upload/v1658331315/282x188/ufzzknimvp2ayzz4ilby.jpg"
        /> </ng-template
      ><ng-template swiperSlide>
        <img
          src="http://res.cloudinary.com/carros-facil/image/upload/v1657501751/282x188/zegunf8kwp4p9istnqtr.jpg"
        /> </ng-template
      ><ng-template swiperSlide>
        <img
          src="http://res.cloudinary.com/carros-facil/image/upload/v1657501750/282x188/lxjnijziyaswfmlkbycl.jpg"
        /> </ng-template
      ><ng-template swiperSlide>
        <img
          src="http://res.cloudinary.com/carros-facil/image/upload/v1657501750/282x188/lxjnijziyaswfmlkbycl.jpg"
        />
      </ng-template>
    </swiper>
  </div>
</div> -->

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>

<app-fo-footer></app-fo-footer>
