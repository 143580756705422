<div class="row pull-right" style="margin-right: 3px">
  <button
    type="button"
    (click)="closeDialog()"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    data-toggle="tooltip"
    data-placement="top"
    title="Cerrar"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="mt-4 mb-4">
  <h2 style="text-align: center">Cambio de contraseña</h2>
</div>

<div class="row">
  <form
    name="passwordForm"
    #passwordForm="ngForm"
    autocomplete="off"
    (ngSubmit)="(passwordForm.valid) && changePassword()"
  >
    <div class="col-12 mb-1">
      <mat-form-field class="example-full-width">
        <mat-label>Contraseña actual</mat-label>
        <input
          matInput
          name="currentPassword"
          #currentPassword="ngModel"
          [(ngModel)]="settings.currentPassword"
          appearance="legacy"
          type="text"
          type="password"
          required
        />
        <mat-error *ngIf="currentPassword.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 mb-1">
      <mat-form-field class="example-full-width">
        <mat-label>Nueva contraseña</mat-label>
        <input
          matInput
          name="newPassword"
          #newPassword="ngModel"
          [(ngModel)]="settings.newPassword"
          appearance="legacy"
          type="password"
          minlength="8"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}"
          required
        />
        <mat-error *ngIf="newPassword.errors?.required">
          Este campo es obligatorio
        </mat-error>
        <mat-error *ngIf="newPassword.errors?.minlength">
          Su clave debe contener al menos 8 dígitos
        </mat-error>
        <mat-error *ngIf="newPassword.errors?.pattern">
          Su clave debe contener al menos una minúscula, una mayúscula, y un
          número
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 mb-2">
      <mat-form-field class="example-full-width">
        <mat-label>Confirmar contraseña</mat-label>
        <input
          matInput
          name="newPasswordRepeat"
          #newPasswordRepeat="ngModel"
          [(ngModel)]="settings.newPasswordRepeat"
          appearance="legacy"
          required
          type="password"
        />
        <mat-error *ngIf="newPasswordRepeat.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
      <div
        *ngIf="
          settings.newPasswordRepeat.length > 0 &&
          settings.newPassword !== settings.newPasswordRepeat
        "
        style="color: red; font-size: 10.5px; margin-top: -18px"
      >
        Las contraseñas no coinciden
      </div>
    </div>
    <div class="text-center">
        <button
          class="btn btn-primary btn-round mt-4"
          style="
            background-color: #0f387a;
            font-weight: 600;
            margin-left: 20px;
          "
          mat-button
          type="submit"
        >
          Cambiar
        </button>
      </div>
  </form>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
