import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-reset-password',
  templateUrl: './bo-reset-password.component.html',
  styleUrls: ['./bo-reset-password.component.css']
})
export class BoResetPasswordComponent implements OnInit {
  public isError = null;
  public changedPassword = false;
  public submitted = false;
  public settings = {
    newPassword: "",
    newPasswordRepeat: "",
    code: ""
  }
  constructor(
    private route: ActivatedRoute,
    private service: UsersBOService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.service.setLoginValue()) {
      this.router.navigate(['bo/home']);
    }
    this.route.params.subscribe(
      (params: Params) => {
        this.submitted = true;
        this.service.checkResetPasswordCode(params['code']).subscribe((data: string) => {
          this.settings.code = params['code'];
          this.submitted = false;
          this.isError = false;
        }, error => {
          console.log(error);
          this.submitted = false;
          this.isError = true;
        })
      })
  }

  public goToProfile() {
    this.router.navigate(['bo/login']);
  }

  public sendResetPassword() {
    if (this.settings.newPassword !== this.settings.newPasswordRepeat) {
      Swal.fire({
        icon: 'error',
        title: "Las contraseñas ingresadas no coinciden",
      });
      return;
    }
    this.submitted = true;
    this.service.resetPassword(this.settings).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        text: "Contraseña actualizada correctamente",
      });
      this.changedPassword = true;
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })
  }

}
