export class VehicleRentCarModel {
  public _id: string;
  public makeId: string;
  public modelId: string;
  public year: number;
  public priceByDay: number;
  public vehicleTypeId: string;
  public coinType: string;
  public fuelTypeId: number;
  public placa: string;
  public images: any[];

  constructor() {
    this.images = [];
  }
}
