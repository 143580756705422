<h2 class="titleMoradito">Tu cuenta</h2>
<hr align="left" class="highlighter" />
<div class="row justify-content-center mt-2">
  <h3 style="color: #039687; text-align: center">
    <b>Ve y edita la información de tu Rent a Car</b>
  </h3>
</div>
<div class="row justify-content-center">
  <div class="card">
    <br />

    <div class="card-body">
      <form
        name="UserForm"
        #UserForm="ngForm"
        autocomplete="off"
        (ngSubmit)="
          UserForm.valid &&
            selectCountries.valid &&
            selectCities.valid &&
            updateBOUser()
        "
      >
        <div class="row set-resposive-form">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Nombre Rent a Car</mat-label>
              <input
                matInput
                [(ngModel)]="user.name"
                name="name"
                #name="ngModel"
                required
                type="text"
              />
              <mat-icon matSuffix>home</mat-icon>
              <mat-error *ngIf="name.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width">
              <mat-label>RNC</mat-label>
              <input
                matInput
                [(ngModel)]="user.rnc"
                name="rnc"
                #rnc="ngModel"
                appearance="legacy"
                required
                style="font-family: Poppins"
                type="text"
              />
              <mat-icon matSuffix>contact_page</mat-icon>
              <mat-error *ngIf="rnc.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Correo</mat-label>
              <input
                disabled
                matInput
                type="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="mail"
                required
              />
              <mat-icon matSuffix>email</mat-icon>
              <mat-error *ngIf="email.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Direccion</mat-label>
              <input
                name="Direccion"
                #Direccion="ngModel"
                [(ngModel)]="user.address"
                matInput
                required
              />
              <mat-icon matSuffix>place</mat-icon>
              <mat-error *ngIf="Direccion.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width">
              <mat-select
                placeholder="Pais"
                name="country"
                [formControl]="selectCountries"
                [(ngModel)]="user.city.country._id"
                required
                (selectionChange)="getCities(countrySelect.value)"
                #countrySelect
              >
                <mat-option *ngFor="let c of countries" [value]="c._id">
                  {{ c?.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectCountries.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width">
              <mat-select
                placeholder="Ciudad"
                name="city"
                [(ngModel)]="user.city._id"
                [formControl]="selectCities"
                required
              >
                <mat-option *ngFor="let b of cities" [value]="b._id">
                  {{ b?.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectCities.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Nombre Propietario</mat-label>
              <input
                matInput
                type="text"
                name="Owner"
                #Owner="ngModel"
                [(ngModel)]="user.ownerName"
                required
              />
              <mat-icon matSuffix>person</mat-icon>
              <mat-error *ngIf="Owner.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Cedula</mat-label>
              <input
                matInput
                name="cedula"
                #cedula="ngModel"
                [(ngModel)]="user.document"
                required
                maxlength="13"
              />
              <mat-icon matSuffix>portrait</mat-icon>
              <mat-error *ngIf="cedula.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Telefono</mat-label>
              <input
                matInput
                name="PhoneNumber"
                #PhoneNumber="ngModel"
                [(ngModel)]="user.phoneNumber"
                required
              />
              <mat-icon matSuffix>phone</mat-icon>
              <mat-error *ngIf="PhoneNumber.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Celular</mat-label>
              <input
                matInput
                name="MobileNumber"
                #MobileNumber="ngModel"
                [(ngModel)]="user.mobileNumber"
                required
              />
              <mat-icon matSuffix>phone_iphone</mat-icon>
              <mat-error *ngIf="MobileNumber.errors?.required">
                Este campo es obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="text-center">
          <button
            class="btn btn-primary btn-round mt-4"
            style="background-color: lightgray; color: black; font-weight: 600"
            mat-button
            (click)="cancel()"
            name="cancelbutton"
            type="button"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary btn-round mt-4"
            style="
              background-color: #0f387a;
              font-weight: 600;
              margin-left: 20px;
            "
            mat-button
            type="submit"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
