import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import fetch from 'node-fetch';
// import * as $ from 'jquery';
import { PaymentsService } from 'src/app/services/payments.service';
// var client = require('braintree-web/client');
// var hostedFields = require('braintree-web/hosted-fields');
// var braintree = require('braintree-web');

declare var paypal;
@Component({
  selector: 'app-fo-dealers',
  templateUrl: './fo-dealers.component.html',
  styleUrls: ['./fo-dealers.component.css']
})
export class FoDealersComponent implements OnInit {
  // @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  producto = {
    description: "test",
    price: 20
  }

  constructor(public servicePay: PaymentsService) { }

  ngOnInit(): void {
    // paypal.Buttons({
    //   style: {
    //     shape: 'pill',
    //     color: 'silver',
    //     layout: 'vertical',
    //     label: 'pay',

    //   },
    //   createOrder: (data, actions) => {
    //     return actions.order.create({
    //       purchase_units: [{
    //         description: this.producto.description,
    //         amount: {
    //           currency_code: "USD",
    //           value: this.producto.price
    //         }
    //       }]
    //     })

    //   }, onApprove: async (data, actions) => {
    //     const order = await actions.order.capture();
    //     console.log(order);
    //   }, onError: err => {
    //     console.log(err);
    //   }
    // }).render(this.paypalElement.nativeElement);

    // client.create({
    //   authorization: 'sandbox_6m4yj8hc_qyf7yhmb97bcw27n'
    // }).then(function (clientInstance) {
    //   return client.paypalCheckout.create({
    //     client: clientInstance
    //   });
    // }).then(function (paypalCheckoutInstance) {
    //   return paypal.Buttons({
    //     createOrder: function () {
    //       return paypalCheckoutInstance.createPayment({
    //         flow: 'checkout',
    //         currency: 'USD',
    //         amount: '10.00',
    //         intent: 'capture'
    //       });
    //     },
    //     onApprove: function (data, actions) {
    //       return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
    //       });
    //     },
    //     onCancel: function () {
    //     },
    //     onError: function (err) {
    //     }
    //   }).render(this.paypalElement.nativeElement);
    // }).catch(function (err) {
    //   console.error('Error!', err);
    // });

    // client.create({
    //   authorization: 'sandbox_6m4yj8hc_qyf7yhmb97bcw27n'
    // }).then(function (clientInstance) {
    //   return braintree.paypalCheckout.create({
    //     client: clientInstance
    //   });
    // }).then(function (paypalCheckoutInstance) {
    //   return paypal.Buttons({
    //     createOrder: function () {
    //       return paypalCheckoutInstance.createPayment({
    //         flow: 'checkout',
    //         currency: 'USD',
    //         amount: '10.00',
    //         intent: 'capture'
    //       });
    //     },

    //     onApprove: function (data, actions) {
    //       // some logic here before tokenization happens below
    //       return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
    //         console.log("Paypal payment");
    //         $.ajax({
    //           type: 'POST',
    //           url: 'http://localhost:8888/api/pay',
    //           data: { 'paymentMethodNonce': payload.nonce }
    //         }).done(function (result) {
    //           console.log(result);
    //         });
    //       });
    //     },

    //     onCancel: function () {
    //     },

    //     onError: function (err) {
    //     }
    //   }).render('#paypal-button');
    // }).catch(function (err) {
    //   console.error('Error!', err);
    // });

    // Hosted
    // var form = $('form');
    // client.create({
    //   authorization: 'sandbox_6m4yj8hc_qyf7yhmb97bcw27n'
    // }, function (err, clientInstance) {
    //   if (err) {
    //     console.error(err);
    //     return;
    //   }

    //   hostedFields.create({
    //     client: clientInstance,
    //     styles: {
    //       input: {
    //         'font-size': '1rem',
    //         color: '#495057'
    //       }
    //     },
    //     fields: {
    //       cardholderName: {
    //         selector: '#cc-name',
    //         placeholder: 'Name as it appears on your card'
    //       },
    //       number: {
    //         selector: '#cc-number',
    //         placeholder: '4111 1111 1111 1111'
    //       },
    //       cvv: {
    //         selector: '#cc-cvv',
    //         placeholder: '123'
    //       },
    //       expirationDate: {
    //         selector: '#cc-expiration',
    //         placeholder: 'MM / YY'
    //       }
    //     }
    //   }, function (err, hostedFieldsInstance) {
    //     if (err) {
    //       console.error(err);
    //       return;
    //     }


    //     hostedFieldsInstance.on('validityChange', function (event) {
    //       var field = event.fields[event.emittedBy];
    //       $(field.container).removeClass('is-valid');
    //       $(field.container).removeClass('is-invalid');

    //       if (field.isValid) {
    //         $(field.container).addClass('is-valid');
    //       } else if (field.isPotentiallyValid) {
    //         // skip adding classes if the field is
    //         // not valid, but is potentially valid
    //       } else {
    //         $(field.container).addClass('is-invalid');
    //       }
    //     });

    //     hostedFieldsInstance.on('cardTypeChange', function (event) {
    //       var cardBrand = $('#card-brand');
    //       var cvvLabel = $('[for="cc-cvv"]');

    //       if (event.cards.length === 1) {
    //         var card = event.cards[0];

    //         // change pay button to specify the type of card
    //         // being used
    //         cardBrand.text(card.niceType);
    //         // update the security code label
    //         cvvLabel.text(card.code.name);
    //       } else {
    //         // reset to defaults
    //         cardBrand.text('Card');
    //         cvvLabel.text('CVV');
    //       }
    //     });

    //     form.submit(function (event) {
    //       event.preventDefault();

    //       var formIsInvalid = false;
    //       var state = hostedFieldsInstance.getState();

    //       Object.keys(state.fields).forEach(function (field) {
    //         if (!state.fields[field].isValid) {
    //           $(state.fields[field].container).addClass('is-invalid');
    //           formIsInvalid = true;
    //         }
    //       });

    //       if (formIsInvalid) {
    //         // skip tokenization request if any fields are invalid
    //         return;
    //       }
    //       console.log(hostedFieldsInstance);
    //       hostedFieldsInstance.tokenize(function (err, payload) {
    //         if (err) {
    //           console.error(err);
    //           return;
    //         } else {
    //           console.log("Payment completed");
    //           console.log(payload);

    //           $.ajax({
    //             type: 'POST',
    //             url: 'http://localhost:8888/api/pay',
    //             data: { 'paymentMethodNonce': payload.nonce }
    //           }).done(function (result) {
    //             console.log(result);
    //           });
    //         }

    //       });
    //     });
    //   });
    // });
  }

}
