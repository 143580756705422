import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BookingModel } from 'src/app/models/fo/booking.model';
import { ClientsService } from 'src/app/services/clients.service';
import { CompaniesPickupService } from 'src/app/services/companies-pickup.service';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import { FoLoginComponent } from '../modals/fo-login/fo-login.component';
import actionsHelpers from "../../helper/actions-helper";
import { BookingsService } from 'src/app/services/bookings.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-rentcar-vehicles-profile',
  templateUrl: './fo-rentcar-vehicles-profile.component.html',
  styleUrls: ['./fo-rentcar-vehicles-profile.component.css'],
})
export class FoRentcarVehiclesProfileComponent implements OnInit {
  myParam: string;
  public today = new Date();
  public submitted = false;
  public Vehicle: any;
  public notFound = false;
  public ServiceTariff;
  public availableSites;
  public booking: BookingModel;
  private initiaLocation = "";
  public reservedDates = [];
  fromControl = new FormControl('', [Validators.required]);
  toControl = new FormControl('', [Validators.required]);
  public invalidSelectedDate = false;
  public error = 'Vehículo no encontrado - 404';
  public hours = [
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
  ]

  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehiclesRentCarService,
    private companyPickupService: CompaniesPickupService,
    private bookingService: BookingsService,
    public loginModal: MatDialog,
    public clientService: ClientsService,
    public router: Router,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.booking = new BookingModel();
    this.submitted = true;
    this.route.params.subscribe(
      (params: Params) => (
        this.booking.vehicleId = params['id']
      )
    );
    this.route.queryParams.subscribe(
      (params: Params) => {
        if (params.locationId) {
          this.booking.pickupSite = this.booking.deliverSite = params.locationId;
          this.initiaLocation = params.locationId;
        }

        if (params.fromYear, params.fromMonth, params.fromDay) {
          this.booking.fromDate = new Date(params.fromYear, params.fromMonth, params.fromDay)
          if (!this.booking.fromDate) {
            this.booking.fromDate = new Date();
          }
        }
        else {
          this.booking.fromDate = new Date();
        }

        if (params.toYear, params.toMonth, params.toDay) {
          this.booking.toDate = new Date(params.toYear, params.toMonth, params.toDay);
          if (!this.booking.toDate) {
            this.booking.toDate.setDate(this.booking.fromDate.getDate() + 1);
          }
        } else {
          this.booking.toDate.setDate(this.booking.fromDate.getDate() + 1);
        }

        this.booking.days = Math.round(
          (this.booking.toDate.getTime() - this.booking.fromDate.getTime()) /
          (1000 * 60 * 60 * 24)
        );
        this.booking.days++;

        if (params.fromHour, params.toHour) {
          this.booking.fromHour = params.fromHour;
          this.booking.toHour = params.toHour;
        }
      }
    );
    this.bookingService.getReservedDates(this.booking.vehicleId).subscribe((data: any) => {
      this.reservedDates = data.object;

    }, err => {
      console.log("Error reserved dates");
      console.log(err);
    });
    this.vehicleService.getById(this.booking.vehicleId).subscribe(
      (data: any) => {
        this.submitted = false;
        this.Vehicle = data.object;
        this.Vehicle.name =
          this.Vehicle.make.name +
          ' ' +
          this.Vehicle.model.name +
          ' ' +
          this.Vehicle.year;
        this.ServiceTariff = this.Vehicle.priceByDay * this.booking.days * 0.10;
        this.getPickupsByCompany(this.Vehicle.companyId);
      },
      (err) => {
        this.Vehicle = null;
        this.submitted = false;
        this.notFound = true;
      }
    );
  }
  public changedDateEvent() {
    if (this.booking.toDate === null) {
      this.booking.toDate = new Date(this.booking.fromDate);
      this.booking.toDate.setDate(this.booking.toDate.getDate() + 1);
    }
    if (this.booking.toDate < this.booking.fromDate) {
      this.booking.toDate.setDate(this.booking.fromDate.getDate() + 1);
    }
    this.booking.days = Math.round(
      (this.booking.toDate.getTime() - this.booking.fromDate.getTime()) /
      (1000 * 60 * 60 * 24)
    );
    this.booking.days++;
    this.updateQueryParameters();
    // this.validateSelectedDate();
  }

  dateFilter = (date: Date) => {

    if (!this.reservedDates) {
      return true;
    }
    const stringDate = this.formatDate(date);
    return !this.reservedDates.includes(stringDate);
  }

  dateFilter2 = (date: Date) => {
    if (!this.reservedDates) {
      return true;
    }
    const stringDate = this.formatDate(date);
    return !this.reservedDates.includes(stringDate);
  }

  private formatDate(date: Date | null): string {
    if (!date) return ''; // Manejo de fecha nula
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Los meses se basan en 0
    const day = date.getDate();

    return `${year}-${this.pad(month)}-${this.pad(day)}`;
  }

  private pad(n: number): string {
    return n < 10 ? `0${n}` : n.toString();
  }


  validateSelectedDate() {
    for (var d = this.booking.fromDate; d <= this.booking.toDate; d.setDate(d.getDate() + 1)
    ) {
      if (this.reservedDates.find((day) => day === d.toISOString())) {
        this.invalidSelectedDate = true;
        break;
      } else {
        this.invalidSelectedDate = false;
      }
    }
  }

  public myFilter = (d: Date | null): boolean => {
    const date = (d || new Date()).toISOString();
    return !this.reservedDates.find((day) => day === date);
  };

  private getPickupsByCompany(companyId: string) {
    this.companyPickupService.GetPickupsByCompany(companyId).subscribe((data: any) => {
      this.availableSites = data.object;
    }, err => {
      console.log(err);
    })
  }
  
  private fechasIncluyenRango(): boolean {
    const rangoInicio = new Date(this.booking.fromDate);
    const rangoFin = new Date(this.booking.toDate);

    for (const fecha of this.reservedDates) {
      const fechaComparar = new Date(fecha);
      if (fechaComparar >= rangoInicio && fechaComparar <= rangoFin) {
        return true;
      }
    }
    return false;
  }





  public Process() {
    if (this.fechasIncluyenRango()) {
      Swal.fire({
        icon: 'info',
        title: 'Atención',
        text: 'Este rango de fecha no está disponible',
      });
      return;
    }
    else if (!this.clientService.setLoginValue()) {
      const dialogRef = this.loginModal.open(FoLoginComponent, {
        height: 'auto',
        width: 'auto',
        minWidth: '400px',
        maxHeight: '650px',
        panelClass: 'modal-transparent',
        disableClose: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.message == "Ok") {
            this.loginModal.closeAll();
            this.clientService.getLoggedInfo();
            this.PostBooking();
          }
        }
      });;
      return;
    }

    else {
      this.PostBooking();
    }
  }

  public PostBooking() {

    let data = {
      fromDay: this.booking.fromDate.getUTCDate(),
      fromMonth: actionsHelpers.getMonth(this.booking.fromDate),
      fromYear: this.booking.fromDate.getUTCFullYear(),
      toDay: this.booking.toDate.getUTCDate(),
      toMonth: actionsHelpers.getMonth(this.booking.toDate),
      toYear: this.booking.toDate.getUTCFullYear(),
      pickupId: this.booking.pickupSite,
      deliverId: this.booking.pickupSite,
      fromHour: this.booking.fromHour,
      toHour: this.booking.toHour
      // deliverId: this.booking.deliverSite
    };
    this.router.navigate(['fo/rent-cars/booking-pay', this.Vehicle._id], {
      queryParams: actionsHelpers.cleanObject(data)
    });
  }

  public updateQueryParameters() {
    if (!this.booking.fromDate) {
      this.booking.fromDate = new Date();
    }

    if (!this.booking.toDate) {
      this.booking.toDate.setDate(this.booking.fromDate.getDate() + 1);
    }
    let data = {
      fromDay: this.booking.fromDate.getUTCDate(),
      fromMonth: actionsHelpers.getMonth(this.booking.fromDate),
      fromYear: this.booking.fromDate.getUTCFullYear(),
      toDay: this.booking.toDate.getUTCDate(),
      toMonth: actionsHelpers.getMonth(this.booking.toDate),
      toYear: this.booking.toDate.getUTCFullYear(),
      locationId: this.booking.pickupSite ?? this.initiaLocation,
      fromHour: this.booking.fromHour,
      toHour: this.booking.toHour
    };

    // let data = {
    //   fromDate: actionsHelpers.setDateToFormat(this.booking.fromDate),
    //   toDate: actionsHelpers.setDateToFormat(this.booking.toDate),
    //   locationId: this.booking.pickupSite === this.booking.deliverSite ? this.booking.pickupSite : this.initiaLocation
    // };
    this.router.navigate(
      [],
      {
        replaceUrl: true,
        relativeTo: this.route,
        queryParams: actionsHelpers.cleanObject(data),
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
}
