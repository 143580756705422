<h2 class="titleMoradito">Planes y suscripción</h2>
<hr align="left" class="highlighter" />
<div *ngIf="showPlans">
  <div class="row justify-content-center mt-5">
    <h3 style="color: #039687; text-align: center">
      <b
        >Subscríbete para acceder a todas las funcionalidades de Carros
        Facil</b
      >
    </h3>
  </div>
  <div class="row justify-content-center mt-5">
    <!-- <div class="col-sm"> -->
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Plan Rent a Car</h5>
          <ul class="list-group">
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Acceso a todas las funcionalidades
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 8 vehículos
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 5 imágenes de vehículos
            </li>
          </ul>
          <p>$10 USD</p>
          <div class="mt-3" id="paypal-button-container-basic"></div>
        </div>
      </div>
    <!-- </div> -->
    <!-- <div class="col-sm">
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Plan intermedio</h5>
          <ul class="list-group">
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Acceso a todas las funcionalidades
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 6 vehículos
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 6 imágenes vehículos
            </li>
          </ul>
          <p>$75 USD</p>
          <div class="mt-3" id="paypal-button-container-intermediate"></div>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card text-center">
        <div class="card-body">
          <h5 class="card-title">Plan avanzado</h5>
          <ul class="list-group">
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Acceso a todas las funcionalidades
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 10 vehículos
            </li>
            <li class="list-group-item">
              <img
                alt=""
                border="0"
                height="13"
                src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
                style="display: block; margin-right: 7px"
                width="16"
                data-image-whitelisted=""
                class="CToWUd"
              />Subida de hasta 8 imágenes vehículos
            </li>
          </ul>
          <p>$100 USD</p>
          <div class="mt-3" id="paypal-button-container-advanced"></div>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div *ngIf="plan">
  <div class="row justify-content-center mt-5">
    <h2 style="color: #039687; text-align: center">
      <b>Tu plan seleccionado</b>
    </h2>
  </div>
  <div class="row justify-content-center">
    <div class="card text-center">
      <div class="card-body">
        <h5 class="card-title">{{ plan?.planId.name }}</h5>
        <ul class="list-group">
          <li class="list-group-item">
            <img
              alt=""
              border="0"
              height="13"
              src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
              style="display: block; margin-right: 7px"
              width="16"
              data-image-whitelisted=""
              class="CToWUd"
            />Acceso a todas las funcionalidades
          </li>
          <li class="list-group-item">
            <img
              alt=""
              border="0"
              height="13"
              src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
              style="display: block; margin-right: 7px"
              width="16"
              data-image-whitelisted=""
              class="CToWUd"
            />Subida de hasta {{ plan?.planId.carQty }} vehículos
          </li>
          <li class="list-group-item">
            <img
              alt=""
              border="0"
              height="13"
              src="https://res.cloudinary.com/carros-facil/image/upload/v1651671114/assets/success-arrow_s46sbw.png"
              style="display: block; margin-right: 7px"
              width="16"
              data-image-whitelisted=""
              class="CToWUd"
            />Subida de hasta {{ plan?.planId.imgQty }} imágenes de vehículos
          </li>
        </ul>
        <p>${{ plan?.planId.price }} {{ plan?.planId.coinType }}</p>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-round mt-4"
            style="background-color: lightgray; color: black; font-weight: 600"
            mat-button
            (click)="cancelPlan(plan?._id)"
            name="cancelbutton"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>

<div id="paypal-button-container-P-8LX04046YX5340839MLDV2LQ"></div>
<script src="https://www.paypal.com/sdk/js?client-id=ATeA9yKpjfY0iosygdt18p44450XRf7lHqFTondRkPA164vcMWC22zB5eqi_ySikWFQ1VET1X5nTIX4p&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
<script>
  paypal.Buttons({
      style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
      },
      createSubscription: function(data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: 'P-8LX04046YX5340839MLDV2LQ'
        });
      },
      onApprove: function(data, actions) {
        alert(data.subscriptionID); // You can add optional success message for the subscriber here
      }
  }).render('#paypal-button-container-P-8LX04046YX5340839MLDV2LQ'); // Renders the PayPal button
</script>