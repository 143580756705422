import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(private http: HttpClient, private globals: Globals) { }
  public GetByCountry(countryId: string) {
    const url_api =
      this.globals.urlApi + `cities/getByCountry/${countryId}`;
    return this.http.get(url_api);
  }
}
