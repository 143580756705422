<div class="row pull-right" style="margin-right: 3px">
  <button
    type="button"
    (click)="closeDialog()"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    data-toggle="tooltip"
    data-placement="top"
    title="Cerrar"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="mt-4">
  <h2 style="text-align: center">Cambiar estado</h2>
  <h3 class="car-name">{{ carName | titlecase }}</h3>
</div>

<div class="row">
  <div class="col-12">
    <mat-form-field class="example-full-width">
      <mat-select
        placeholder="Estado"
        name="Estado"
        [(ngModel)]="selectedStatus"
        (selectionChange)="onChangingStatus(selectStatus.value)"
        [formControl]="selectStatus"
        required
      >
        <mat-option *ngFor="let s of status" [value]="s?._id">
          {{ s?.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="text-center mt-3 mb-2">
  <button
    class="btn btn-primary btn-round bo-save-btn"
    mat-button
    type="button"
    [disabled]="data.status === selectedStatus"
    (click)="updateStatus()"
  >
    Guardar
  </button>
</div>

<div *ngIf="!isAvailable">
  <hr />
  <div class="mt-3" style="max-width: 300px">
    <p class="text-center" style="color: red"><b>Notas</b></p>
    <p *ngIf="!isAvailable && hasBookings" class="note">
      *Las reservas registradas para este vehículo no se cancelarán.
    </p>
    <p class="note" *ngIf="!isAvailable">
      *El vehículo no aparecerá en las búsquedas de Carros Facil.
    </p>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
