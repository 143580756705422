<!-- <footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="footer__about">
          <div class="footer__logo">
            <a href="#"
              ><img
                src="../../assets/img/logos/iso.png"
                alt="Logo iso CF"
                width="60px"
                style="margin-top:-3px"
            /></a>
          </div>
          <p>
            Garantizamos tu seguridad y las de nuestros socios para que tengas
            la mejor experiencia posible.
          </p>
          <a href="#"><img src="./../assets/img/payment.png" alt="" /></a>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
        <div class="footer__widget">
          <h6>Shopping</h6>
          <ul>
            <li><a href="#">Clothing Store</a></li>
            <li><a href="#">Trending Shoes</a></li>
            <li><a href="#">Accessories</a></li>
            <li><a href="#">Sale</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="footer__widget">
          <h6>Shopping</h6>
          <ul>
            <li><a href="#">Contact Us</a></li>
            <li><a href="#">Payment</a></li>
            <li><a href="#">Delivary</a></li>
            <li><a href="#">Exchanges</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6">
        <div class="footer__widget">
          <h6>NewLetter</h6>
          <div class="footer__newslatter">
            <p>
              Be the first to know about new arrivals, look books, sales &
              promos!
            </p>
            <form action="#">
              <input type="text" placeholder="Your email" />
              <button type="submit"><span class="icon_mail_alt"></span></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="footer__copyright__text">
          <p>
            Copyright © 2022 Todos los derechos reservados | ThechnoFacil
            Solutions <i class="fa fa-heart-o" aria-hidden="true"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer> -->

<!-- Remove the container if you want to extend the Footer to full width. -->

<!-- Footer -->
<footer
  class="text-center text-lg-start text-white"
  style="background-color: #f5f5f7"
>
  <!-- Section: Social media -->
  <section
    class="d-flex justify-content-between p-4"
    style="background-color: #f5f5f7"
  >
    <!-- Left -->
    <div class="ml-5">
      <span>Conéctate con nosotros en las redes sociales:</span>
    </div>
    <!-- Left -->

    <!-- Right -->
    <div>
      <a href="https://www.instagram.com/carrosfacilrd/" target="_blank" class="text-white ml-3">
        <i class="fa fa-facebook fa-lg"></i>
      </a>
      <!-- <a href="" class="text-white ml-3">
        <i class="fa fa-twitter fa-lg"></i>
      </a> -->
      <!-- <a href="" class="text-white ml-3">
        <i class="fa fa-google fa-lg"></i>
      </a> -->
      <a href="https://www.instagram.com/carrosfacilrd/" target="_blank" class="text-white ml-3">
        <i class="fa fa-instagram fa-lg"></i>
      </a>
      <!-- <a href="" class="text-white ml-3">
        <i class="fa fa-linkedin fa-lg"></i>
      </a> -->
      <!-- <a href="" class="text-white ml-3">
        <i class="fa fa-github fa-lg"></i>
      </a> -->
    </div>
    <!-- Right -->
  </section>
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-center text-md-left mt-4">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <h6 class="text-uppercase font-weight-bold">Carros Facil</h6>
          <hr
            class="mb-3 mt-0 d-inline-block mx-auto"
            style="width: 60px; background-color: #5c8fc9; height: 2px"
          />
          <p>
            Garantizamos tu seguridad y las de nuestros socios para que tengas
            la mejor experiencia posible.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Clientes</h6>
          <hr
            class="mb-3 mt-0 d-inline-block mx-auto"
            style="width: 60px; background-color: #5c8fc9; height: 2px"
          />
          <p>
            <a class="hvr-grow" routerLink="/fo/signup">Crea tu cuenta</a>
          </p>
          <p>
            <a class="hvr-grow" routerLink="/fo/rent-cars">Elige el vehículo</a>
          </p>
          <p>
            <a class="hvr-grow" routerLink="/fo/client/bookings"
              >Administra tus reservas</a
            >
          </p>
          <p>
            <a class="hvr-grow">Realiza pagos</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold">Rent a Car</h6>
          <hr
            class="mb-3 mt-0 d-inline-block mx-auto"
            style="width: 60px; background-color: #5c8fc9; height: 2px"
          />
          <p>
            <a class="hvr-grow" routerLink="/bo/signup">Crea tu cuenta</a>
          </p>
          <p>
            <a class="hvr-grow" routerLink="/bo/login">Publica tus vehículos</a>
          </p>
          <p>
            <a class="hvr-grow" routerLink="/bo/login">Recibe tus pagos</a>
          </p>
          <!-- <p>
            <a href="#!">Help</a>
          </p> -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h6 class="text-uppercase font-weight-bold">Contáctanos</h6>
          <hr
            class="mb-3 mt-0 d-inline-block mx-auto"
            style="width: 60px; background-color: #5c8fc9; height: 2px"
          />
          <!-- <p><i class="fa fa-home mr-2"></i>New York, NY 10012, US</p> -->
          <p class="hvr-grow">
            <i class="fa fa-envelope mr-2"></i>
            <a href="mailto:soporte@carrosfacil.com">soporte@carrosfacil.com</a>
          </p>
          <p><i class="fa fa-phone mr-2"></i>+1 (809) 637-2619</p>
          <!-- <p><i class="fa fa-print mr-2"></i> + 01 234 567 89</p> -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div
    class="text-center p-3 footer__copyright__text"
    style="background-color: #dedee0; color: black"
  >
    <p>
      Copyright © 2022 Todos los derechos reservados | ThechnoFacil Solutions
      <i class="fa fa-heart-o" aria-hidden="true"></i>
    </p>
    <!-- © 2020 Copyright:
      <a href="https://mdbootstrap.com/"
         >MDBootstrap.com</a
        > -->
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->

<!-- End of .container -->
