import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FoChangePasswordComponent } from '../../modals/fo-change-password/fo-change-password.component';

@Component({
  selector: 'app-fo-account',
  templateUrl: './fo-account.component.html',
  styleUrls: ['./fo-account.component.css']
})
export class FoAccountComponent implements OnInit {

  constructor(
    private helperModal: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  public openModal() {
    this.helperModal.open(FoChangePasswordComponent, {
      height: 'auto',
       maxWidth: '350px',
      // maxHeight: '700px',
     minWidth: '300px',
    });
  }

}
