<div class="row justify-content-center">
  <div class="col-md-auto">
    <div>
      <a routerLink="/bo/account-information">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  src="../../../assets/img/bo/login-security.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Cuenta</h2>
                <p class="card-text">Ve y actualiza tu información del Rent a Car</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/bo/rentcars/payments">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  style="width: 98px; margin-left: -10px"
                  src="../../../assets/img/bo/payments.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Pagos</h2>
                <p class="card-text">Tus pagos recibidos e histórico</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/bo/subscription">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  style="width: 85px; margin-left: -12px"
                  src="../../../assets/img/bo/plan.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Tus planes</h2>
                <p class="card-text">Ver tu plan de Carros Facil</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/bo/rentcars/pickup-config">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Puntos de entrega"
                  src="../../../assets/img/bo/place.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Puntos entrega</h2>
                <p class="card-text">Puntos de entrega de vehículos</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/bo/rentcars/bookings">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Puntos de entrega"
                  style="width: 85px; margin-left: -12px; margin-top: 18px"
                  src="../../../assets/img/bo/scheduled-icon.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Reservas</h2>
                <p class="card-text">Tus reservas activas e histórico</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a (click)="openModal()">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Puntos de entrega"
                  style="width: 85px; margin-left: -12px; margin-top: 19px"
                  src="../../../assets/img/bo/password-icon.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Cambiar Contraseña</h2>
                <p class="card-text">Actualiza tu clave de inicio de sesión</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
