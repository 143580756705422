import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/global';
import { CompanyModel } from 'src/app/models/bo/company.model';

@Injectable({
  providedIn: 'root',
})
export class UsersBOService {
  public isUserBOLoggedIn: boolean;
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) { }

  public postBoUser(user: CompanyModel) {
    const urlAPI = this.globals.urlApi + `companies`;
    return this.http.post(urlAPI, user);
  }

  public putBoUser(user: CompanyModel) {
    const urlAPI = this.globals.urlApi + `companies`;
    return this.http.put(urlAPI, user);
  }
  public changePassword(config: any) {
    const urlAPI = this.globals.urlApi + `auth/companies/change-password`;
    return this.http.post(urlAPI, config);
  }

  public getLoggedInfo() {
    const url_api = this.globals.urlApi + `companies/logged`;
    return this.http.get(url_api);
  }

  public getById(id:string) {
    const url_api = this.globals.urlApi + `companies/getById/${id}`;
    return this.http.get(url_api);
  }

  public requestResetPassword(email: string) {
    const urlAPI = this.globals.urlApi + `companies/forgot-password`;
    return this.http.post(urlAPI, {email});
  }

  public checkResetPasswordCode(code: string) {
    const urlAPI = this.globals.urlApi + `companies/confirmation/checkCode`;
    return this.http.post(urlAPI, { code: code });
  }

  public resetPassword(config: any) {
    const urlAPI = this.globals.urlApi + `auth/companies/reset-password`;
    return this.http.post(urlAPI, config);
  }

  public getDocumentByName(name:string) {
    const url_api = this.globals.urlApi + `documents/by-name?name=${name}`;
    return this.http.get(url_api);
  }



  public login(username: string, password: string) {
    const urlAPI = this.globals.urlApi + `auth/companies/login`;
    let user = { email: username, password: password };
    return this.http.post(urlAPI, user);
  }

  public logout() {
    // localStorage.removeItem("TokenBO");
    localStorage.clear();
    this.isUserBOLoggedIn = false;
    this.router.navigate(['bo/login']);
  }

  public setLoginValue(): boolean {
    let Token = localStorage.getItem('TokenBO');
    Token
      ? (this.isUserBOLoggedIn = true)
      : (this.isUserBOLoggedIn = false);
    return this.isUserBOLoggedIn;
  }

}
