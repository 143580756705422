<div class="tz-gallery">
  <div class="row">
    <div
      *ngFor="let vehicle of Vehicles; let i = index"
      class="col-sm-4 col-md-3"
    >
      <div class="thumbnail">
        <a class="lightbox">
          <div
            [id]="'carouselControls' + i"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                *ngFor="let image of vehicle.images; let counter = index"
                class="carousel-item"
                [ngClass]="{ active: counter === 0 }"
              >
                <img
                  class="img-fluid"
                  [src]="image.urlThumbnail"
                  alt="Slide img"
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              [href]="'#carouselControls' + i"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              [href]="'#carouselControls' + i"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </a>
        <h3 style="text-align: center">
          {{ vehicle.name | titlecase }}
          <img
          *ngIf="vehicle.vehiclesStatus.name === 'No Disponible'"
            class="hvr-icon vehicle-status-icons"
            src="../../../assets/img/icons8-unlike.png"
            alt="Vehículo no disponible"
            data-toggle="tooltip"
            data-placement="right"
            title="Vehículo no disponible"
          />
        </h3>
        <div class="row">
          <div class="col-9">
            <div class="caption">
              <p>
                <b>Precio: </b>{{ vehicle.priceByDay | number }}
                {{ " " + vehicle.coinType + "$" }}
              </p>
              <p><b>Comb.:</b> {{ vehicle.vehiclesFuel.name }}</p>
              <p><b>Tipo:</b> {{ vehicle.vehiclesTypes.name }}</p>
              <p><b>Placa:</b> {{ vehicle.placa }}</p>
            </div>
          </div>
          <div class="col-3">
            <div class="dropdown btn-group">
              <button
                mat-icon-button
                class="dropdown-toggle dropdown-style"
                aria-label="Opciones"
                data-toggle="dropdown"
              >
                <img
                  alt="Opciones del carro"
                  style="width: 23px"
                  src="../../../assets/img/bo/carOption.jpg"
                  data-toggle="tooltip"
                  title="Configuración del vehículo"
                />
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="editVehicle(vehicle._id)">
                  <i
                    class="fa fa-pencil vehicle-config-icons"
                    aria-hidden="true"
                  ></i>
                  Editar</a
                >
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="
                    changeStatus(
                      vehicle._id,
                      vehicle.name,
                      vehicle.vehicleStatus
                    )
                  "
                >
                  <i
                    class="fa fa-wrench vehicle-config-icons"
                    aria-hidden="true"
                  ></i
                  >Cambiar estado</a
                >
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="deleteVehicle(vehicle._id)"
                >
                  <i
                    class="fa fa-trash vehicle-config-icons"
                    aria-hidden="true"
                  ></i
                  >Eliminar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container">
    <img [src]="Vehicles[0].vehiclesRentCarsImages[0].imageURL282x188" class="container_img" />
</div>
<div class="container">
    <img [src]="Vehicles[2].vehiclesRentCarsImages[0].imageURL282x188" class="container_img" />
</div>
<div class="container">
    <img [src]="Vehicles[1].vehiclesRentCarsImages[1].imageURL282x188" class="container_img" />
</div> -->

<!-- <img class="img-fluid" [src]="Vehicles[0].vehiclesRentCarsImages[0].imageURL282x188" alt="Slide img"> -->

<!-- <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-md-3">
            <div class="thumbnail">
                <a class="lightbox">
                    <div id="carouselControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="img-fluid"
                                    src="https://images.hgmsites.net/hug/2015-toyota-corolla_100474632_h.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://smartcdn.prod.postmedia.digital/driving/wp-content/uploads/2015/06/img_3257.jpg?quality=100&strip=all"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://media.ed.edmunds-media.com/mazda/3/2018/oem/2018_mazda_3_sedan_grand-touring_fq_oem_2_1600.jpg"
                                    alt="Third slide">
                            </div>
                            <div class="carousel-item">
                                <img class="img-fluid"
                                src="https://images.hgmsites.net/hug/2020-honda-cr-v_100748896_h.jpg"
                                    alt="Third slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </a>
                <h3 style="text-align: center;">
                    Toyota Corolla 2015 <img class="hvr-icon vehicle-status-icons"
                        src="../../../assets/img/bo/locked-icon.png" alt="Vehículo reservado" data-toggle="tooltip"
                        title="Vehículo reservado" /></h3>
                <div class="row">
                    <div class="col-9">
                        <div class="caption">
                            <p><b>Precio:</b> 2500 RD$</p>
                            <p><b>Comb.:</b> Gasolina</p>
                            <p><b>Tipo:</b> Sedan</p>
                            <p><b>Placa:</b> A1092882</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="dropdown btn-group">
                            <button mat-icon-button class="dropdown-toggle dropdown-style" aria-label="Opciones"
                                data-toggle="dropdown">
                                <img alt="Opciones del carro" style="width: 23px;"
                                    src="../../../assets/img/bo/carOption.jpg" data-toggle="tooltip"
                                    title="Configuración del vehículo">
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/bo/account">
                                    <i class="fa fa-pencil vehicle-config-icons" aria-hidden="true"></i>
                                    Editar</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-wrench vehicle-config-icons" aria-hidden="true"></i>Cambiar
                                    estado</a>
                                <a class="dropdown-item" style="cursor: pointer"> <i
                                        class="fa fa-trash vehicle-config-icons" aria-hidden="true"></i>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
