import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue: string = inputElement.value;

    // Eliminar cualquier carácter no numérico
    inputElement.value = inputValue.replace(/[^0-9]/g, '');
  }
}