import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';
import { SubcriptionModel } from '../models/bo/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class BoSubscriptionsService {

  constructor(private http: HttpClient, private globals: Globals) { }

  public getSubsPlans() {
    const url_api = this.globals.urlApi + `subscriptions-plans`;;
    return this.http.get(url_api);
  }

  public postSubscription(sub: SubcriptionModel) {
    const urlAPI = this.globals.urlApi + `subscriptions`;
    return this.http.post(urlAPI, sub);
  }

  public getPlanByCompany() {
    const url_api = this.globals.urlApi + `subscriptions/by-company`;;
    return this.http.get(url_api);
  }

  public cancelSubscription(id: string) {
    const data = { subId: id };
    const urlAPI = this.globals.urlApi + `subscriptions/cancel`;
    return this.http.put(urlAPI, data);
  }
}
