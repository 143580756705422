import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsService } from 'src/app/services/payments.service';
import { BookingDetailComponent } from 'src/app/templates/modals/booking-detail/booking-detail.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-client-payments',
  templateUrl: './fo-client-payments.component.html',
  styleUrls: ['./fo-client-payments.component.css']
})
export class FoClientPaymentsComponent implements OnInit {
  dateControl = new FormControl('', [Validators.required]);
  public startDate = new Date();
  public endDate = new Date();
  public filterDate = false;
  public submitted = false;
  public payments = [];
  public paymentsCopy = [];
  public p: number = 1;

  constructor(
    private service: PaymentsService,
    private helperModal: MatDialog,

  ) { }

  ngOnInit(): void {
    this.getPayments();
  }
  goBack() {
    window.history.go(-1); return false;
  }

  getPayments() {
    this.submitted = true;
    this.service.getByClient().subscribe((data: any) => {
      this.submitted = false;
      this.payments = data.object;
      this.paymentsCopy = this.payments;
      console.log(data);

    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })
  }
  public viewBookingDetail(id: string) {
    this.helperModal.open(BookingDetailComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      minWidth: '350px',
      data: { bookingId: id, isRentCar: false },
    });
  }



  public checkedChange() { this.setFilters() }

  public setFilters() {
    if (this.filterDate) {
      this.startDate.setHours(1,0,0);
      this.endDate.setHours(23,59,59);
      if (this.startDate <= this.endDate) {
        this.paymentsCopy = this.payments.filter(
          d => (new Date(d.createdDate) >= this.startDate && new Date(d.createdDate) <= this.endDate)
        )
      }
    } else {
      this.paymentsCopy = this.payments;
    }
  }
}
