<div class="col-md-10 ml-auto mr-auto">
  <div class="containerBody container-fluid">
    <h2 class="form-title hvr-icon-bounce mb-5">
      <a style="cursor: pointer" (click)="goBack()"
        ><mat-icon class="hvr-icon" style="margin-bottom: 6px"
          >keyboard_arrow_left</mat-icon
        ></a
      >
      Tus reservas
    </h2>

    <div *ngFor="let booking of bookings" class="row">
      <div class="col-12">
        <div style="padding: 8px">
          <h2>{{ booking.vehicle.name | titlecase }}</h2>
          <p style="margin-top: -12px">
            {{ booking.fromDate | date: "d MMM y" }} –
            {{ booking.toDate | date: "d MMM y" }}
          </p>
        </div>

        <div class="card" style="margin-top: -4px; min-width: 278px">
          <div class="card-body">
            <div class="row">
              <div class="col-md-auto text-center" style="margin-bottom: 20px">
                <img
                  [src]="booking.vehicle.image.secureUrlThumbnail"
                  width="160"
                  height="120"
                  alt=""
                />
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <p><b>Retiro: </b>{{ booking.pickupSite.name }}</p>
                    <p><b>Retorno: </b>{{ booking.deliverSite.name }}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <p class="text-muted" style="margin-top: -12px">
                      {{ booking.fromDate | date: "d MMM y" }} –
                      {{ booking.toDate | date: "d MMM y" }}
                    </p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <p style="margin-top: -12px">
                      {{
                        booking.bookingStatus.name === "Confirmed"
                          ? "Confirmada"
                          : booking.bookingStatus.name === "Cancelled"
                          ? "Cancelada"
                          : booking.bookingStatus.name === "In Progress" ? 'En curso'
                          : booking.bookingStatus.name === "Completed" ? 'Completada'
                          : booking.bookingStatus.name
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="dropdown float-right div-dropdown" *ngIf="booking.bookingStatus.name !== 'Completed'">
                  <button
                    class="btn btn-secondary drop-button"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  >
                    <i
                      class="fa fa-ellipsis-v fa-2x"
                      style="color: gray"
                      aria-hidden="true"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Opciones"
                    ></i>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      *ngIf="booking.bookingStatus.name === 'Confirmed' || booking.bookingStatus.name === 'In Progress'"
                      class="dropdown-item"
                      (click)="viewRentcarModal(booking.companyId)"
                      >Ver Rent a Car</a
                    >
                    <a
                      *ngIf="booking.bookingStatus.name === 'Confirmed'"
                      class="dropdown-item"
                      (click)="cancelBooking(booking._id)"
                      >Cancelar reserva</a
                    >
                    <a
                      *ngIf="booking.bookingStatus.name === 'Cancelled'"
                      class="dropdown-item"
                      (click)="deleteBooking(booking._id)"
                      >Eliminar</a
                    >
                  </div>
                </div>
                <h2 class="float-right" style="margin-top: 5px">
                  {{ booking.coinType }} {{ booking.total | number }}$
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
