import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/models/fo/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { DocumentTypesService } from 'src/app/services/document-types.service';
import { GendersService } from 'src/app/services/genders.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  public Client: ClientModel;
  public ClientCopy: ClientModel;
  public Genders: any[];
  public DocumentTypes: any[];
  public maxDate: Date;
  public submitted: boolean = false;
  public email: string;

  constructor(
    public clientService: ClientsService,
    public genderService: GendersService,
    public documentTypesService: DocumentTypesService,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 15);
    this.clientService.setLoginValue();
    this.Client = new ClientModel();
    this.ClientCopy = new ClientModel();
    this.getGenders();
    this.getUserInfo();
    this.getDocumentTypes();

  }
  x
  cancel() {
    this.route.navigate(['fo/client/account']);
  }

  putPersonalInfo() {
    this.submitted = true;
    delete this.Client.user;
    this.clientService.putClient(this.Client).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text:
          'Se han guardado sus cambios correctamente',
      });
      this.getUserInfo();
    }, error => {
      console.log(error);
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });

    });
  }

  getUserInfo() {
    this.submitted = true;
    this.clientService.getClientInfo().subscribe((data: any) => {
      this.email = data.object.user.email;
      this.submitted = false;
      let model = new ClientModel();
      this.Client = Object.assign(model, data.object);
      this.ClientCopy = data.object;
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    });
  }

  getGenders() {
    this.genderService.getAll().subscribe((data: any) => {
      this.Genders = data.object;
    });
  }

  getDocumentTypes() {
    this.documentTypesService.getAll().subscribe((data: any) => {
      this.DocumentTypes = data.object;
    })
  }

}
