<div>
  <div class="row" style="background-color: #f7f7f7; padding: 10px">
    <div class="col mt-5 text-center">
      <h2 class="title-not-found">Opps! ¿Confundido?</h2>
      <div class="vertical-grid">
        <h2 class="vertical-text rotate">nosotros</h2>
        <h2 class="vertical-text">también</h2>
      </div>
    </div>
    <div class="col text-center">
      <img
        class="img-fluid mt-4"
        src="https://res.cloudinary.com/carros-facil/image/upload/v1658924494/assets/minion-confundido_uw7jwx.png"
        alt="Not found image"
        style="max-width: 345px"
      />
    </div>
  </div>
  <h3 class="text-center text-not-found mt-4">
    {{ ErrorMessage }}
  </h3>
</div>
