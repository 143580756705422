class ActionsHelpers {
    public cleanObject(obj) {
        for (var propName in obj) {
            if (
                obj[propName] === null ||
                obj[propName] === undefined ||
                obj[propName] === ""
            ) {
                delete obj[propName];
            }
        }
        return obj;
    }

    public getMonth(date: Date): string {
        let month = (date.getUTCMonth()).toString();
        month = parseInt(month) < 10 ? '0' + month : month;
        return month;
    }

    public getFormattedMonth(date: Date): string {
        let month = (date.getUTCMonth()+1).toString();
        month = parseInt(month) < 10 ? '0' + month : month;
        return month;
    }

    public setDateToFormat(date: Date): string {
        if (date === undefined) {
            return null;
        }
        let month = (date.getUTCMonth() + 1).toString();
        month = parseInt(month) < 10 ? '0' + month : month;
        return month + '-' + date.getUTCDate() + '-'
            + date.getUTCFullYear()
    }

    public isCreditCard(cc: string): boolean {
        let valid = new RegExp(/^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/);
        if (valid.test(cc)) {
            return true;
        }
        else {
            return false;
        }
    }
    public creditCardType(cc: string) {
        let amex = new RegExp('^3[47][0-9]{13}$');
        let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
        let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

        let mastercard = new RegExp('^5[1-5][0-9]{14}$');
        let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

        let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
        let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
        let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

        let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
        let jcb = new RegExp('^35[0-9]{14}[0-9]*$');


        if (visa.test(cc)) {
            return 'VISA';
        }
        if (amex.test(cc)) {
            return 'AMEX';
        }
        if (mastercard.test(cc) || mastercard2.test(cc)) {
            return 'MASTERCARD';
        }
        if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
            return 'DISCOVER';
        }
        if (diners.test(cc)) {
            return 'DINERS';
        }
        if (jcb.test(cc)) {
            return 'JCB';
        }
        if (cup1.test(cc) || cup2.test(cc)) {
            return 'CHINA_UNION_PAY';
        }
        return undefined;
    }

    public formatCard(cc: string): string {
        if (cc.substring(0, 1) === "4" || cc.substring(0, 1) === "5") {
            if (cc.replace(/ /g, '').length % 4 === 0) {
                cc += " ";
            }
        }
        if (cc.substring(0, 2) === "34" || cc.substring(0, 2) === "37") {
            if (cc.replace(/ /g, '').length === 4 || cc.replace(/ /g, '').length === 11) { cc += " "; }
        }
        return cc;
    }
    public formatCompleteCard(cc: string): string {
        cc = cc.replace(/ /g, '');
        let formattedCard = "";
        if (cc.substring(0, 1) === "4" || cc.substring(0, 1) === "5") {
            for (let i = 0; i < cc.length; i++) {
                formattedCard += cc.charAt(i);
                if (i % 4 === 0) {
                    formattedCard += " ";
                }
            }
            return formattedCard;
        }
        if (cc.substring(0, 2) === "34" || cc.substring(0, 2) === "37") {
            for (let i = 0; i < cc.length; i++) {
                formattedCard += cc.charAt(i);
                if (i === 4 || i === 11) {
                    formattedCard += " ";
                }
            }
            return formattedCard;
        } else {
            return cc;
        }


    }

}

export default new ActionsHelpers();