import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientsService } from 'src/app/services/clients.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-change-password',
  templateUrl: './fo-change-password.component.html',
  styleUrls: ['./fo-change-password.component.css']
})
export class FoChangePasswordComponent implements OnInit {
  public submitted = false;
  public settings = {
    currentPassword: "",
    newPassword: "",
    newPasswordRepeat: ""
  }

  constructor(
    public dialogRef: MatDialogRef<FoChangePasswordComponent>,
    private service : ClientsService
  ) { }

  ngOnInit(): void {
  }
  public closeDialog() {
    this.dialogRef.close();
  }

  public changePassword() {
    if (this.settings.newPassword !== this.settings.newPasswordRepeat) {
      Swal.fire({
        icon: 'error',
        title: "Las contraseñas ingresadas no coinciden",
      });
      return;
    }
    this.submitted = true;
    this.service.changePassword(this.settings).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        text: "Contraseña actualizada correctamente",
      });
      this.closeDialog();
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })

  }
}
