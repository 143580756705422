<h2 class="titleMoradito">Mis vehículos</h2>
<hr align="left" class="highlighter" />

<div *ngIf="hasPlan">
  <div class="d-flex justify-content-between" style="padding: 10px">
    <mat-form-field style="width: 40%; margin-top: 12px">
      <mat-icon matSuffix>search</mat-icon>
      <input
        matInput
        (input)="search()"
        placeholder="Buscar"
        name="Buscar"
        maxlength="20"
        [(ngModel)]="filter"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
    <div>
      <button
        [disabled]="!hasPlan"
        type="button"
        style="background-color: #323232"
        class="btn btn-default btn-circle btn-lg"
        (click)="openVehicleModal(null)"
        data-toggle="tooltip"
        data-placement="left"
        title="Agregar vehículo"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div *ngIf="vehicles" style="margin-top: 23px">
    <app-bo-vehicles-images
      [Vehicles]="vehiclesCopy"
      (editVehicleEvent)="editVehicle($event)"
      (changeStatusEvent)="changeStatus($event)"
      (deleteVehicleEvent)="deleteVehicle($event)"
    ></app-bo-vehicles-images>
  </div>
</div>

<div *ngIf="!hasPlan">
  <div class="row justify-content-center mt-5">
    <h3 style="color: #039687; text-align: center">
      <b
        >Para poder gestionar vehículos debes tener una subscripción activa en
        Carros Facil</b
      >
    </h3>
  </div>
  <div class="row justify-content-center mt-2">
    <a
      style="color: #0f387a; text-decoration: underline"
      routerLink="/bo/subscription"
      >Ir a planes</a
    >
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
