import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';
import { VehicleRentCarModel } from '../models/vehicles-rentcar.models';

@Injectable({
  providedIn: 'root',
})
export class VehiclesRentCarService {
  constructor(private http: HttpClient, private globals: Globals) { }

  public postVehicleRentCar(car: VehicleRentCarModel) {
    const urlAPI = this.globals.urlApi + `vehicles-rent-cars`;
    return this.http.post(urlAPI, JSON.stringify(car));
  }
  public putVehicleRentCar(car: VehicleRentCarModel) {
    const urlAPI = this.globals.urlApi + `vehicles-rent-cars`;
    return this.http.put(urlAPI, JSON.stringify(car));
  }

  public getAllVehiclesByRC() {
    const urlAPI = this.globals.urlApi + 'vehicles-rent-cars';
    return this.http.get(urlAPI);
  }

  public getAvailableVehicles(pickupId: string, fromDate: Date, toDate: Date) {
    let urlAPI = this.globals.urlApi;
    // if (!pickupId)
    //   urlAPI += `api/vehicles-rent-cars/available?fromDate=${fromDate}&toDate=${toDate}`;
    // else
    urlAPI += `vehicles-rent-cars/available?pickupId=${pickupId}&fromDate=${fromDate}&toDate=${toDate}`
    return this.http.get(urlAPI);
  }

  public getLocationsForPickup(filter: string) {
    const urlAPI =
      this.globals.urlApi + `cities-pickup/search?name=${filter}`;
    return this.http.get(urlAPI);
  }

  public getById(id: string) {
    const urlAPI =
      this.globals.urlApi + `vehicles-rent-cars/profile/${id}`;
    return this.http.get(urlAPI);
  }

  public getResumed(id: string) {
    const urlAPI =
      this.globals.urlApi + `vehicles-rent-cars/profile/resumed/${id}`;
    return this.http.get(urlAPI);
  }

  public deleteVehicleRentCar(id: string) {
    const urlAPI = this.globals.urlApi + `vehicles-rent-cars/${id}`;
    return this.http.delete(urlAPI);
  }

  public getRecents() {
    const urlAPI =
      this.globals.urlApi + `vehicles-rent-cars/get-recents`;
    return this.http.get(urlAPI);
  }
}
