<div class="row" style="background-color: #f7f7f7; padding: 10px">
    <div class="col mt-5 text-center">
      <h2 class="title-success">{{TitleMessage}}</h2>
    </div>
    <div class="col text-center">
      <img
        class="img-fluid mt-4"
        src="https://res.cloudinary.com/carros-facil/image/upload/v1658924494/assets/minion-success_p4ndm6.png"
        alt="Not found image"
        style="max-width: 345px; margin-left: 25px"
      />
    </div>
  </div>
  <h3 class="text-center mt-5">
    <span class="animate-charcter">{{SuccessMessage}} ✓</span>
  </h3>