import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyModel } from 'src/app/models/bo/company.model';
import { UsersBOService } from 'src/app/services/users-bo.service';
import { CitiesService } from 'src/app/services/cities.service';
import { CountriesService } from 'src/app/services/countries.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-singup',
  templateUrl: './bo-singup.component.html',
  styleUrls: ['./bo-singup.component.css'],
})
export class BoSingupComponent implements OnInit {
  public user: CompanyModel;
  public samePasswords: string = '';
  public notMatchP: boolean = false;
  public selectCountries = new FormControl('', [Validators.required]);
  public selectCities = new FormControl('', [Validators.required]);
  public submitted = false;
  public countryId = "60c8c9efc41b21207cce4dd7"; //RD
  public countries;
  public cities;
  public acceptedConditions = false;
  constructor(
    private router: Router,
    public userBoServices: UsersBOService,
    public countryServices: CountriesService,
    public cityServices: CitiesService
  ) { }

  ngOnInit() {
    this.user = new CompanyModel();
    this.getCountries();
    this.GetCities(this.countryId);
  }

  public onPasswordInput() {
    if (this.user.password === this.samePasswords) this.notMatchP = false;
    else this.notMatchP = true;
    // console.log(this.user.Password != this.samePasswords);
  }
  public GetCities(value: string) {
    this.submitted = true;
    this.cityServices.GetByCountry(value).subscribe(
      (data: any) => {
        this.cities = data.object;
        this.submitted = false;
      },
      (error) => {
        console.log(error);
        this.submitted = false;
      }
    );
  }
  public createBOUser() {
    if(this.notMatchP){
      return;
    }
    this.submitted = true;
    this.userBoServices.postBoUser(this.user).subscribe(
      (data: any) => {
        this.submitted = false;
        Swal.fire({
          icon: 'success',
          title: 'En hora buena!',
          text:
            'Registro completado. Nos estaremos comunicando contigo para validar tu cuenta.',
        });
        this.router.navigate(['bo/login']);
      },
      (error) => {
        this.submitted = false;
        Swal.fire({
          icon: 'error',
          title: error.error.title,
          text: error.error.message,
        });
      }
    );
  }

  public goHome() {
    this.router.navigate(['fo/rent-cars']);
  }

  public getCountries() {
    this.countryServices.GetCountries().subscribe(
      (data: any) => {
        this.countries = data.object;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }
  public openDocument() {
    this.userBoServices.getDocumentByName('Términos-y-condiciones-Carros-Facil Clientes.html').subscribe((data: any) => {
      var binary = atob(data.object.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      var blob = new Blob([view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      window.open(url);
    })
  }

  public checkedChange(event) {
    this.acceptedConditions = event.checked;
  }
}
