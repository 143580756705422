<div class="wrapper wrapper-full-page">
  <div
    class="page-header login-page header-filter"
    filter-color="black"
    style="
      background-image: url('./assets/img/login.jpg');
      background-size: cover;
      background-position: top center;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
          <form
            class="form"
            (ngSubmit)="emailUser.valid && password.valid && Login()"
          >
            <div class="card card-login card-hidden" style="min-width: 330px">
              <div class="card-header card-header-success text-center">
                <h3 class="card-title">Bienvenido</h3>
              </div>
              <div class="card-body">
                <p
                  class="card-description text-center"
                  style="margin-left: 14px"
                >
                  Inicia sesión
                </p>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                  <mat-form-field style="width: 81%">
                    <input
                      matInput
                      [(ngModel)]="Username"
                      type="email"
                      placeholder="Correo electrónico"
                      [ngModelOptions]="{ standalone: true }"
                      name="emailUser"
                      #emailUser="ngModel"
                      [email]="true"
                      required
                    />
                    <mat-error *ngIf="!emailUser.valid">
                      Favor escriba un correo valido
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-lock"></i>
                    </span>
                  </div>
                  <mat-form-field style="width: 81%">
                    <input
                      matInput
                      [(ngModel)]="Password"
                      type="password"
                      placeholder=" Contraseña"
                      [ngModelOptions]="{ standalone: true }"
                      name="password"
                      #password="ngModel"
                      required
                    />
                    <mat-error *ngIf="password.errors?.required">
                      Favor escriba su contraseña
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="card-footer justify-content-center">
                <button
                  class="btn btn-primary btn-round mt-4"
                  style="background-color: #0f387a; font-weight: 600"
                  mat-button
                  type="submit"
                >
                  Adelante
                </button>
              </div>
              <div class="row justify-content-center">
                <a
                  class="links-tags"
                  routerLink="/bo/signup"
                  style="margin-top: 10px"
                  >¿Aún no tienes usuario? Crea tu cuenta</a
                >
              </div>
              <div
                class="card-footer justify-content-center"
                style="margin-top: -12px"
              >
                <a class="links-tags" (click)="openModal()"
                  >¿Olvidaste tu clave?</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="row"> -->
      <div class="justify-content-center text-center">
        <button
          class="float-buttom-RB hvr-icon-grow"
          data-toggle="tooltip"
          data-placement="left"
          title="Ir a inicio"
          (click)="goHome()"
          style="margin-top: 70px"
        >
          <mat-icon class="hvr-icon">home</mat-icon>
        </button>
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
