import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import actionsHelpers from "../../helper/actions-helper";

@Component({
  selector: 'fo-rentcars-vehicles-recents',
  templateUrl: './fo-rentcars-vehicles-recents.component.html',
  styleUrls: ['./fo-rentcars-vehicles-recents.component.css']
})
export class FoRentcarsVehiclesRecentsComponent implements OnInit {
  public vehicles: any[];

  constructor(
    private rentCarService: VehiclesRentCarService,
    public router: Router

  ) { }

  ngOnInit(): void {
    this.rentCarService.getRecents().subscribe(
      (data: any) => {
        this.vehicles = data.object;
        for (let v of this.vehicles) {
          v = Object.assign(v, {
            name: v.make.name + ' ' + v.model.name + ' ' + v.year,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }


  goToVehicleProfile(id: string) {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(startDate.getDate() + 1)
    const selectedParams =
    {
      fromDay: startDate.getUTCDate(),
      fromMonth: actionsHelpers.getMonth(startDate),
      fromYear: startDate.getUTCFullYear(),
      toDay: endDate.getUTCDate(),
      toMonth: actionsHelpers.getMonth(endDate),
      toYear: endDate.getUTCFullYear(),
    };
    this.router.navigate(['fo/rent-cars/vehicle-profile', id], {
      queryParams: actionsHelpers.cleanObject(selectedParams)
    });
  }

}
