import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';
import { BookingModel } from '../models/fo/booking.model';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  constructor(private http: HttpClient, private globals: Globals) { }

  public postBooking(data: BookingModel) {
    console.log("saliendo request")
    const urlAPI = this.globals.urlApi + `bookings`;
    return this.http.post(urlAPI, data);
  }

  public getByClient() {
    const url_api = this.globals.urlApi + `bookings`;;
    return this.http.get(url_api);
  }

  public getById(id:string) {
    const url_api = this.globals.urlApi + `bookings/getById/${id}`;
    return this.http.get(url_api);
  }


  public getByRC() {
    const url_api = this.globals.urlApi + `bookings/getByRentcar`;;
    return this.http.get(url_api);
  }

  public cancelBooking(id: string) {
    const urlAPI = this.globals.urlApi + `bookings/cancel-booking/byClient`;
    return this.http.post(urlAPI, { bookingId: id });
  }

  public generatePaymentToken() {
    const url_api = this.globals.urlApi + `client-payments/generateToken`;;
    return this.http.get(url_api);
  }

  public deleteBooking(id: string) {
    const urlAPI = this.globals.urlApi + `bookings/delete/${id}`;
    return this.http.delete(urlAPI);
  }

  public getReservedDates(vehicleId: string) {
    const url_api = this.globals.urlApi + `bookings/reserved-dates/${vehicleId}`;
    return this.http.get(url_api);
  }

  public generateBOPaypalToken() {
    const url_api = this.globals.urlApi + `bo-subscriptions/generateToken`;
    return this.http.get(url_api);
  }

  public hasBeenBooking(vehicleId: string) {
    const url_api = this.globals.urlApi + `bookings/hasBeenBooking/${vehicleId}`;
    return this.http.get(url_api);
  }

  public getStatus() {
    const url_api = this.globals.urlApi + `bookings-status`;
    return this.http.get(url_api);
  }
}
