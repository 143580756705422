import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-company-details',
  templateUrl: './fo-company-details.component.html',
  styleUrls: ['./fo-company-details.component.css']
})
export class FoCompanyDetailsComponent implements OnInit {

  public submitted = true;
  public companyForm: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<FoCompanyDetailsComponent>,
    private formBuilder: FormBuilder,
    private service: UsersBOService

  ) { }

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }],
      address: [{ value: '', disabled: true }],
      phoneNumber: [{ value: '', disabled: true }]
    });
    this.getCompany(this.data.companyId);
  }

  private getCompany(id: string) {
    this.service.getById(id).subscribe((data: any) => {
      this.submitted = false;
      console.log(data);
      
      this.companyForm.setValue({
        name: data.object.name,
        address: data.object.address,
        phoneNumber: data.object.phoneNumber
      });
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
      this.closeDialog();

    });
  }


  public closeDialog() {
    this.dialogRef.close();
  }

}
