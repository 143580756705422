<div class="example-container">
  <mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <span>{{ userBoName }}</span>
    <span class="example-spacer"></span>
    <div class="dropdown">
      <button mat-icon-button class="example-icon dropdown-toggle" aria-label="Example icon-button with share icon"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <mat-icon>person</mat-icon>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" routerLink="/bo/account">Tu cuenta</a>
        <a class="dropdown-item" style="cursor: pointer" (click)="Logout()">Cerrar sesión</a>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #sidenav mode="side">
      <mat-nav-list>
        <a mat-list-item [routerLink]="'/bo/account'">
          <mat-icon>home</mat-icon><span class="nav-caption">Inicio</span>
        </a>
        <a mat-list-item [routerLink]="'/bo/account-information'">
          <mat-icon>account_circle</mat-icon><span class="nav-caption">Tu cuenta</span>
        </a>
        <a mat-list-item [routerLink]="'/bo/rentcars/payments'">
          <mat-icon>credit_card</mat-icon><span class="nav-caption">Pagos</span>
        </a>
        <a mat-list-item routerLink="/bo/rentcars/vehicles">
          <mat-icon>local_taxi</mat-icon><span class="nav-caption">Mis vehiculos</span>
        </a>
        <a mat-list-item [routerLink]="'/bo/rentcars/bookings'">
          <mat-icon>calendar_today</mat-icon><span class="nav-caption">Reservas</span>
        </a>
        <a mat-list-item [routerLink]="'/bo/rentcars/pickup-config'">
          <mat-icon>location_on</mat-icon><span class="nav-caption">Puntos de entrega</span>
        </a>
        <a mat-list-item [routerLink]="'/bo/subscription'">
          <mat-icon>shopping_cart</mat-icon><span class="nav-caption">Tus Planes</span>
        </a>
        <a mat-list-item (click)="openModal()">
          <mat-icon>password</mat-icon><span class="nav-caption">Cambiar Contraseña</span>
        </a>
        <a mat-list-item (click)="Logout()">
          <mat-icon>logout</mat-icon><span class="nav-caption">Cerrar sesión</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="containerWhite container-fluid">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>