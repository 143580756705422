import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypesService {

  constructor(private http: HttpClient, private globals: Globals) { }
  public getAll() {
    const url_api =
      this.globals.urlApi + `documents-types`;
    return this.http.get(url_api);
  }
  
}
