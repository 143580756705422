<div class="row pull-right" style="margin-right: 3px">
  <button
    type="button"
    (click)="closeDialog()"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    data-toggle="tooltip"
    data-placement="top"
    title="Cerrar"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="mt-4 mb-4">
  <h2 style="text-align: center">Información del Rent a Car</h2>
</div>

<div class="row">
    <form [formGroup]="companyForm">
      <div class="col-12">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input
            matInput
            name="name"
            appearance="legacy"
            formControlName="name"
            style="color:black"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width">
          <mat-label>Dirección</mat-label>
          <textarea matInput
            name="address"
            appearance="legacy"
            formControlName="address"
            style="color:black"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width">
          <mat-label>Teléfono</mat-label>
          <input
            matInput
            name="phone"
            appearance="legacy"
            formControlName="phoneNumber"
            style="color:black"
          />
        </mat-form-field>
      </div>
      <div class="row justify-content-center mt-2">
          <button
          class="btn btn-primary btn-round btn-close"
          mat-button
          type="button"
          (click)="closeDialog()"
        >
          Salir
        </button>
      </div>
    
    </form>
  </div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
