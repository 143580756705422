export class ClientModel {
    public _id: string;
    public firstName: String = "";
    public lastName: String = "";
    public user: string;
    public email: string;
    public phone: String;
    public password: string;
    public userStatus: string;
    public documentType: String;
    public document: String;
    public address: String;
    public gender: String;
    public birthday: Date;

    constructor() {
        this.gender = new String();
        this.documentType = new String();
        this.firstName = new String();
        this.lastName = new String();
        this.phone = new String();
        this.document = new String();
        this.address = new String();
    }
}