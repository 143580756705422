import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})
export class VehiclesMakesService {

  constructor(private http: HttpClient, private globals: Globals) { }
  public getModelsByMakeId(makeId: number) {
    const url_api =
      this.globals.urlApi + `models/${makeId}`;
    return this.http.get(url_api);
  }

  public getAllMakes() {
    const url_api =
      this.globals.urlApi + `makes`;
    return this.http.get(url_api);
  }

  public getTypes() {
    const url_api =
      this.globals.urlApi + `vehicle-types`;
    return this.http.get(url_api);
  }
}
