<head>
  <script src="https://www.paypal.com/sdk/js?client-id=ATeA9yKpjfY0iosygdt18p44450XRf7lHqFTondRkPA164vcMWC22zB5eqi_ySikWFQ1VET1X5nTIX4p&components=buttons,hosted-fields"></script>
</head>
<div *ngIf="!notFound" class="col-md-10 ml-auto mr-auto">
  <div class="containerBody container-fluid">
    <div class="row">
      <h2 class="form-title hvr-icon-bounce">
        <a style="cursor: pointer" (click)="goBack()"
          ><mat-icon class="hvr-icon" style="margin-bottom: 6px"
            >keyboard_arrow_left</mat-icon
          ></a
        >
        Confirma y paga
      </h2>
    </div>
    <div class="row mt-5">
      <div class="col-sm col-md-12 col-lg-6">
        <h2>Detalle de reserva</h2>
        <div class="row mt-4">
          <div class="col-4">
            <a href="#" class="">
              <img
                class="img-fluid"
                [src]="Vehicle?.images[0]?.urlThumbnail"
                style="width: 130px; border-radius: 10px"
                alt=""
              />
            </a>
          </div>
          <div class="col-8" style="margin-bottom: 20px">
            <h3 style="margin-bottom: 2px">
              <b>{{ Vehicle?.name | titlecase }}</b>
            </h3>
            <p class="text-muted p-detail"><b>Retiro: </b>{{ pickupSite }}</p>
            <p class="text-muted p-detail"><b>Retorno:</b> {{ deliverSite }}</p>
            <p class="text-muted p-detail">
              <b>Desde</b> {{ fromDate }} <b>hasta</b> {{ toDate }}
            </p>
          </div>
        </div>
        <hr />
        <div class="row" style="margin-bottom: 20px">
          <div class="col">
            <h2>Totales</h2>
            <div class="row">
              <div class="col-6">
                <p>
                  ${{ Vehicle?.priceByDay | number }} x {{ booking.days }} días
                </p>
              </div>
              <div class="col-6">
                <p>${{ Vehicle?.priceByDay * booking.days | number }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6"><p>Tarifa de servicio</p></div>
              <div class="col-6">
                <p>${{ ServiceTariff | number }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h3><b>Total</b></h3>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >${{
                      Vehicle?.priceByDay * booking.days + ServiceTariff
                        | number
                    }}
                    {{ " " + Vehicle?.coinType }}</b
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="hide-hr" />
      </div>
      <div class="col-sm col-md-12 col-lg-5 offset-lg-1">
        <h2>
          Información de pago
          <span class="img-payments-types"
            ><span role="img" aria-label="Visa Card"
              ><img
                src="//a0.muscache.com/airbnb/static/packages/assets/frontend/legacy-shared/svgs/payments/logo_visa.0adea522bb26bd90821a8fade4911913.svg"
                alt="Visa Card"
                height="8"
                aria-hidden="true" /></span></span
          ><span class="img-payments-types"
            ><span role="img" aria-label="American Express Card"
              ><img
                src="//a0.muscache.com/airbnb/static/packages/assets/frontend/legacy-shared/svgs/payments/logo_amex.84088b520ca1b3384cb71398095627da.svg"
                alt="American Express Card"
                height="8"
                aria-hidden="true" /></span></span
          ><span class="img-payments-types"
            ><span role="img" aria-label="Mastercard"
              ><img
                src="//a0.muscache.com/airbnb/static/packages/assets/frontend/legacy-shared/svgs/payments/logo_mastercard.f18379cf1f27d22abd9e9cf44085d149.svg"
                alt="Mastercard"
                height="8"
                aria-hidden="true" /></span
          ></span>
        </h2>
        <!-- <form
          autocomplete="off"
          name="paymentForm"
          #paymentForm="ngForm"
          (ngSubmit)="paymentForm.valid && processBooking()"
        >
          <div class="row mt-4">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Número de tarjeta</mat-label>
                <input
                  matInput
                  required
                  [(ngModel)]="creditCard.cardNumber"
                  name="cardnumber"
                  #cardnumber="ngModel"
                  [mask]="cardMask"
                  (input)="validateMask($event.target.value)"
                  (focusout)="onCardFocusOut()"
                />
                <mat-icon matSuffix>credit_card</mat-icon>
                <mat-error *ngIf="cardnumber.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
              <div
                *ngIf="invalidCard"
                style="color: red; font-size: 10.5px; margin-top: -10px"
              >
                Tarjeta inválida
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Nombre en la tarjeta</mat-label>
                <input
                  matInput
                  required
                  [(ngModel)]="creditCard.cardholderName"
                  name="cardholder"
                  #cardholder="ngModel"
                  required
                />
                <mat-icon matSuffix>contact_page</mat-icon>
                <mat-error *ngIf="cardholder.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
           <div class="row mt-1">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  required
                  [(ngModel)]="creditCard.email"
                  disabled
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-icon matSuffix>email</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Fecha expiración</mat-label>
                <input
                  matInput
                  required
                  name="expiration"
                  #expiration="ngModel"
                  placeholder="Mes/Año"
                  (input)="validateExpiresDate($event.target.value)"
                  [(ngModel)]="creditCard.expiresDate"
                />
                <mat-error *ngIf="expiration.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
              <div
                *ngIf="invalidCardDate"
                style="color: red; font-size: 10.5px; margin-top: -10px"
              >
                La fecha de su tarjeta ha expirado
              </div>
            </div>
            <div class="col-6">
              <mat-form-field
                style="width: 100%; margin-bottom: -10px"
                appearance="outline"
              >
                <mat-label>CVV</mat-label>
                <input
                  matInput
                  required
                  name="cvv"
                  #cvv="ngModel"
                  placeholder="123"
                  [(ngModel)]="creditCard.cvv"
                  maxlength="4"
                  minlength="3"
                />
                <mat-error *ngIf="cvv.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center mt-4">
            <div class="col-8 ml-auto mr-auto">
              <button
                class="btn btn-primary"
                style="
                  background-color: #2b2e2f;
                  width: 100%;
                  border-radius: 18px;
                  height: 40px;
                  animation: show-text 1s 0s forwards;
                "
                mat-button
                type="submit"
              >
                <span
                  class="hvr-icon-pop"
                  style="font-size: 16px; text-transform: capitalize"
                  >Pagar <span style="text-transform: lowercase">con</span>
                  <i
                    class="fa fa-credit-card fa-5x hvr-icon"
                    style="margin-left: 8px; margin-top: -20px"
                  ></i
                ></span>
              </button>
            </div>
          </div>
        </form> -->

        <div class="row text-center mt-4">
          <div class="col-8 ml-auto mr-auto">
            <div id="paypal-button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notFound">
  <app-fo-not-found [ErrorMessage]="error"></app-fo-not-found>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
