import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-forgot-password',
  templateUrl: './fo-forgot-password.component.html',
  styleUrls: ['./fo-forgot-password.component.css']
})
export class FoForgotPasswordComponent implements OnInit {
  public submitted = false;
  public emailReset = '';
  public emailSubmitted = false;
  constructor(
    private service: ClientsService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    if(this.service.setLoginValue()){
      this.router.navigate(['fo/client/account']);
    }
  }

  public closeDialog(): void {

  }

  public sendResetPassword() {
    if (!this.emailReset) {
      Swal.fire({
        icon: 'info',
        title: "Por favor escriba su correo para continuar"
      });
      return;
    }
    this.submitted = true;
    this.service.requestResetPassword(this.emailReset).subscribe((data) => {
      this.submitted = false;
      this.emailSubmitted = true;
      Swal.fire({
        icon: 'success',
        title: "Correo de recuperación envíado.",
        text: "Si este correo coincide con una cuenta activa, envíaremos un link donde podrá restablecer su contraseña."
      });
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })
  }

  public goToProfile(){
    this.router.navigate(['fo/client/account']);
  }
}
