import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ClientsService } from './clients.service';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

const oAuthConfig: AuthConfig = {
  issuer: 'https://accounts.google.com',
  strictDiscoveryDocumentValidation: false,
  redirectUri: window.location.origin,
  clientId: '814398364627-sjm40k1crgphpk7j7t159h8a93uh9vp9.apps.googleusercontent.com',
  scope: 'openid profile email'
}

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  constructor(
    private readonly oAuthService: OAuthService,
    public clientService: ClientsService,
  ) {
    oAuthService.configure(oAuthConfig);

  }

  public checkUserLogged() {
    this.oAuthService.loadDiscoveryDocument().then(() => {
      this.oAuthService.tryLoginImplicitFlow().then(() => {
        if (this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.loadUserProfile().then((userProfile: any) => {
            this.clientService.loginGoogle(this.encrypt(JSON.stringify(userProfile.info))).subscribe((data: any) => {
              localStorage.setItem('Token', data.object.token);
              this.clientService.isUserFOLoggedIn = true;
              this.clientService.getLoggedInfo();
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: data.object.message,
              })
            })
          })
        }
      })
    })
  }

  public initGoogleLogin() {
    this.oAuthService.loadDiscoveryDocument().then(() => {
      this.oAuthService.tryLoginImplicitFlow().then(() => {
        if (!this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.initLoginFlow();
        }
      })
    })
  }

  public logOut() {
    this.oAuthService.logOut();
  }

  encrypt(value: string): string {
    const sk = `9a4c66795b56d79a0e62bf2d6d858854a6bbc40c1f9f7619f74997e108bb243b`
    return CryptoJS.AES.encrypt(value, sk).toString();

  }
}
