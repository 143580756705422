<div class="tz-gallery">
  <div class="row justify-content-center justify-content-sm-center">
    <div
      *ngFor="let vehicle of vehicles; let i = index"
      class="col-sm-4 col-md-3 d-flex justify-content-center"
      (click)="goToVehicleProfile(vehicle._id)"
    >
      <div class="thumbnail">
        <a class="lightbox">
          <img class="img-fluid" [src]="vehicle.images[0].urlThumbnail" alt="Slide img" />
        </a>
        <h3 style="text-align: center">
          {{ vehicle.name | titlecase }}
        </h3>
        <div class="row">
          <div class="col-9">
            <div class="caption">
              <p>
                <b>Precio: </b>{{ vehicle.priceByDay | number }}
                {{ " " + vehicle.coinType + "$" }}
              </p>
              <p><b>Comb.:</b> {{ vehicle.vehiclesTypes[0].name }}</p>
              <p><b>Tipo:</b> {{ vehicle.vehiclesFuel[0].name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
