import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { GoogleApiService } from 'src/app/services/google-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fo-login',
  templateUrl: './fo-login.component.html',
  styleUrls: ['./fo-login.component.css'],
})
export class FoLoginComponent implements OnInit {
  constructor(
    private clientService: ClientsService,
    public dialogRef: MatDialogRef<FoLoginComponent>,
    public router: Router,
    private readonly google: GoogleApiService
  ) {

  }
  public Password: string;
  public Username: string;
  public submitted = false;

  ngOnInit(): void {
  }

  public login() {
    this.submitted = true;
    this.clientService.login(this.Username, this.Password).subscribe(
      (data: any) => {
        this.submitted = false;
        localStorage.setItem('Token', data.object);
        this.clientService.isUserFOLoggedIn = true;
        this.clientService.getLoggedInfo();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: data.message,
        })
        const ruta = window.location.href;
        if (ruta.includes('/fo/clients/forgot-password') || ruta.includes('fo/clients/reset-password/')) {
          this.router.navigate(['fo/client/account']);
        }
        this.dialogRef.close({ message: "Ok", data: data.object });
      },
      (error) => {
        console.log(error);
        this.submitted = false;
        Swal.fire({
          icon: error.error.code === 412 ? 'info' : 'error',
          title: error.error.title,
          text: error.error.message,
        });
      }
    );
  }

  public forgotPassword() {
    this.router.navigate(['fo/clients/forgot-password']);
    this.dialogRef.close();
  }

  public createAccount(){
    this.router.navigate(['fo/signup']);
    this.dialogRef.close();
  }

  public googleLogin(){
    this.google.initGoogleLogin();
  }
}
