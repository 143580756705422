<div class="row mt-3">
  <div class="col-lg-3 col-sm-12 col-md-4 mt-4 pl-5">
    <h4 class="text-uppercase font-weight-bold mb-3">Informacion de reserva</h4>
    <div class="pl-2">
      <mat-form-field class="booking-select-width">
        <mat-label>Punto de retiro/retorno</mat-label>
        <input
          matInput
          aria-label="State"
          [matAutocomplete]="auto"
          [formControl]="stateCtrl"
          required
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="setSelectedSite($event.option.value)"
        >
          <mat-option
            *ngFor="let location of filteredLocations"
            [value]="location.name"
          >
            <span>{{ location.name }}</span>
          </mat-option>
          <mat-option *ngIf="filteredLocations.length === 0 && stateCtrl.value.length > 0">
            <span>Sin resultados</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="stateCtrl.errors?.required">
          Este campo es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="pl-2">
      <div class="row">
        <div class="col">
          <mat-form-field class="booking-select-width">
            <mat-label>Fecha desde</mat-label>
            <input
              matInput
              [min]="today"
              [(ngModel)]="startDate"
              [matDatepicker]="picker"
              (dateInput)="setDateParameters()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="booking-select-width">
            <mat-label>Fecha hasta</mat-label>
            <input
              matInput
              [min]="startDate"
              [matDatepicker]="pickerTo"
              [(ngModel)]="endDate"
              (dateInput)="setDateParameters()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- <mat-form-field class="booking-select-width">
        <mat-label>Elige un rango de fecha</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker" [min]="today">
          <input
            [(ngModel)]="startDate"
            matStartDate
            placeholder="Fecha desde"
          />
          <input [(ngModel)]="endDate" matEndDate placeholder="Fecha hasta" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="rangePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancelar</button>
            <button
              (click)="setDateParameters()"
              mat-raised-button
              color="primary"
              matDateRangePickerApply
              style="background-color: #0f387a"
            >
              Aplicar
            </button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field> -->
    </div>

    <div class="pl-2 mt-3 mb-3" style="text-align: center">
      <a
        aria-expanded="true"
        class="filter-ctrl text-uppercase font-weight-bold"
        data-toggle="collapse"
        href="#multiCollapseExample1"
        role="button"
        aria-expanded="true"
        aria-controls="multiCollapseExample1"
      >
        Filtros <i class="fa fa-filter" aria-hidden="true"></i>
      </a>
    </div>

    <div
      class="collapse"
      [ngClass]="showFiller ? 'show' : ''"
      id="multiCollapseExample1"
    >
      <!-- <h4 class="text-uppercase font-weight-bold">Ordenar por</h4> -->
      <div class="mb-2 pl-2" style="margin-top: -12px">
        <mat-form-field class="booking-select-width">
          <mat-select
            placeholder="Ordenar por"
            name="orderBy"
            (selectionChange)="orderBy($event.value)"
          >
            <mat-option value="priceByDay asc">
              Precio: Menor a mayor
            </mat-option>
            <mat-option value="priceByDay desc">
              Precio: Mayor a menor
            </mat-option>
            <mat-option value="year desc"> Año: Nuevo a viejo </mat-option>
            <mat-option value="year asc"> Año: Viejo a nuevo </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <h4 class="text-uppercase mb-3 font-weight-bold">Tipo de vehiculo</h4>
      <mat-radio-group id="vehicleType">
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            id="allVehiclesTypes"
            value="All"
            [checked]="checkedAll.vehiclesTypes"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Todos</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Sedan"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Sedan</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Compacto"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Compacto</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Jeepeta"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Jeepeta</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Camioneta"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Camioneta</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Coupe/Sport"
            (change)="updateFilters($event, 'vehiclesTypes', '==', 'name')"
            >Coupe/Sport</mat-radio-button
          >
        </div>
      </mat-radio-group>
      <h4 class="text-uppercase mt-3 mb-3 font-weight-bold">Combustible</h4>
      <mat-radio-group id="vehicleFuelType">
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            id="allVehicleFuelType"
            [checked]="checkedAll.vehiclesFuelType"
            value="All"
            (change)="updateFilters($event, 'vehiclesFuel', '==', 'name')"
            >Todos</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Gasolina"
            (change)="updateFilters($event, 'vehiclesFuel', '==', 'name')"
            >Gasolina</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Gas"
            (change)="updateFilters($event, 'vehiclesFuel', '==', 'name')"
            >Gas</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Gasoil"
            (change)="updateFilters($event, 'vehiclesFuel', '==', 'name')"
            >Gasoil</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="Electrico"
            (change)="updateFilters($event, 'vehiclesFuel', '==', 'name')"
            >Electrico</mat-radio-button
          >
        </div>
      </mat-radio-group>
      <h4 class="text-uppercase mt-3 mb-3 font-weight-bold">Precio</h4>
      <mat-radio-group id="vehicleFuelType">
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            id="allPrice"
            [checked]="checkedAll.vehiclesFuelType"
            value="All"
            (change)="updateFilters($event, 'priceByDay', '>')"
            >Todos</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="50"
            (change)="updateFilters($event, 'priceByDay', '<')"
            >Menos de 50</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="100"
            (change)="updateFilters($event, 'priceByDay', '<')"
            >Menos de 100</mat-radio-button
          >
        </div>
        <div class="mt-2 mb-2 pl-2">
          <mat-radio-button
            value="200"
            (change)="updateFilters($event, 'priceByDay', '>')"
            >Mas de 200</mat-radio-button
          >
        </div>
      </mat-radio-group>
      <!-- <mat-form-field appearance="fill" style="width: 50px">
        <input matInput placeholder="Min" />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 50px; margin-left: 5px">
        <input matInput placeholder="Max" />
      </mat-form-field> -->
    </div>
  </div>
  <div class="col mt-4">
    <fo-rentcar-vehicles
      [Vehicles]="vehiclesCopy"
      [Params]="selectedParams"
    ></fo-rentcar-vehicles>
  </div>
</div>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
