import { Routes } from '@angular/router';
import { BoModule } from './bo.module';
import { BoLayoutComponent } from './bo/bo-layout/bo-layout.component';
import { FohomeComponent } from './fo/fo-home/fohome.component';
import { FoLayoutComponent } from './fo/fo-layout/fo-layout.component';
import { FoRentcarsSearchComponent } from './fo/fo-rentcars-search/fo-rentcars-search.component';
import { FoSignupComponent } from './fo/fo-signup/fo-signup.component';
import { RentCarComponent } from './fo/rent-car/rent-car.component';
import { FoClientLayoutComponent } from './fo/fo-clients/fo-client-layout/fo-client-layout.component';
import { FoAccountComponent } from './fo/fo-clients/fo-account/fo-account.component';
import { PersonalInfoComponent } from './fo/fo-clients/personal-info/personal-info.component';
import { FoRentcarVehiclesProfileComponent } from './fo/fo-rentcar-vehicles-profile/fo-rentcar-vehicles-profile.component';
import { FoDealersComponent } from './fo/fo-dealers/fo-dealers.component';
import { FoRentcarConfirmAndPayComponent } from './fo/fo-rentcar-confirm-and-pay/fo-rentcar-confirm-and-pay.component';
import { FoClientBookingsComponent } from './fo/fo-clients/fo-client-bookings/fo-client-bookings.component';
import { FoClientsAccountConfirmComponent } from './fo/fo-clients-account-confirm/fo-clients-account-confirm.component';
import { FoForgotPasswordComponent } from './fo/fo-forgot-password/fo-forgot-password.component';
import { FoClientsResetPasswordComponent } from './fo/fo-clients-reset-password/fo-clients-reset-password.component';
import { FoClientPaymentsComponent } from './fo/fo-clients/fo-client-payments/fo-client-payments.component';
export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'fo/rent-cars',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FoLayoutComponent,
    children: [
      {
        path: 'fo/home',
        component: FohomeComponent,
      },
      {
        path: 'fo/rent-cars',
        component: RentCarComponent,
      },
      {
        path: 'fo/rent-cars/search',
        component: FoRentcarsSearchComponent,
      },
      {
        path: 'fo/rent-cars/search/:location',
        component: FoRentcarsSearchComponent,
      },
      {
        path: 'fo/rent-cars/vehicle-profile/:id',
        component: FoRentcarVehiclesProfileComponent,
      },
      {
        path: 'fo/rent-cars/booking-pay/:id',
        component: FoRentcarConfirmAndPayComponent
      },
      {
        path: 'fo/signup',
        component: FoSignupComponent,
      },
      {
        path: 'fo/dealers',
        component: FoDealersComponent,
      },
      {
        path: 'fo/clients/account-confirmation/:code',
        component: FoClientsAccountConfirmComponent,
      },
      {
        path: 'fo/clients/forgot-password',
        component: FoForgotPasswordComponent,
      },
      {
        path: 'fo/clients/reset-password/:code',
        component: FoClientsResetPasswordComponent,
      },
      {
        path: 'fo/client',
        component: FoClientLayoutComponent,
        children: [
          {
            path: 'account',
            component: FoAccountComponent,
          },
          {
            path: 'personal-info',
            component: PersonalInfoComponent,
          },
          {
            path: 'bookings',
            component: FoClientBookingsComponent,
          },
          {
            path: 'payments',
            component: FoClientPaymentsComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: BoLayoutComponent,
    children: [
      {
        path: 'bo',
        loadChildren: () => BoModule,
      },
    ],
  },
];
