export class CompanyModel {
  public _id: string;
  public name: string;
  public rnc: string;
  public email: string;
  public password: string = '';
  public address: string;
  public cityId: string;
  public ownerName: string;
  public document: string;
  public phoneNumber: string;
  public mobileNumber: string;
  public documentTypeId: string = '';
  public userTypeId: string = '';
  public city: City;
  constructor() {
    this.city = new City();
  }
}



export class City {
  constructor() {
    this.country = new Country();
  }
  public _id: string;
  public name: string;
  public country: Country;
}

export class Country {
  public _id: string;
  public name: string;
}