import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-display',
  templateUrl: './success-display.component.html',
  styleUrls: ['./success-display.component.css']
})
export class SuccessDisplayComponent implements OnInit {
  @Input() SuccessMessage: string;
  @Input() TitleMessage: string;
  constructor() { }

  ngOnInit(): void {
  }

}
