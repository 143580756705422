import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientsService } from 'src/app/services/clients.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-booking-client',
  templateUrl: './bo-booking-client.component.html',
  styleUrls: ['./bo-booking-client.component.css']
})
export class BoBookingClientComponent implements OnInit {
  // public client: any;
  public submitted = true;
  public clientForm: FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private clientService: ClientsService,
    public dialogRef: MatDialogRef<BoBookingClientComponent>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      fullName: [{ value: '', disabled: true }],
      phone: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }]
    });
    this.getClient(this.data.clientId);
  }

  public closeDialog() {
    this.dialogRef.close("false");
  }

  private getClient(id: string) {
    this.clientService.getById(id).subscribe((data: any) => {
      this.submitted = false;
      this.clientForm.setValue({
        fullName: data.object.firstName + " " + data.object.lastName,
        phone: data.object.phone,
        email: data.object.user.email,
      });
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
      this.closeDialog();

    });
  }

}
