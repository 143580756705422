<div class="row justify-content-center">
  <div class="col-md-auto" style="margin-bottom: 15px">
    <div>
      <a routerLink="/fo/client/personal-info">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <svg
                  viewBox="0 0 24 24"
                  role="presentation"
                  aria-hidden="true"
                  focusable="false"
                  style="
                    height: 101px;
                    width: 73px;
                    display: block;
                    fill: rgb(96, 182, 181);
                  "
                >
                  <path
                    d="m18.66 6.51-14.84 9.65a1 1 0 0 0 .55 1.84h15.62a1 1 0 0 0 1-1v-9.24a1.5 1.5 0 0 0 -2.32-1.26z"
                  ></path>
                  <path
                    d="m9.25 12a1.25 1.25 0 1 1 -1.25-1.25 1.25 1.25 0 0 1 1.25 1.25zm11.75-8h-14a .5.5 0 0 0 0 1h14a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-1.5a.5.5 0 0 0 0 1h1.5a2 2 0 0 0 2-2v-12a2 2 0 0 0 -2-2zm-5 15h-13a1 1 0 0 1 -1-1v-12a1 1 0 0 1 1-1h1a .5.5 0 0 0 0-1h-1a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h13a .5.5 0 0 0 0-1zm-7.17-11.17a4.25 4.25 0 1 0 3.42 4.17 4.21 4.21 0 0 0 -.46-1.92.5.5 0 0 0 -.89.45 3.25 3.25 0 0 1 -.61 3.77 3.67 3.67 0 0 0 -4.56.02 3.25 3.25 0 0 1 2.27-5.57 3.3 3.3 0 0 1 .63.06.5.5 0 1 0 .19-.98zm5.67 3.17h5.5a.5.5 0 0 0 0-1h-5.5a.5.5 0 0 0 0 1zm0 2h5.5a.5.5 0 0 0 0-1h-5.5a.5.5 0 0 0 0 1zm0 2h5.5a.5.5 0 0 0 0-1h-5.5a.5.5 0 0 0 0 1z"
                    fill="#484848"
                  ></path>
                </svg>
              </div>
              <div class="col">
                <h2 class="card-title">Información Personal</h2>
                <p class="card-text">Informacion personal y de contacto</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a (click)="openModal()">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  src="../../../../assets/img/bo/login-security.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Restablecer contraseña</h2>
                <p class="card-text">
                  Cambia tu contraseña
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/fo/client/payments">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  style="width: 98px; margin-left: -10px"
                  src="../../../../assets/img/bo/payments.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Tus pagos</h2>
                <p class="card-text">Ver histórico de transacciones</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto">
    <div>
      <a routerLink="/fo/client/bookings">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  style="width: 85px; margin-left: -12px; margin-top: 13px"
                  src="../../../../assets/img/bo/scheduled-icon.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Reservas</h2>
                <p class="card-text">Administra tus reservas, ver histórico</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="col-md-auto" style="opacity: 0%;">
    <div>
      <a>
        <div class="card" style="cursor: default;">
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <img
                  class="pull-left img-style"
                  alt="Inicio de sesión y seguridad"
                  style="width: 70px; margin-left: -12px"
                  src="../../../../assets/img/bo/notification.png"
                />
              </div>
              <div class="col">
                <h2 class="card-title">Notificaciones</h2>
                <p class="card-text">Actualizaciones y notificaciones</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>

<div class="col-md-auto" style="  visibility:hidden;">
  <div>
    <a>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <img
                class="pull-left img-style"
                alt="Inicio de sesión y seguridad"
                style="width: 85px; margin-left: -12px"
                src="../../../../assets/img/bo/scheduled-icon.png"
              />
            </div>
            <div class="col">
              <h2 class="card-title">Reservas</h2>
              <p class="card-text">Administra tus reservas, ver histórico</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
</div>

