import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientsService } from 'src/app/services/clients.service';
import { GoogleApiService } from 'src/app/services/google-api.service';
import { FoLoginComponent } from '../modals/fo-login/fo-login.component';

@Component({
  selector: 'app-fonavbar',
  templateUrl: './fonavbar.component.html',
  styleUrls: ['./fonavbar.component.css'],
})
export class FonavbarComponent implements OnInit {
  public name = "";
  constructor(
    public clientService: ClientsService,
    public loginModal: MatDialog,
    private googleApi: GoogleApiService
  ) { }

  ngOnInit(): void {
    this.clientService.getLoggedInfo$().subscribe(loggedInfo => {
      if (loggedInfo !== null) {
        this.name = loggedInfo.firstName;
      } else {
        this.name = "";
      }
    });
    if (this.clientService.setLoginValue()) {
      this.clientService.getLoggedInfo();
    } else {
       this.googleApi.checkUserLogged();
    }
  }

  public logout() {
    this.googleApi.logOut();
    this.clientService.logout();
  }

  public openFoLoginModal() {
    this.loginModal.closeAll();
    const dialogRef = this.loginModal.open(FoLoginComponent, {
      height: 'auto',
      width: 'auto',
      minWidth: '400px',
      maxHeight: '650px',
      panelClass: 'modal-transparent',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.message == "Ok") {
          this.loginModal.closeAll();
          this.name = result.data.client.name;
        } else {
          this.validateLoggin()
        }
      } else
        this.validateLoggin();
    });;
  }

  public validateLoggin() {
    let isLoggedIn = this.clientService.setLoginValue();
    const ruta = window.location.href;
    if (ruta.includes('/fo/client/') && !isLoggedIn) {
      this.openFoLoginModal();
    }
    if (isLoggedIn) {
      this.clientService.getLoggedInfo();
    }
  }

  public closeFoLoginModal(){
    this.loginModal.closeAll();
  }
}
