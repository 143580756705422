import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { Globals } from '../global';
import { ClientModel } from '../models/fo/client.model';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  public isUserFOLoggedIn: boolean;
  public loggedInfo: any;
  public loggedInfo$: Subject<any>;;

  constructor(
    private http: HttpClient,
    private router: Router,
    private globals: Globals,
  ) {
    this.loggedInfo$ = new Subject();
  }

  public postClient(user: ClientModel) {
    const urlAPI = this.globals.urlApi + `clients`;
    return this.http.post(urlAPI, user);
  }

  public changePassword(config: any) {
    const urlAPI = this.globals.urlApi + `auth/client/change-password`;
    return this.http.post(urlAPI, config);
  }

  public resetPassword(config: any) {
    const urlAPI = this.globals.urlApi + `auth/clients/reset-password`;
    return this.http.post(urlAPI, config);
  }

  public requestResetPassword(email: string) {
    const urlAPI = this.globals.urlApi + `clients/forgot-password`;
    return this.http.post(urlAPI, { email });
  }

  public putClient(user: ClientModel) {
    const urlAPI = this.globals.urlApi + `clients`;
    return this.http.put(urlAPI, user);
  }

  public login(username: string, password: string) {
    const urlAPI = this.globals.urlApi + `auth/clients/login`;
    let user = { email: username, password: password };
    return this.http.post(urlAPI, user);
  }


  public loginGoogle(payload: any) {
    const urlAPI = this.globals.urlApi + `auth/google-login`;
    return this.http.post(urlAPI, { data: payload });
  }

  public clientConfirmation(code: string) {
    const urlAPI = this.globals.urlApi + `clients/confirmation`;
    return this.http.post(urlAPI, { code: code });
  }

  public checkResetPasswordCode(code: string) {
    const urlAPI = this.globals.urlApi + `clients/confirmation/checkCode`;
    return this.http.post(urlAPI, { code: code });
  }

  public getClientInfo() {
    const url_api = this.globals.urlApi + `clients/me`;
    return this.http.get(url_api);
  }

  public getById(id: string) {
    const url_api = this.globals.urlApi + `clients/getById/${id}`;
    return this.http.get(url_api);
  }

  public getLoggedInfo() {
    const url_api = this.globals.urlApi + `clients/me`;
    this.http.get(url_api).subscribe((data: any) => {
      this.loggedInfo$.next(data.object);
    }, error => {
      this.loggedInfo$.next(null);
      this.logout();
    });
  }

  public getLoggedInfo$(): Observable<any> {
    return this.loggedInfo$.asObservable();
  }

  setLoginValue(): boolean {
    let Token = localStorage.getItem('Token');
    Token
      ? (this.isUserFOLoggedIn = true)
      : (this.isUserFOLoggedIn = false);
    return this.isUserFOLoggedIn;
  }

  public logout() {
    this.isUserFOLoggedIn = false;
    localStorage.clear();
    // localStorage.removeItem("Token");
    this.loggedInfo$.next(null);
    const ruta = window.location.href;
    if (ruta.includes('/fo/client')) {
      this.router.navigate(['fo/rent-cars']);
    }
  }

  public logoutNotRedirect() {
    this.isUserFOLoggedIn = false;
    this.loggedInfo$.next(null);
    localStorage.clear();
    // localStorage.removeItem("Token");
  }
}
