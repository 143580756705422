import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehicleRentCarModel } from 'src/app/models/vehicles-rentcar.models';
import { BookingsService } from 'src/app/services/bookings.service';
import { VehiclesFuelTypesService } from 'src/app/services/vehicles-fuel-types.service';
import { VehiclesMakesService } from 'src/app/services/vehicles-makes.service';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import { YearsService } from 'src/app/services/years.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-vehicles-add',
  templateUrl: './bo-vehicles-add.component.html',
  styleUrls: ['./bo-vehicles-add.component.css'],
})
export class BoVehiclesAddComponent implements OnInit {
  public fuelTypes: any[];
  public makes: any[];
  public models: any[];
  public years: any[];
  public submitted = true;
  public vehicle: VehicleRentCarModel;
  public selectMake = new FormControl('', [Validators.required]);
  public selectModels = new FormControl('', [Validators.required]);
  public selectYears = new FormControl('', [Validators.required]);
  public selectTypes = new FormControl('', [Validators.required]);
  public selectFuel = new FormControl('', [Validators.required]);
  public selectCoins = new FormControl('', [Validators.required]);
  public types;
  public chargedImg = [];
  public hasBookings = false;
  public hasBeenBookings = false;
  private editing = false;
  pondFiles = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private makeServices: VehiclesMakesService,
    private fuelTypesServices: VehiclesFuelTypesService,
    private rentCService: VehiclesRentCarService,
    private yearsService: YearsService,
    public dialogRef: MatDialogRef<BoVehiclesAddComponent>,
    private bookingService: BookingsService,
  ) { }

  ngOnInit(): void {
    this.getMakes();
    this.vehicle = new VehicleRentCarModel();
    if (!this.data.vehicle) {
      this.editing = false;
    } else {
      this.getVehicleById(this.data.vehicle);
      this.editing = true;
    }

  }

  public getVehicleById(id: string) {
    this.submitted = true;
    this.rentCService.getById(id).subscribe((data: any) => {
      this.vehicle._id = id;
      this.vehicle.coinType = data.object.coinType;
      this.vehicle.makeId = data.object.make._id;
      this.vehicle.modelId = data.object.model._id;
      this.vehicle.placa = data.object.placa;
      this.vehicle.priceByDay = data.object.priceByDay;
      this.vehicle.year = data.object.year;
      this.vehicle.fuelTypeId = data.object.vehiclesFuel._id;
      this.vehicle.vehicleTypeId = data.object.vehiclesTypes._id;
      this.chargedImg = this.vehicle.images = data.object.images;
      this.getModelsByMakeId(data.object.make._id)
      this.submitted = false;
      this.checkVehicleBookings(id);
    }, err => {
      this.submitted = false;
    })
  }

  public checkVehicleBookings(id: string) {
    this.bookingService.getReservedDates(id).subscribe((data: any) => {
      if (data.object.length > 0) {
        this.hasBookings = true;
      }
    });
    this.bookingService.hasBeenBooking(id).subscribe((data: any) => {
      this.hasBeenBookings = data.object.hasBeenBooking;
    })
  }

  public getMakes() {
    this.submitted = true;
    this.makeServices.getAllMakes().subscribe(
      (data: any) => {
        this.makes = data.object;
        this.getYears();
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public getYears() {
    this.yearsService.getYears().subscribe((data: any) => {
      this.years = data.object;
      this.getFuelTypes();
    })
  }

  public getModelsByMakeId(value: number) {
    this.submitted = true;
    this.makeServices.getModelsByMakeId(value).subscribe(
      (data: any) => {
        this.models = data.object;
        this.submitted = false;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public getFuelTypes() {
    this.fuelTypesServices.getAll().subscribe(
      (data: any) => {
        this.fuelTypes = data.object;
        this.getTypes();
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public getTypes() {
    this.makeServices.getTypes().subscribe(
      (data: any) => {
        this.submitted = false;
        this.types = data.object;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public saveCar() {

    if (this.vehicle.images.length == 0) {
      Swal.fire({
        icon: 'info',
        title: "Atención",
        text: "Para guardar el vehículo debe elegir al menos 1 imagen.",
      });
      return;
    }
    this.submitted = true;
    if (this.editing) {
      this.rentCService.putVehicleRentCar(this.vehicle).subscribe(
        (data: any) => {
          this.submitted = false;
          Swal.fire({
            icon: 'success',
            text: "Vehículo actualizado correctamente",
          });
          this.dialogRef.close("Ok");
        },
        (error) => {
          this.submitted = false;
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });

        }
      );
    } else {
      this.rentCService.postVehicleRentCar(this.vehicle).subscribe(
        (data: any) => {
          this.submitted = false;
          console.log(data);
          Swal.fire({
            icon: 'success',
            text: "Vehículo guardado correctamente",
          });
          this.dialogRef.close("Ok");
        },
        (error) => {
          this.submitted = false;
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });

        }
      );
    }

  }

  public addImage(image: any): void {
    this.vehicle.images.push(image);
    console.log(this.vehicle.images);

  }

  public deleteImage(name: string): void {
    let i = this.vehicle.images.find(({ FileName, publicId }) => FileName === name || publicId === name);
    if (i) {
      this.vehicle.images.splice(this.vehicle.images.indexOf(i), 1);
    } else {
      let res = this.vehicle.images.filter((f) =>
        f.secureUrlThumnail.toLowerCase().includes(name)
      );
      for (let item in res) {
        this.vehicle.images.splice(this.vehicle.images.indexOf(item), 1);
      }
    }

  }

  public closeDialog() {
    this.dialogRef.close("false");
  }




}
