<div *ngIf="isError === false" style="background-color: #f7f7f7; margin: top 20px !important">
  <div class="row justify-content-center">
    <form
      name="emailForm"
      #emailForm="ngForm"
      (ngSubmit)="emailForm.valid && sendResetPassword()"
    >
      <div class="card mt-4">
        <div class="card-body" *ngIf="!changedPassword">
          <h1
            class="card-title text-center"
            style="color: #0f387a; margin-top: 15px; font-weight: 500"
          >
            Ingresa tu nueva contraseña
          </h1>
          <!-- <p class="card-subtitle" style="font-size: 14px; margin-top: 20px">
              ¡No hay problema! Te enviaremos un enlace a tu correo para
              restablecerla.
            </p> -->
          <div class="row justify-content-center mt-4">
            <div class="col-12 mb-1">
              <mat-form-field class="example-full-width">
                <mat-label>Nueva contraseña</mat-label>
                <input
                  matInput
                  name="newPassword"
                  #newPassword="ngModel"
                  [(ngModel)]="settings.newPassword"
                  appearance="legacy"
                  type="password"
                  minlength="8"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}"
                  required
                />
                <mat-error *ngIf="newPassword.errors?.required">
                  Este campo es obligatorio
                </mat-error>
                <mat-error *ngIf="newPassword.errors?.minlength">
                  Su clave debe contener al menos 8 dígitos
                </mat-error>
                <mat-error *ngIf="newPassword.errors?.pattern">
                  Su clave debe contener al menos una minúscula, una mayúscula,
                  y un número
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 mb-2">
              <mat-form-field class="example-full-width">
                <mat-label>Confirmar contraseña</mat-label>
                <input
                  matInput
                  name="newPasswordRepeat"
                  #newPasswordRepeat="ngModel"
                  [(ngModel)]="settings.newPasswordRepeat"
                  appearance="legacy"
                  required
                  type="password"
                />
                <mat-error *ngIf="newPasswordRepeat.errors?.required">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
              <div
                *ngIf="
                  settings.newPasswordRepeat.length > 0 &&
                  settings.newPassword !== settings.newPasswordRepeat
                "
                style="color: red; font-size: 10.5px; margin-top: -18px"
              >
                Las contraseñas no coinciden
              </div>
            </div>
            <div class="row row justify-content-center mt-4">
              <button
                class="btn btn-primary btn-round"
                style="background-color: #134699; font-weight: 600"
                mat-button
                type="submit"
              >
                Cambiar contraseña
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="changedPassword">
          <h1
            class="card-title text-center"
            style="color: #0f387a; margin-top: 15px; font-weight: 500"
          >
            Contraseña cambiada
          </h1>
          <p
            class="card-subtitle"
            style="
              font-size: 14px;
              margin-top: 20px;
              text-align: justify;
              line-height: 23px;
            "
          >
            Inicia sesión con tu nueva contraseña en Carros Facil.
          </p>
          <div class="row row justify-content-center mt-5">
            <button
              class="btn btn-primary btn-round"
              style="background-color: white; color: #134699; font-weight: 600"
              mat-button
              type="buttom"
              (click)="goToProfile()"
            >
              Iniciar sesión
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="text-center">
    <img
      class="img-fluid"
      src="https://res.cloudinary.com/carros-facil/image/upload/v1658924494/assets/minion-success_p4ndm6.png"
      alt="Success image"
      style="max-width: 345px; margin-left: 25px"
    />
  </div>
</div>

<div *ngIf="isError === true">
  <app-fo-not-found
    [ErrorMessage]="'Enlace inválido o expirado'"
  ></app-fo-not-found>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
