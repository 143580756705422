<div class="row pull-right" style="margin-right: 3px">
  <button
    type="button"
    (click)="closeDialog()"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    data-toggle="tooltip"
    data-placement="top"
    title="Cerrar"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="mt-4 mb-4">
  <h2 style="text-align: center">Información de la reserva</h2>
</div>

<div class="col-12">
  <div style="padding: 8px">
    <h2 style="font-weight: 400; font-size: 18px">
      {{ booking.vehicle.name | titlecase }}
    </h2>
    <p style="margin-top: -12px">
      {{ booking.fromDate | date: "d MMM y" }} –
      {{ booking.toDate | date: "d MMM y" }}
    </p>
  </div>

  <!-- <div class="card" style="margin-top: -4px; min-width: 278px">
      <div class="card-body"> -->
  <div class="row">
    <div class="col-md-auto text-center" style="margin-bottom: 20px">
      <img
        [src]="booking.vehicle.image.secureUrlThumbnail"
        width="160"
        height="120"
        alt=""
      />
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <p><b>Retiro: </b>{{ booking.pickupSite.name }}</p>
          <p><b>Retorno: </b>{{ booking.deliverSite.name }}</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <p class="text-muted" style="margin-top: -12px">
            {{ booking.fromDate | date: "d MMM y" }} –
            {{ booking.toDate | date: "d MMM y" }}
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <p style="margin-top: -12px">
            <b>
              {{
                booking.bookingStatus.name === "Confirmed"
                  ? "Confirmada"
                  : booking.bookingStatus.name === "Cancelled"
                  ? "Cancelada"
                  : booking.bookingStatus.name
              }}
            </b>
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="float-right">
            <h3>
              <b style="color: green">Total de Reserva:</b>
              {{ booking.coinType }} {{ booking.total | number }}$
            </h3>
            <h3 style="margin-top: -10px" *ngIf="isRentCar">
              <b style="color: green">Total Rent a Car:</b>
              {{ booking.coinType }} 
              {{booking.bookingStatus.name !== "Cancelled" ? 
              (booking.rcTotal | number) : (booking.subTotal * 0.5 * 0.4 | number) }}$
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm">
            <div class="float-right">
              <h2 style="margin-top: 5px">
                {{ booking.coinType }} {{ booking.total | number }}$
              </h2>
            </div>
            <br /><br /><br />
            <div class="float-right">
              <button
                class="btn btn-primary btn-round btn-view-client"
                mat-button
                type="button"
                (click)="viewClientModal(booking.clientId)"
              >
                Ver cliente
              </button>
            </div>
          </div> -->
  </div>
  <!-- </div>
    </div> -->
</div>

<div class="row justify-content-center mt-4">
  <button
    class="btn btn-primary btn-round btn-close"
    mat-button
    type="button"
    (click)="closeDialog()"
  >
    Salir
  </button>
</div>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
