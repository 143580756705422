import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BoSubscriptionsService } from 'src/app/services/bo-subscriptions.service';
import { ScriptsService } from 'src/app/services/scripts.service';
import Swal from 'sweetalert2';
import { SubcriptionModel } from '../../models/bo/subscription.model';
declare var paypal;
const SCRIPT_PATH = "https://www.paypal.com/sdk/js?client-id=ATeA9yKpjfY0iosygdt18p44450XRf7lHqFTondRkPA164vcMWC22zB5eqi_ySikWFQ1VET1X5nTIX4p&vault=true&intent=subscription";

@Component({
  selector: 'app-bo-subscription',
  templateUrl: './bo-subscription.component.html',
  styleUrls: ['./bo-subscription.component.css']
})

export class BoSubscriptionComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  public submitted = true;
  public plan;
  public showPlans = false;

  constructor(
    private renderer: Renderer2,
    private scriptService: ScriptsService,
    private boSubsService: BoSubscriptionsService
  ) { }

  ngOnInit(): void {
    this.getPlan();

  }

  configuratePlans() {
    const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
    scriptElement.onload = () => {
      this.initializePaypalButton();
    }
    scriptElement.onerror = () => {
      console.log('Could not load the Paypal API Script!');
    }
  }

  rentCarPlanButton(planId: string, _id: string) {
    if (!planId) {
      return;
    }
    paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'white',
        layout: 'horizontal',
        tagline: 'false',
        label: 'subscribe'
      },
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          /* Creates the subscription */
          plan_id: planId
        });
      },
      onApprove: (data) => {
        this.postSubscription(_id, data.subscriptionID, data.orderID)
      }
    }).render('#paypal-button-container-basic');
  }

  // intermediatePlanButton(planId: string, _id: string) {
  //   if (!planId) {
  //     return;
  //   }
  //   paypal.Buttons({
  //     style: {
  //       shape: 'pill',
  //       color: 'silver',
  //       layout: 'horizontal',
  //       tagline: 'false',
  //       label: 'subscribe'
  //     },
  //     createSubscription: function (data, actions) {
  //       return actions.subscription.create({
  //         /* Creates the subscription */
  //         plan_id: planId
  //       });
  //     },
  //     onApprove: (data, actions) => {
  //       console.log(data);
  //       this.postSubscription(_id, data.subscriptionID, data.orderID)
  //     }
  //   }).render('#paypal-button-container-intermediate');
  // }

  // advancedPlanButton(planId: string, _id: string) {
  //   if (!planId) {
  //     return;
  //   }
  //   paypal.Buttons({
  //     style: {
  //       shape: 'pill',
  //       color: 'gold',
  //       layout: 'horizontal',
  //       tagline: 'false',
  //       label: 'subscribe'
  //     },
  //     createSubscription: function (data, actions) {
  //       return actions.subscription.create({
  //         /* Creates the subscription */
  //         plan_id: planId
  //       });
  //     },
  //     onApprove: (data) => {
  //       console.log(data);
  //       this.postSubscription(_id, data.subscriptionID, data.orderID);
  //     }
  //   }).render('#paypal-button-container-advanced'); // Renders the PayPal button
  // }

  initializePaypalButton() {
    this.submitted = true;
    this.boSubsService.getSubsPlans().subscribe((data: any) => {
      this.submitted = false;
      this.rentCarPlanButton(data.object[0].paypalPlanId, data.object[0]._id);
      // this.intermediatePlanButton(data.object[1].paypalPlanId, data.object[1]._id);
      // this.advancedPlanButton(data.object[2].paypalPlanId, data.object[2]._id);
    },error=>{
      this.submitted = false;
      console.log(error);
      
    });

  }

  postSubscription(planId: string, subscriptionId: string, orderId: string) {
    this.submitted = true;
    this.boSubsService.postSubscription(new SubcriptionModel(subscriptionId, orderId, planId)).subscribe((data: any) => {
      this.submitted = false;
      this.showPlans = false;
      Swal.fire({
        icon: 'success',
        text: "Su subscripción ha sido creada correctamente!",
      });
      this.getPlan();

    }, err => {
      this.submitted = false;
      console.log(err);

    })
  }

  public getPlan() {
    this.boSubsService.getPlanByCompany().subscribe((data: any) => {
      if (data.statusCode === 404) {
        this.configuratePlans();
        this.showPlans = true;
      }
      this.plan = data.object;
      this.submitted = false;

    }, err => {
      this.submitted = false;
      if (err.error?.statusCode === 404) {
        this.configuratePlans();
        this.showPlans = true;
      } else {
        Swal.fire({
          icon: 'error',
          title: err.error.title,
          text: err.error.message,
        });
      }
    })
  }

  public cancelPlan(id: string) {
    Swal.fire({
      icon: 'warning',
      title: '¿Seguro que desea cancelar su subscripción?',
      text:
        'Si cancela su subscripción, perderá los beneficios de Carros Facil',
      showDenyButton: true,
      confirmButtonText: 'Mantener subscripción',
      denyButtonText: 'Si, Cancelar',
    }).then((result) => {
      if (result.isDenied) {
        this.submitted = true;
        this.boSubsService.cancelSubscription(id).subscribe((data: any) => {
          this.submitted = false;
          Swal.fire({
            icon: 'success',
            text: "Su subscripción ha sido cancelada",
          });
          this.showPlans = true;
          this.plan = null;
          this.configuratePlans();
        }, error => {
          this.submitted = false;
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });
        });
      }
    })
  }
  
}
