import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BookingsService } from 'src/app/services/bookings.service';
import { BoBookingClientComponent } from '../../modals/bo-booking-client/bo-booking-client.component';

@Component({
  selector: 'app-bo-bookings',
  templateUrl: './bo-bookings.component.html',
  styleUrls: ['./bo-bookings.component.css']
})
export class BoBookingsComponent implements OnInit {
  public selectStatus = new FormControl('', [Validators.required]);
  dateControl = new FormControl('', [Validators.required]);
  public startDate = new Date();
  public endDate = new Date();
  public filterDate = false;
  public filter;
  public bookings: any[];
  public bookingsCopy: any[];
  public bookingStatus: any[];
  public submitted = false;
  public selectedStatus = "0";
  public p: number = 1;
  constructor(
    private bookingService: BookingsService,
    private helperModal: MatDialog,

  ) { }

  ngOnInit(): void {
    this.getBookings();
    this.getStatus();
  }

  public search() {
    this.bookingsCopy = this.bookings.filter((f) =>
      f.vehicle.name.toLowerCase().includes(this.filter.toLowerCase())
    );
  }


  public setFilters() {
    let bookResults = [];
    if (this.selectedStatus === "0")
      bookResults = this.bookings;
    else {
      bookResults = this.bookings.filter(f => f.bookingStatus.name === this.selectedStatus);
    }
    if (this.filterDate) {
      if (this.startDate <= this.endDate) {
        bookResults = bookResults.filter(
          d => (new Date(d.fromDate) >= this.startDate && new Date(d.fromDate) <= this.endDate) ||
            (new Date(d.toDate) >= this.startDate && new Date(d.toDate) <= this.endDate)
        )
      }
    }
    this.bookingsCopy = bookResults;
  }

  public getStatus() {
    this.bookingStatus = [
      { name: "Todas", status: "0" },
      { name: "Confirmada", status: "Confirmed" },
      { name: "Completada", status: "Completed" },
      { name: "Cancelada", status: "Cancelled" },
    ]
  }

  getBookings() {
    this.submitted = true;
    this.bookingService.getByRC().subscribe((data: any) => {
      this.bookings = data.object;
      const date = new Date();
      for (let book of this.bookings) {
        let daysFromNow = Math.round(
          (date.getTime() - new Date(book.fromDate).getTime()) /
          (1000 * 60 * 60 * 24));
        if (daysFromNow < 0) { daysFromNow = -1 * daysFromNow }
        book = Object.assign(book, { dayFromNow: daysFromNow });

      }
      this.bookingsCopy =
        this.bookings.sort((a, b) =>
          b.bookingStatus.name.localeCompare(a.bookingStatus.name) || a.dayFromNow - b.dayFromNow
        );

      this.submitted = false;
    }, error => {
      this.submitted = false;
      console.log(error);
    });
  }

  public viewClientModal(id: string) {
    this.helperModal.open(BoBookingClientComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      minWidth: '350px',
      data: { clientId: id },
    });
  }

  public checkedChange() {
    this.setFilters();
  }
}
