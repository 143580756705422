<div class="row justify-content-center">
  <div class="card">
    <div class="card-body">
      <h2 class="card-title" style="color: #0f387a">Información Personal</h2>
      <h4 class="card-subtitle mb-2 text-muted" style="color: #5c8fc9">
        Actualiza tu información personal y de contacto
      </h4>
      <form
        class="personalForm"
        id="login-form"
        name="personalForm"
        #personalForm="ngForm"
        (ngSubmit)="personalForm.valid && putPersonalInfo()"
      >
        <div class="col mt-3">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Nombres</mat-label>
            <input
              matInput
              [required]="ClientCopy.firstName?.length > 0"
              [(ngModel)]="Client.firstName"
              name="name"
            />
            <mat-icon matSuffix>contact_page</mat-icon>
            <!-- <mat-error *ngIf="email.errors?.required">
                  Este campo es obligatorio
                </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Apellidos</mat-label>
            <input
              matInput
              [required]="ClientCopy.lastName?.length > 0"
              [(ngModel)]="Client.lastName"
              name="lastName"
            />
            <mat-icon matSuffix>contact_page</mat-icon>
            <!-- <mat-error *ngIf="email.errors?.required">
                  Este campo es obligatorio
                </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Correo</mat-label>
            <input matInput [(ngModel)]="email" name="email" disabled />
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Teléfono</mat-label>
            <input matInput required [(ngModel)]="Client.phone" name="phone" />
            <mat-icon matSuffix>phone_iphone</mat-icon>
            <!-- <mat-error *ngIf="email.errors?.required">
                  Este campo es obligatorio
                </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Dirección</mat-label>
            <input
              matInput
              required
              [(ngModel)]="Client.address"
              name="address"
              #address="ngModel"
            />
            <mat-icon matSuffix>home</mat-icon>
            <mat-error *ngIf="address.errors?.required">
                  Este campo es obligatorio
                </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-label>Fecha de nacimiento</mat-label>
            <input
              matInput
              (click)="picker.open()"
              readonly
              [matDatepicker]="picker"
              [(ngModel)]="Client.birthday"
              [max]="maxDate"
              name="birthday"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
            </mat-datepicker-toggle>
            <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width">
            <mat-select
              placeholder="Género"
              required
              name="gender"
              [(ngModel)]="Client.gender"
            >
              <mat-option *ngFor="let g of Genders" [value]="g?._id">
                {{ g?.name }}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="selectFuel.errors?.required">
                  Este campo es obligatorio
                </mat-error> -->
          </mat-form-field>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-sm">
              <mat-form-field class="example-full-width">
                <mat-select
                  placeholder="Tipo de documento"
                  required
                  [(ngModel)]="Client.documentType"
                  name="documentType"
                >
                  <mat-option *ngFor="let d of DocumentTypes" [value]="d._id">
                    {{ d.name }}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="selectFuel.errors?.required">
                          Este campo es obligatorio
                        </mat-error> -->
              </mat-form-field>
            </div>
            <div class="col-sm">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Documento</mat-label>
                <input
                  matInput
                  required
                  [(ngModel)]="Client.document"
                  name="document"
                  #document="ngModel"
                />
                <mat-icon matSuffix>description</mat-icon>
                <mat-error *ngIf="document.errors?.required">
                          Este campo es obligatorio
                        </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 text-center">
              <button
                class="btn btn-primary btn-round mt-4"
                style="
                  background-color: lightgray;
                  color: black;
                  font-weight: 600;
                "
                mat-button
                (click)="cancel()"
                name="cancelbutton"
                type="button"
              >
                Cancelar
              </button>
              <button
                class="btn btn-primary btn-round mt-4"
                style="background-color: #0f387a; margin-left: 37px"
                mat-button
                type="submit"
                name="savebutton"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
