import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-bo-vehicles-images',
  templateUrl: './bo-vehicles-images.component.html',
  styleUrls: ['./bo-vehicles-images.component.css']
})
export class BoVehiclesImagesComponent implements OnInit {
  @Input() Vehicles: any;
  public vehicleImages;
  @Output() editVehicleEvent = new EventEmitter<string>();
  @Output() changeStatusEvent = new EventEmitter<any>();
  @Output() deleteVehicleEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }


  public editVehicle(id) {
    this.editVehicleEvent.emit(id);
  }

  public changeStatus(id: string, name: string, status) {
    this.changeStatusEvent.emit({ id, name, status });
  }

  public deleteVehicle(id) {
    this.deleteVehicleEvent.emit(id);
  }

}
