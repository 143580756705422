import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private http: HttpClient, private globals: Globals) {}

  public GetCountries() {
    const url_api = this.globals.urlApi + `countries`;
    return this.http.get(url_api);
  }
}
