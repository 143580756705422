import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-fo-layout',
  templateUrl: './fo-layout.component.html',
  styleUrls: ['./fo-layout.component.css']
})
export class FoLayoutComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
