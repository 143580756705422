import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BookingsService } from 'src/app/services/bookings.service';
import Swal from 'sweetalert2';
import { FoCompanyDetailsComponent } from '../../modals/fo-company-details/fo-company-details.component';

@Component({
  selector: 'app-fo-client-bookings',
  templateUrl: './fo-client-bookings.component.html',
  styleUrls: ['./fo-client-bookings.component.css']
})
export class FoClientBookingsComponent implements OnInit {

  public bookings: any;
  public submitted = false;
  public vehicle: any;
  constructor(
    private bookingService: BookingsService,
    private helperModal: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.submitted = true;
    this.getBookings();
  }

  getBookings() {
    this.bookingService.getByClient().subscribe((data: any) => {
      this.bookings = data.object;
      this.submitted = false;
    }, error => {
      this.submitted = false;
      console.log(error);
    });
  }

  cancelBooking(id: string) {
    Swal.fire({
      icon: 'warning',
      title: '¿Seguro que desea cancelar la reserva?',
      text:
        'Si cancela la reserva, solo será reembolsado el 50% de su pago.',
      showDenyButton: true,
      confirmButtonText: 'Mantener reserva',
      denyButtonText: 'Si, Cancelar',
    }).then((result) => {

      if (result.isDenied) {
        this.submitted = true;
        this.bookingService.cancelBooking(id).subscribe((data: any) => {
          this.submitted = false;
          Swal.fire('Reserva cancelada', 'Su pago será  reembolsado de 1 a 5 días laborables', 'success');
          this.getBookings();
        }, error => {
          this.submitted = false;
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });
        });

      }
    })
  }


  deleteBooking(id: string) {
    Swal.fire({
      icon: 'warning',
      title: '¿Está seguro que desea eliminar este registro?',
      confirmButtonText: 'Si, eliminar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitted = true;
        this.bookingService.deleteBooking(id).subscribe((data: any) => {
          Swal.fire('Se ha eliminado este registro', '', 'success');
          this.getBookings();
          this.submitted = false;
        }, error => {
          this.submitted = false;
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });
        })
      }
    })
  }

  goBack() {
    // window.history.go(-1); return false;
    this.router.navigate(['fo/client/account']);
  }

  public viewRentcarModal(companyId: string) {
    this.helperModal.open(FoCompanyDetailsComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      minWidth: '350px',
      data: { companyId: companyId },
    });
  }

}
