<div class="col-md-10 col-sm-12 ml-auto mr-auto">
  <div class="containerBody container-fluid">
    <h2 class="form-title hvr-icon-bounce mb-5">
      <a style="cursor: pointer" (click)="goBack()"
        ><mat-icon class="hvr-icon" style="margin-bottom: 6px"
          >keyboard_arrow_left</mat-icon
        ></a
      >
      Tus Pagos
    </h2>

    <div class="row justify-content-center mt-2 mb-3">
      <h3 style="color:  #0f387a; text-align: center">
        <b>Lista de tus pagos realizados</b>
      </h3>
    </div>
    <div class="row justify-content-center mt-1">
      <div>
        <mat-form-field class="range-width">
          <mat-label>Elige un rango de fecha</mat-label>
          <mat-date-range-input
            [rangePicker]="rangePicker"
            required
            [disabled]="!filterDate"
          >
            <input
              matStartDate
              placeholder="Fecha desde"
              [(ngModel)]="startDate"
              [formControl]="dateControl"
              (dateChange)="setFilters()"
            />
            <input
              matEndDate
              placeholder="Fecha hasta"
              [(ngModel)]="endDate"
              (dateChange)="setFilters()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="rangePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker> </mat-date-range-picker>
          <mat-error *ngIf="dateControl.invalid"
            >Este campo es obligatorio</mat-error
          >
        </mat-form-field>
      </div>
      <mat-checkbox
        data-toggle="tooltip"
        data-placement="top"
        class="example-margin"
        title="Filtro por fecha"
        [(ngModel)]="filterDate"
        (change)="checkedChange()"
      >
      </mat-checkbox>
    </div>

    <div class="row justify-content-center mt-4">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Fecha</th>
              <th scope="col">Detalle</th>
              <th scope="col">Total</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of paymentsCopy  | paginate: { id: '1', itemsPerPage: 12, currentPage: p };">
              <td>{{ p.createdDate | date: "d MMM y hh:mm:ss" }}</td>
              <td>{{ p.paymentInfo }}</td>
              <td>{{ p.amount | number: "1.2-2" }} {{ p.coinType }}</td>
              <td>
                <a
                  style="color:  #0f387a;; margin-right: 5px"
                  class="selected"
                  class="btnDetailsIcon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ver reserva"
                  (click)="viewBookingDetail(p.bookingId)"
                >
                  Ver reserva
                  <i class="fa fa-eye"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="float-right mt-4">
        <nav class="">
          <pagination-controls
            id="1"
            (pageChange)="p = $event"
            previousLabel="Anterior"
            nextLabel="Siguiente"
          >
          </pagination-controls>
        </nav>
      </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
