<h2 class="titleMoradito">Puntos de entrega</h2>
<hr align="left" class="highlighter" />
<div class="row justify-content-center mt-2 mb-3">
  <h3 class="accent-color text-center">
    <b>Selecciona las ciudades y los lugares donde entregaras tus vehiculos</b>
  </h3>
</div>
<div class="row justify-content-center">
  <div class="col-8 col-sm-10">
    <div class="table-responsive-sm">
      <!-- Main table start -->
      <table class="table table-sm table-borderless">
        <!-- Start of main table header -->
        <thead>
          <tr>
            <th class="col-6">Ciudad</th>
            <th class="col-6" colspan="2">Acción</th>
          </tr>
        </thead>
        <!-- End of main table header -->

        <!-- Start of main table body -->
        <tbody>
          <ng-container *ngFor="
              let cp of citiesPickups
                | paginate : { id: '1', itemsPerPage: 11, currentPage: p };
              let i = index
            ">
            <!-- Parent row -->
            <tr>
              <td class="align-middle">{{ cp.name }}</td>
              <td class="align-middle">
                <mat-checkbox (change)="markAllCityCheckbox($event.checked, cp.city)" [(ngModel)]="cp.all">
                  <span class="font-black">Marcar todos
                    <span class="badge badge-secondary">{{ countSelectedSites(cp) + '/' +
                      cp.sites.length
                      }}</span></span>
                </mat-checkbox>
              </td>
              <td class="align-middle">
                <a class="show-sites" style="color: black; margin-left: 6px; cursor: pointer" data-toggle="collapse"
                  [attr.data-target]="'#collapseItem' + i" aria-expanded="true"
                  (click)="cp.isCollapsed = !cp.isCollapsed">
                  <i class="fa fa-eye accent-color"></i>
                  <span>Mostrar lugares</span>
                </a>
              </td>
            </tr>

            <!-- Sites table | Collapsible row-->
            <tr *ngIf="cp.isCollapsed">
              <td></td>
              <td colspan="2" class="sites-table">
                <div class="collapse show">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th>Lugar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let site of cp.sites">
                        <mat-checkbox class="pl-5 m-0" [(ngModel)]="site.marked" [checked]="site.marked">
                          <td class="font-black">{{ site.name }}</td>
                        </mat-checkbox>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <!-- Sites table end -->
          </ng-container>
        </tbody>
        <!-- End of main table body -->
      </table>
      <!-- Main table end -->

      <div class="float-right mt-4">
        <nav class="">
          <pagination-controls id="1" (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
          </pagination-controls>
        </nav>
      </div>
    </div>
  </div>
  <div class="col-12 text-center">
    <button class="btn btn-primary btn-round mt-4" style="background-color: lightgray; color: black; font-weight: 600"
      mat-button (click)="cancel()" name="cancelbutton">
      Cancelar
    </button>
    <button class="btn btn-primary btn-round mt-4"
      style="background-color: #0f387a; font-weight: 600; margin-left: 20px" mat-button type="submit"
      (click)="saveSites()">
      Guardar
    </button>
  </div>
</div>
<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>