import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { UsersBOService } from 'src/app/services/users-bo.service';

@Component({
  selector: 'app-bo-layout',
  templateUrl: './bo-layout.component.html',
  styleUrls: ['./bo-layout.component.css'],
})
export class BoLayoutComponent implements OnInit {
  constructor(
    public userBoServices: UsersBOService,
    private router: Router,
    private clientService: ClientsService
  ) { }

  ngOnInit() {
    if (this.clientService.isUserFOLoggedIn) {
      this.router.navigate(['fo/client/account']);
      return;
    }
    let Token = localStorage.getItem('TokenBO');
    Token
      ? (this.userBoServices.isUserBOLoggedIn = true)
      : (this.userBoServices.isUserBOLoggedIn = false);
    var ruta = window.location.href;
    if (!this.userBoServices.isUserBOLoggedIn) {
      if (!ruta.includes('/bo/reset-password/') && !ruta.includes('/bo/signup')) {
        this.router.navigate(['bo/login']);
      }
    } else {
      if (ruta.includes('/bo/login') || ruta.includes('/bo/signup') || ruta.includes('/bo/reset-password/')) {
        this.router.navigate(['bo/home']);
      }
    }
  }
}
