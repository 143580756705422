<div class="row justify-content-center grid-style">
  <form
    name="emailForm"
    #emailForm="ngForm"
    (ngSubmit)="emailForm.valid && sendResetPassword()"
  >
    <div *ngIf="!emailSubmitted">
      <h1
        class="card-title text-center"
        style="color: #0f387a; margin-top: 15px; font-weight: 500"
      >
        ¿Olvidaste tu contraseña?
      </h1>
      <p class="card-subtitle" style="font-size: 14px; margin-top: 20px;">
        ¡No hay problema! Te enviaremos un enlace a tu correo para
        restablecerla.
      </p>
      <div class="row justify-content-center mt-4">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Correo</mat-label>
            <input
              matInput
              [(ngModel)]="emailReset"
              required
              type="email"
              name="email"
              #email="ngModel"
              email="true"
            />
            <mat-icon matSuffix>email</mat-icon>
            <mat-error *ngIf="email.errors?.required">
              Este campo es obligatorio
            </mat-error>
            <mat-error *ngIf="email.errors?.email">
              Debe escribir un correo válido
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="row justify-content-center mt-4">
            <button
              class="btn btn-primary btn-round"
              style="background-color: #134699; font-weight: 600"
              mat-button
              type="submit"
            >
              Enviar link
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="emailSubmitted">
      <h1
        class="card-title text-center"
        style="color: #0f387a; margin-top: 15px; font-weight: 500"
      >
        Revisa tu correo
      </h1>
      <p
        class="card-subtitle"
        style="
          font-size: 14px;
          margin-top: 20px;
          text-align: justify;
          line-height: 23px;
        "
      >
        Acabamos de enviar instrucciones por correo electrónico y un enlace para
        restablecer la contraseña a <b>{{ emailReset }}</b
        >. Puede tardar unos minutos en llegar.
      </p>
      <div class="row row justify-content-center mt-5">
        <button
          class="btn btn-primary btn-round"
          style="background-color: white; color: #134699; font-weight: 600"
          mat-button
          type="button"
          (click)="close()"
        >
          Iniciar sesión
        </button>
      </div>
    </div>
  </form>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>