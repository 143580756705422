<div *ngIf="isError === false">
  <app-success-display
    [SuccessMessage]="'Cuenta verificada'"
    [TitleMessage]="'En hora buena!'"
  ></app-success-display>
</div>

<div *ngIf="isError === true">
  <app-fo-not-found [ErrorMessage]="error"></app-fo-not-found>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
