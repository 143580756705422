import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompaniesPickupService } from 'src/app/services/companies-pickup.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-pickup-config',
  templateUrl: './bo-pickup-config.component.html',
  styleUrls: ['./bo-pickup-config.component.css']
})
export class BoPickupConfigComponent implements OnInit {
  public citiesPickups = [];
  public submitted = false;
  public p: number = 1;
  constructor(
    private service: CompaniesPickupService,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.submitted = true;
    this.service.GetPickups().subscribe((data: any) => {
      this.citiesPickups = data.object;
      this.submitted = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: "Error",
        text: error.error.message,
      });
      this.submitted = false;

    });
  }

  public markAllCityCheckbox(completed: boolean, city: string) {
    let res = this.citiesPickups.find(c => c.city === city);
    for (let site of res.sites) {
      site.marked = completed;
    }
    res.all = completed;
  }

public countSelectedSites(city: any): number {
  return city.sites.filter(site => site.marked).length;
}

  public saveSites() {
    this.submitted = true;
    this.service.savePickups(this.citiesPickups).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        title: "Guardado",
        text: "Sus cambios han sido guardados correctamente",
      });
    }, (error) => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })

  }

  cancel() {
    this.route.navigate(['bo/account']);
  }

}
