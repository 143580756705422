import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BoHomeComponent } from './bo/bo-home/bo-home.component';
import { BoRoutes } from './bo.routing';
import { BoLoginComponent } from './bo/bo-login/bo-login.component';
import { BoSingupComponent } from './bo/bo-singup/bo-singup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { BoAccountComponent } from './bo/bo-account/bo-account.component';
import { BOVehiclesComponent } from './bo/bo-rentcars/bo-vehicles/bo-vehicles.component';
import { BoVehiclesAddComponent } from './bo/modals/bo-vehicles-add/bo-vehicles-add.component';
import { BoVehiclesImagesComponent } from './bo/bo-rentcars/bo-vehicles-images/bo-vehicles-images.component';
import { BoPickupConfigComponent } from './bo/bo-rentcars/bo-pickup-config/bo-pickup-config.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgxPaginationModule } from "ngx-pagination";
import { BoAccountInformationComponent } from './bo/bo-account-information/bo-account-information.component';
import { BoSubscriptionComponent } from './bo/bo-subscription/bo-subscription.component';
import { DndDirective } from './helper/directives/dnd.directive';
import { FileDropComponent } from './bo/components/file-drop/file-drop.component';
import { ProgressComponent } from './bo/components/progress/progress.component';
import { BoVehiclesStatusComponent } from './bo/modals/bo-vehicles-status/bo-vehicles-status.component';
import { BoBookingsComponent } from './bo/bo-rentcars/bo-bookings/bo-bookings.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BoBookingClientComponent } from './bo/modals/bo-booking-client/bo-booking-client.component';
import { BoChangePasswordComponent } from './bo/modals/bo-change-password/bo-change-password.component';
import { BoForgotPasswordComponent } from './bo/modals/bo-forgot-password/bo-forgot-password.component';
import { BoResetPasswordComponent } from './bo/bo-reset-password/bo-reset-password.component';
import { TemplatesModule } from './templates/templates.module';
import { BoPaymentsComponent } from './bo/bo-rentcars/bo-payments/bo-payments.component';
import { NgxMaskModule } from 'ngx-mask';
import { NumbersOnlyDirective } from './helper/directives/numbers-only';

@NgModule({
    declarations: [
        BoHomeComponent,
        BoLoginComponent,
        BoSingupComponent,
        BoAccountComponent,
        BOVehiclesComponent,
        BoVehiclesAddComponent,
        BoVehiclesImagesComponent,
        BoPickupConfigComponent,
        BoAccountInformationComponent,
        BoSubscriptionComponent,
        DndDirective,
        ProgressComponent,
        FileDropComponent,
        BoVehiclesStatusComponent,
        BoBookingsComponent,
        BoBookingClientComponent,
        BoChangePasswordComponent,
        BoForgotPasswordComponent,
        BoResetPasswordComponent,
        BoPaymentsComponent,
        NumbersOnlyDirective
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(BoRoutes),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: 'blue',
            secondaryColour: 'rgba(0, 0, 0, 0.87)',
            tertiaryColour: 'blue',
        }),
        NgxMaskModule.forRoot(),
        FormsModule,
        TemplatesModule,
        NgxPaginationModule,
        [
            ReactiveFormsModule,
            MatSelectModule,
            MatCheckboxModule,
            MatFormFieldModule,
            MatIconModule,
            MatInputModule,
            MatToolbarModule,
            MatDialogModule,
            MatDatepickerModule,
            MatNativeDateModule,
        ],
    ],
    providers: [{
            provide: MatDialogRef,
            useValue: {}
        },],
    exports: [RouterModule]
})
export class BoModule { }
