export class SubcriptionModel {
    public subscriptionId: string;
    public orderId: string;
    public planId: string;

    constructor(subs: string, orderId: string, planId: string) {
        this.subscriptionId = subs;
        this.orderId = orderId;
        this.planId = planId;
    }
}