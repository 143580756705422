<h2 class="titleMoradito">Reservas</h2>
<hr align="left" class="highlighter" />
<div class="row justify-content-center mt-2 mb-3">
  <h3 style="color: #039687; text-align: center">
    <b>Lista de las reservas de tus vehículos</b>
  </h3>
</div>

<div class="row">
  <div class="col-sm row justify-content-center">
    <mat-form-field class="example-full-width">
      <mat-icon matSuffix>search</mat-icon>
      <input
        matInput
        (input)="search()"
        placeholder="Buscar"
        name="Buscar"
        maxlength="20"
        [(ngModel)]="filter"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="col-sm row justify-content-center">
    <mat-form-field class="example-full-width">
      <mat-select
        placeholder="Estado"
        name="Estado"
        [formControl]="selectStatus"
        [(ngModel)]="selectedStatus"
        (selectionChange)="setFilters()"
        required
      >
        <mat-option *ngFor="let s of bookingStatus" [value]="s?.status">{{
          s?.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm row justify-content-center">
    <div>
      <mat-form-field class="range-width">
        <mat-label>Elige un rango de fecha</mat-label>
        <mat-date-range-input
          [rangePicker]="rangePicker"
          required
          [disabled]="!filterDate"
        >
          <input
            matStartDate
            placeholder="Fecha desde"
            [(ngModel)]="startDate"
            [formControl]="dateControl"
            (dateChange)="setFilters()"
          />
          <input matEndDate placeholder="Fecha hasta" [(ngModel)]="endDate"  (dateChange)="setFilters()" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="rangePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker> </mat-date-range-picker>
        <mat-error *ngIf="dateControl.invalid"
          >Este campo es obligatorio</mat-error
        >
      </mat-form-field>
    </div>
    <mat-checkbox
      data-toggle="tooltip"
      data-placement="top"
      title="Filtro fecha"
      class="example-margin"
      [(ngModel)]="filterDate"
      (change)="checkedChange()" 
    >
    </mat-checkbox>
  </div>
</div>

<div class="col-md-10 ml-auto mr-auto mt-3">
  <div class="containerBody container-fluid">
    <div *ngFor="let booking of bookingsCopy | paginate: { id: '1', itemsPerPage: 11, currentPage: p };" class="row">
      <div class="col-12">
        <div style="padding: 8px">
          <h2>{{ booking.vehicle.name | titlecase }}</h2>
          <p style="margin-top: -12px">
            {{ booking.fromDate | date: "d MMM y" }} –
            {{ booking.toDate | date: "d MMM y" }}
          </p>
        </div>

        <div class="card" style="margin-top: -4px; min-width: 278px">
          <div class="card-body">
            <div class="row">
              <div class="col-md-auto text-center" style="margin-bottom: 20px">
                <img
                  [src]="booking.vehicle.image.secureUrlThumbnail"
                  width="160"
                  height="120"
                  alt=""
                />
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <p><b>Retiro: </b>{{ booking.pickupSite.name }}</p>
                    <p><b>Retorno: </b>{{ booking.deliverSite.name }}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <p class="text-muted" style="margin-top: -12px">
                      {{ booking.fromDate | date: "d MMM y" }} –
                      {{ booking.toDate | date: "d MMM y" }}
                    </p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <p style="margin-top: -12px">
                      {{
                        booking.bookingStatus.name === "Confirmed"
                          ? "Confirmada"
                          : booking.bookingStatus.name === "Cancelled"
                          ? "Cancelada"
                          : booking.bookingStatus.name
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div class="float-right">
                  <h2 style="margin-top: 5px">
                    {{ booking.coinType }} {{ booking.total | number }}$
                  </h2>
                </div>
                <br /><br /><br />
                <div class="float-right">
                  <button
                    class="btn btn-primary btn-round btn-view-client"
                    mat-button
                    type="button"
                    (click)="viewClientModal(booking.clientId)"
                  >
                    Ver cliente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="float-right mt-4">
        <nav class="">
          <pagination-controls
            id="1"
            (pageChange)="p = $event"
            previousLabel="Anterior"
            nextLabel="Siguiente"
          >
          </pagination-controls>
        </nav>
      </div>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
