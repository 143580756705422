<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    accept="image/png, image/jpg, image/jpeg, image/heic"
    (change)="fileBrowseHandler($event.target.files)"
  />
  <h3>
    Para seleccionar las imagenes de tu vehiculo haz click aqui o arrastra.
  </h3>
  <img
    src="../../../../assets/img/bo/dnd/ic-upload-file.svg"
    width="38px"
    alt=""
  />
</div>
<div class="files-list">
  <div class="row justify-content-center" *ngFor="let file of files; let i = index">
    <div class="col-12 justify-content-center">
      <div class="single-file">
        <img
        [src]="file.img"
        width="50px" height="45px"
        alt="file"
        style="margin-right: 5px"
      />
        <div class="info">
          <h4 class="name">
            {{ file?.doc.name }}
          </h4>
          <p class="size">
            {{ formatBytes(file?.doc.size) }}
          </p>
        </div>
        <img
          src="../../../../assets/img/icons8-delete.png"
          class="delete"
          width="20px"
          alt="file"
          (click)="deleteFile(i,file?.publicId ?? file?.doc.name)"
        />
      </div>
    </div>
  </div>

</div>
