import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fo-not-found',
  templateUrl: './fo-not-found.component.html',
  styleUrls: ['./fo-not-found.component.css']
})
export class FoNotFoundComponent implements OnInit {
  @Input() ErrorMessage: string;
  constructor() { }

  ngOnInit(): void {
  }

}
