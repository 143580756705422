import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoNotFoundComponent } from './fo-not-found/fo-not-found.component';
import { SuccessDisplayComponent } from './success-display/success-display.component';
import { BookingDetailComponent } from './modals/booking-detail/booking-detail.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { SwiperModule } from 'swiper/angular';



@NgModule({
    declarations: [
        FoNotFoundComponent,
        SuccessDisplayComponent,
        BookingDetailComponent,
    ],
    imports: [
        CommonModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: 'blue',
            secondaryColour: 'rgba(0, 0, 0, 0.87)',
            tertiaryColour: 'blue',
        }),
        SwiperModule
    ],
    exports: [FoNotFoundComponent,
        SuccessDisplayComponent]
})
export class TemplatesModule { }
