import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-forgot-password',
  templateUrl: './bo-forgot-password.component.html',
  styleUrls: ['./bo-forgot-password.component.css']
})
export class BoForgotPasswordComponent implements OnInit {
  public emailReset = "";
  public emailSubmitted = false;
  public submitted = false;
  constructor(
    private service: UsersBOService,
    public dialogRef: MatDialogRef<BoForgotPasswordComponent>,

  ) { }

  ngOnInit(): void {
  }

  public close() {
    this.dialogRef.close();
  }

  public sendResetPassword() {
    if (!this.emailReset) {
      Swal.fire({
        icon: 'info',
        title: "Por favor escriba su correo para continuar"
      });
      return;
    }
    this.submitted = true;
    this.service.requestResetPassword(this.emailReset).subscribe((data) => {
      this.submitted = false;
      this.emailSubmitted = true;
      Swal.fire({
        icon: 'success',
        title: "Correo de recuperación envíado.",
        text: "Si este correo coincide con una cuenta activa, envíaremos un link donde podrá restablecer su contraseña."
      });
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    })
  }
}
