import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { FoLoginComponent } from '../../modals/fo-login/fo-login.component';

@Component({
  selector: 'app-fo-client-layout',
  templateUrl: './fo-client-layout.component.html',
  styleUrls: ['./fo-client-layout.component.css']
})
export class FoClientLayoutComponent implements OnInit {

  constructor(
    public clientService: ClientsService,
    private router: Router,
    public loginModal: MatDialog,

  ) { }

  ngOnInit(): void {
    this.validateLoggin();
  }

  public validateLoggin() {
    let isLoggedIn = this.clientService.setLoginValue();
    const ruta = window.location.href;
    if ((ruta.includes('/fo/client/') || ruta.includes('/fo/signup')) && !isLoggedIn) {
      this.openLoginModal();
    }
    if (isLoggedIn) {
      this.clientService.getLoggedInfo();
    }
  }

  public openLoginModal() {
    const dialogRef = this.loginModal.open(FoLoginComponent, {
      height: 'auto',
      width: 'auto',
      minWidth: '400px',
      maxHeight: '650px',
      panelClass: 'modal-transparent',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.message == "Ok") {
          this.loginModal.closeAll();
        } else {
          this.validateLoggin()
        }
      } else
        this.validateLoggin();
    });;
  }

}
