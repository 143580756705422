<h2 class="titleMoradito">Pagos</h2>
<hr align="left" class="highlighter" />
<div class="row justify-content-center mt-2 mb-3">
  <h3 style="color: #039687; text-align: center">
    <b>Lista de tus pagos recibidos</b>
  </h3>
</div>
<div class="row justify-content-center mt-5">
  <div>
    <mat-form-field class="range-width">
      <mat-label>Elige un rango de fecha</mat-label>
      <mat-date-range-input
        [rangePicker]="rangePicker"
        required
        [disabled]="!filterDate"
      >
        <input
          matStartDate
          placeholder="Fecha desde"
          [(ngModel)]="startDate"
          [formControl]="dateControl"
          (dateChange)="setFilters()"
        />
        <input
          matEndDate
          placeholder="Fecha hasta"
          [(ngModel)]="endDate"
          (dateChange)="setFilters()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matSuffix
        [for]="rangePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #rangePicker> </mat-date-range-picker>
      <mat-error *ngIf="dateControl.invalid"
        >Este campo es obligatorio</mat-error
      >
    </mat-form-field>
  </div>
  <mat-checkbox
    data-toggle="tooltip"
    data-placement="top"
    class="example-margin"
    title="Filtro por fecha"
    [(ngModel)]="filterDate"
    (change)="checkedChange()"
  >
  </mat-checkbox>
</div>

<div class="row justify-content-center mt-4">
  <div class="col-8">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Concepto</th>
          <th scope="col">Total</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of paymentsCopy">
          <td>{{ p.createdDate | date: "d MMM y hh:mm:ss" }}</td>
          <td>{{ p.description }}</td>
          <td>{{ p.amount | number: "1.2-2" }} {{ p.coinType }}</td>
          <td>
            <a
              style="color: #04665c; margin-right: 5px"
              class="selected"
              class="btnDetailsIcon"
              data-toggle="tooltip"
              data-placement="top"
              title="Ver reserva"
              (click)="viewBookingDetail(p.bookingId)"
            >
              Ver reserva
              <i class="fa fa-eye"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row justify-content-center mt-5">
  <div class="col-4 text-center">
    <h6 class="total-title"><strong>Total Pagado:</strong></h6>
    <h4 class="total-detail">
      <strong>${{ paymentsTotal | number: "1.2-2" }}</strong>
      <b style="margin-left: 3px">USD</b>
    </h4>
  </div>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
