import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingsService } from 'src/app/services/bookings.service';
import { VehiclesStatusService } from 'src/app/services/vehicles-status.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-vehicles-status',
  templateUrl: './bo-vehicles-status.component.html',
  styleUrls: ['./bo-vehicles-status.component.css']
})
export class BoVehiclesStatusComponent implements OnInit {
  public selectStatus = new FormControl('', [Validators.required]);
  public submitted = true;
  public status = [];
  public selectedStatus: any;
  public carName: string;
  public isAvailable = true;
  public hasBookings = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private vehicleStatus: VehiclesStatusService,
    private bookingService: BookingsService,
    public dialogRef: MatDialogRef<BoVehiclesStatusComponent>,

  ) { }

  ngOnInit(): void {
    this.selectedStatus = this.data.status;
    this.carName = this.data.name;
    this.vehicleStatus.getAll().subscribe((data: any) => {
      this.status = data.object;
      this.submitted = false;
      this.onChangingStatus(this.selectedStatus);
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
    });
    this.checkBooking(this.data.id);
  }

  public onChangingStatus(status) {
    const res = this.status.find(s => s._id === status);
    if (res && res.name === 'Disponible') {
      this.isAvailable = true;
    } else {
      this.isAvailable = false;
    }
  }

  public checkBooking(id: string) {
    this.bookingService.getReservedDates(id).subscribe((data: any) => {
      if (data.object.length > 0) {
        this.hasBookings = true;
      }
    });
  }

  public updateStatus() {
    Swal.fire({
      icon: 'warning',
      title: '¿Seguro que desea actualizar el estatus del vehículo?',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitted = true;
        this.vehicleStatus.putVehicleStatus(this.data.id, this.selectedStatus).subscribe((data: any) => {
          this.submitted = false;
          Swal.fire({
            icon: 'success',
            text: data.message,
          });
          this.dialogRef.close("Ok");
        }, error => {
          Swal.fire({
            icon: 'error',
            title: error.error.title,
            text: error.error.message,
          });
          this.submitted = false;
        });
      }
    });

  }

  public closeDialog() {
    this.dialogRef.close("false");
  }
}
