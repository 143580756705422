<div *ngIf="Vehicle" class="col-md-10 ml-auto mr-auto">
  <div class="containerBody container-fluid">
    <div class="row mt-2">
      <div class="col-sm-12 col-md-4">
        <h2 class="card-title">
          {{ Vehicle.name | titlecase }}
        </h2>
        <hr />
        <div class="row text-center text-lg-start">
          <div class="col-12">
            <a href="#" class="d-block mb-4">
              <img
                class="img-fluid"
                [src]="Vehicle.images[0].url"
                alt="Principal image"
              />
            </a>
          </div>
          <div
            *ngFor="let image of Vehicle.images | slice : 1; let i = index"
            class="col-6"
          >
            <a href="#" class="d-block mb-4">
              <img class="img-fluid" [src]="image.urlThumbnail" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <form
          name="bookingForm"
          #bookingForm="ngForm"
          (ngSubmit)="
            bookingForm.valid &&
              fromControl.valid &&
              toControl.valid &&
              !invalidSelectedDate &&
              Process()
          "
        >
          <div class="general-data">
            <h3 class="secondary-color"><b>Datos del vehículo</b></h3>
            <div class="row">
              <div class="col">
                <p data-toggle="tooltip" data-placement="left" title="Tipo">
                  <img
                    class="icon-style"
                    src="../../../assets/img/icons/user.png"
                  />
                  <span class="img-title">{{
                    Vehicle.vehiclesTypes.name | titlecase
                  }}</span>
                </p>
                <p
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Combustible"
                >
                  <img
                    class="icon-style"
                    src="../../../assets/img/icons/gas-station.png"
                  />
                  <span class="img-title">{{
                    Vehicle.vehiclesFuel.name | titlecase
                  }}</span>
                </p>
              </div>
              <div class="col">
                <p
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Transmisión"
                >
                  <img
                    class="icon-style"
                    src="../../../assets/img/icons/transmission.svg"
                  />
                  <span class="img-title">Automatico</span>
                </p>
                <p>
                  <svg
                    style="margin-bottom: -3px; width: 18px; height: 18px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    class="nwdG-icon"
                    role="img"
                    cleanup=""
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M173.484 133.824l-11.015-6.488l7.75-2.119c10.241-2.802 6.103-18.612-4.147-15.807l-23.249 6.357c-24.647-14.513-11.332-3.975-15.563-22.369l15.563-9.164l23.249 6.357c10.243 2.803 14.4-13.002 4.147-15.807l-7.75-2.119l11.015-6.488c9.188-5.399 1.197-19.589-8.022-14.171l-11.015 6.488l2.078-7.904c2.748-10.467-12.752-14.683-15.499-4.238l-6.226 23.71l-15.564 9.164c-13.458-12.955-11.215 4.381-11.215-24.93l17.016-17.354c3.137-3.199 3.137-8.378 0-11.569c-6.561-6.708-12.765 1.45-17.016 5.785c0-9.357 2.051-21.158-8.022-21.158c-10.06 0-8.022 11.879-8.022 21.158c-4.146-4.228-10.41-12.539-17.016-5.785c-7.567 7.698 3.024 14.654 17.016 28.923c0 29.291 2.242 11.983-11.207 24.93l-15.563-9.164l-6.234-23.711c-2.748-10.446-18.23-6.237-15.499 4.238l2.078 7.904l-11.015-6.488c-9.194-5.403-17.218 8.767-8.022 14.171l11.015 6.488l-7.75 2.119c-10.254 2.806-6.095 18.612 4.156 15.807l23.249-6.357c24.691 14.539 11.267 4.004 15.563 22.369l-15.563 9.164l-23.249-6.357c-10.248-2.804-14.414 13-4.156 15.807l7.75 2.119l-11.015 6.488c-9.183 5.396-1.193 19.587 8.022 14.171l11.015-6.488l-2.078 7.904c-2.733 10.462 12.749 14.692 15.499 4.238l6.234-23.711l15.563-9.164c13.428 12.927 11.207-4.356 11.207 24.93l-17.016 17.354c-3.137 3.199-3.137 8.378 0 11.569c6.561 6.708 12.765-1.45 17.016-5.785c0 9.357-2.051 21.158 8.022 21.158c10.06 0 8.022-11.879 8.022-21.158c4.141 4.223 10.41 12.539 17.016 5.785c7.567-7.698-3.024-14.654-17.016-28.923c0-29.312-2.243-11.975 11.215-24.93l15.564 9.164l6.225 23.711c2.751 10.457 18.249 6.216 15.499-4.238l-2.078-7.904l11.015 6.488c9.227 5.422 17.2-8.778 8.023-14.17zm-63.064-27.688c-7.965 14.053-28.805 1.791-20.842-12.272c7.952-14.047 28.817-1.795 20.842 12.272z"
                    ></path>
                  </svg>
                  <!-- <img
                    class="icon-style"
                    src="../../../assets/img/icons/free.png"
                  /> -->
                  <span
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Aire acondicionado"
                    class="img-title"
                    >A/C</span
                  >
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div class="div-data">
            <h3 style="color: red"><b>Datos de la reserva</b></h3>
            <div class="row">
              <div class="col-md-10 col-sm-12">
                <mat-form-field class="booking-select-width">
                  <mat-select
                    placeholder="Punto de retiro / retorno"
                    name="pickupSite"
                    required
                    [(ngModel)]="booking.pickupSite"
                    #pickupSite="ngModel"
                    (selectionChange)="updateQueryParameters()"
                  >
                    <mat-option
                      *ngFor="let site of availableSites"
                      [value]="site?._id"
                    >
                      {{ site?.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="pickupSite.invalid"
                    >Este campo es obligatorio</mat-error
                  >
                </mat-form-field>
              </div>
              <!-- <div class="col">
                <mat-form-field class="booking-select-width">
                  <mat-select
                    placeholder="Punto de retorno"
                    name="deliverSite"
                    #deliverSite="ngModel"
                    [(ngModel)]="booking.deliverSite"
                    required
                  >
                    <mat-option
                      *ngFor="let site of availableSites"
                      [value]="site?._id"
                    >
                      {{ site?.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="deliverSite.invalid"
                    >Este campo es obligatorio</mat-error
                  >
                </mat-form-field>
              </div> -->
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <mat-form-field class="booking-select-width">
                  <mat-label>Fecha desde</mat-label>
                  <input
                    matInput
                    [min]="today"
                    [(ngModel)]="booking.fromDate"
                    [matDatepicker]="picker"
                    (dateInput)="changedDateEvent()"
                    [formControl]="fromControl"
                    [matDatepickerFilter]="dateFilter"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <!-- <div>
                  <mat-form-field class="booking-select-width">
                    <mat-label>Elige un rango de fecha</mat-label>
                    <mat-date-range-input
                      [rangePicker]="rangePicker"
                      [dateFilter]="myFilter"
                      [min]="today"
                      required
                    >
                      <input
                        matStartDate
                        placeholder="Start date"
                        [(ngModel)]="booking.fromDate"
                        [ngModelOptions]="{ standalone: true }"
                        [formControl]="dateControl"
                      />
                      <input
                        matEndDate
                        placeholder="End date"
                        [(ngModel)]="booking.toDate"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="rangePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                      <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>
                          Cancelar
                        </button>
                        <button
                          mat-raised-button
                          style="background-color: #0f387a; color: white"
                          matDateRangePickerApply
                          (click)="changedDateEvent()"
                        >
                          Aplicar
                        </button>
                      </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                    <mat-error *ngIf="dateControl.invalid"
                      >Este campo es obligatorio</mat-error
                    >
                  </mat-form-field>
                  <div
                    *ngIf="invalidSelectedDate"
                    style="color: red; font-size: 11px; margin-top: -10px"
                  >
                    Este vehículo ya tiene una reserva en el rango de estos
                    días.
                  </div>
                </div> -->
              </div>
              <div class="col-sm-12 col-md-5">
                <mat-form-field class="booking-select-width">
                  <mat-label>Fecha hasta</mat-label>
                  <input
                    matInput
                    [min]="booking.fromDate"
                    [(ngModel)]="booking.toDate"
                    [matDatepicker]="picker2"
                    (dateInput)="changedDateEvent()"
                    [formControl]="toControl"
                    [matDatepickerFilter]="dateFilter2"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-6">
                <mat-form-field class="booking-select-width">
                  <mat-select
                    placeholder="Hora retiro"
                    name="pickupHour"
                    required
                    [(ngModel)]="booking.fromHour"
                    (selectionChange)="updateQueryParameters()"
                  >
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{ hour }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-5 col-6">
                <mat-form-field class="booking-select-width">
                  <mat-select
                    placeholder="Hora retorno"
                    name="pickupToHour"
                    required
                    [(ngModel)]="booking.toHour"
                    (selectionChange)="updateQueryParameters()"
                  >
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{ hour }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <p class="p-days">
                  <span>{{ booking.days }} días</span>
                </p>
              </div>
            </div>
          </div>
          <hr style="margin-top: 1px" />
          <div class="div-data">
            <div class="row">
              <div class="col">
                <p>
                  ${{ Vehicle.priceByDay | number }} x {{ booking.days }} días
                </p>
              </div>
              <div class="col">
                <p>${{ Vehicle.priceByDay * booking.days | number }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>Tarifa de servicio</p>
              </div>
              <div class="col">
                <p>${{ ServiceTariff | number }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h3><b>Total</b></h3>
              </div>
              <div class="col">
                <p>
                  <b
                    >${{
                      Vehicle.priceByDay * booking.days + ServiceTariff | number
                    }}
                    {{ " " + Vehicle.coinType }}</b
                  >
                </p>
              </div>
            </div>
          </div>
          <hr style="margin-top: 1px" />
          <div class="row text-center mt-4">
            <div class="col-12">
              <button
                class="btn btn-primary"
                style="background-color: #f93259; width: 200px"
                mat-button
                type="submit"
              >
                Reservar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-4">
      <hr />
    </div>
  </div>
</div>

<!-- Not found -->
<div *ngIf="notFound">
  <app-fo-not-found [ErrorMessage]="error"></app-fo-not-found>
</div>

<ngx-loading [show]="submitted" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
