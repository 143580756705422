import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BoSubscriptionsService } from 'src/app/services/bo-subscriptions.service';
import { BookingsService } from 'src/app/services/bookings.service';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import Swal from 'sweetalert2';
import { BoVehiclesAddComponent } from '../../modals/bo-vehicles-add/bo-vehicles-add.component';
import { BoVehiclesStatusComponent } from '../../modals/bo-vehicles-status/bo-vehicles-status.component';

@Component({
  selector: 'app-bo-vehicles',
  templateUrl: './bo-vehicles.component.html',
  styleUrls: ['./bo-vehicles.component.css'],
})
export class BOVehiclesComponent implements OnInit {
  public submitted = false;
  public vehicles: any[];
  public vehiclesCopy: any[];
  public filter;
  private Plan: any;
  public hasPlan = true;
  constructor(
    private helperModal: MatDialog,
    private rcService: VehiclesRentCarService,
    private boSubsService: BoSubscriptionsService,
    private bookingService: BookingsService,
  ) { }

  ngOnInit(): void {
    this.getVehicles();
    this.getPlan();
  }

  public search() {
    this.vehiclesCopy = this.vehicles.filter((f) =>
      f.name.toLowerCase().includes(this.filter)
    );
  }

  public getPlan() {
    this.boSubsService.getPlanByCompany().subscribe((data: any) => {
      if (data.code === 404) {
        this.Plan = null;
        this.hasPlan = false;
      } else {
        this.Plan = data.object;
        this.hasPlan = true;
      }
    }, err => {
      if (err.error?.statusCode === 404) {
        this.Plan = null;
        this.hasPlan = false;
      }
      this.submitted = false;
    })
  }

  public getVehicles() {
    this.submitted = true;

    this.rcService.getAllVehiclesByRC().subscribe(
      (data: any) => {
        console.log(data);

        this.submitted = false;
        this.vehicles = data.object;
        for (let v of this.vehicles) {
          v = Object.assign(v, {
            name: v.make.name + ' ' + v.model.name + ' ' + v.year,
          });
        }
        this.vehiclesCopy = this.vehicles;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public openVehicleModal(id) {
    if (!id) {
      if (!this.Plan)
        return;
      if (this.vehicles.length === this.Plan.planId.carQty) {
        Swal.fire({
          icon: 'info',
          text: "El límite de vehículos permitidos por su subscripción ha sido alcanzado. Para agregar más vehículos actualice su plan.",
        });
        return;
      }

    }
    const vehiclesModal = this.helperModal.open(BoVehiclesAddComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      disableClose: true,
      data: { vehicle: id },
    });
    vehiclesModal.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'Ok') this.getVehicles();
      }
    });
  }

  public editVehicle(id) {
    this.openVehicleModal(id);
  }

  public changeStatus(data) {
    const statusModal = this.helperModal.open(BoVehiclesStatusComponent, {
      height: 'auto',
      maxWidth: '650px',
      maxHeight: '700px',
      minWidth: '350px',
      data: data,
    });
    statusModal.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'Ok') this.getVehicles();
      }
    });
  }

  public deleteVehicle(id) {
    this.submitted = true;
    this.bookingService.getReservedDates(id).subscribe((data: any) => {
      this.submitted = false;
      if (data.object.length > 0) {
        Swal.fire({
          icon: 'info',
          text: "Este vehículo tiene reservas programadas, no puede ser eliminado.",
        });
        return;
      } else {
        Swal.fire({
          icon: 'warning',
          title: '¿Seguro que desea eliminar este vehículo?',
          confirmButtonText: 'Si',
          showCancelButton: true,
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitted = true;
            this.rcService.deleteVehicleRentCar(id).subscribe((data: any) => {
              this.submitted = false;
              Swal.fire({
                icon: 'success',
                text: "Vehículo eliminado correctamente",
              });
              this.getVehicles();
            }, error => {
              Swal.fire({
                icon: 'error',
                title: error.error.title,
                text: error.error.message,
              });
              this.submitted = false;
            })
          }
        });
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
      this.submitted = false;
    })
  }

}
