export class BookingModel {
    public _id: string;
    public clientId: string;
    public vehicleId: string;
    public companyId: string;
    public priceByDay: number;
    public days = 0;
    public rcTotal: number;
    public serviceFee: number;
    public fromDate: Date;
    public toDate: Date;
    public fromHour = "08:00";
    public toHour = "18:00";
    public pickupSite: LocationSite;
    public deliverSite: LocationSite;
    public createdDate: Date;
    public coinType: string;
    constructor() {

    }
}

export class LocationSite {
    private _id: string;
    private name: string;

    constructor(_id: string, name: string) {
        this._id = _id;
        this.name = name;
    }
}