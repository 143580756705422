import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bo-home',
  templateUrl: './bo-home.component.html',
  styleUrls: ['./bo-home.component.css'],
})
export class BoHomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  log() {
    console.log('Hola');
  }
}
