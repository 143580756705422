import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RentCarPickUp } from 'src/app/models/fo/rent-car-pickup.models';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import actionsHelpers from "../../helper/actions-helper";
import SwiperCore, { Pagination, Navigation } from 'swiper'

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-rent-car',
  templateUrl: './rent-car.component.html',
  styleUrls: ['./rent-car.component.css'],
})
export class RentCarComponent implements OnInit {
  public submitted = false;
  public minDate: Date;
  public today = new Date();
  public startDate: Date;
  public endDate: Date;
  public slidesPerGroup = 0;
  public searchText= "";

  stateCtrl = new FormControl();
  filteredLocations: any[] = [];
  public selectedLocation = "";
  ngOnInit(): void {
    this.onResize(null);
  }
  constructor(
    private route: Router,
    private vehiclesRentCarService: VehiclesRentCarService,
  ) {
    this.minDate = new Date();
    this.stateCtrl.valueChanges.subscribe((location) => {
      this._filterLocations(location);
    });
  }

  public locations: RentCarPickUp[] = [];

  private _filterLocations(value: string) {
    if (value === '') return [];
    this.vehiclesRentCarService.getLocationsForPickup(value).subscribe(
      (data: any) => {
        this.filteredLocations = data.object;
      },
      (error) => {
        this.filteredLocations = [];
        console.log(error);
      }
    );
  }

  public setSelectedSite(site: any) {
    this.selectedLocation = site;
  }

  public search() {
    let data = {
      fromDay: this.startDate.getUTCDate(),
      fromMonth: actionsHelpers.getMonth(this.startDate),
      fromYear: this.startDate.getUTCFullYear(),
      toDay: this.endDate.getUTCDate(),
      toMonth: actionsHelpers.getMonth(this.endDate),
      toYear: this.endDate.getUTCFullYear(),
      locationId: this.filteredLocations.find(x => x.name === this.selectedLocation ?? "")._id
    }
    this.route.navigate(['fo/rent-cars/search', this.selectedLocation], {
      queryParams: actionsHelpers.cleanObject(data)
    });
  }

  public changedDateEvent() {
    if (this.endDate === null) {
      this.endDate = new Date(this.startDate);
      this.endDate.setDate(this.endDate.getDate() + 1);
    }
  }

  onSwiper([swiper]) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = window.innerWidth;
    if (width >= 1339)
      this.slidesPerGroup = 4;
    else if (width >= 997)
      this.slidesPerGroup = 3;
    else if (width >= 656)
      this.slidesPerGroup = 2;
    else
      this.slidesPerGroup = 1;
  }

}
