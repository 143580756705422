import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BookingModel, LocationSite } from 'src/app/models/fo/booking.model';
import { BookingsService } from 'src/app/services/bookings.service';
import { ClientsService } from 'src/app/services/clients.service';
import { CompaniesPickupService } from 'src/app/services/companies-pickup.service';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import { FoLoginComponent } from '../modals/fo-login/fo-login.component';
import actionsHelpers from "../../helper/actions-helper";
import { CreditCardModel } from 'src/app/models/credit-card.model';
import Swal from 'sweetalert2';
import { ScriptsService } from 'src/app/services/scripts.service';
import { info } from 'console';

// var client = require('braintree-web/client');
// var braintree = require('braintree-web');
declare var paypal;
const SCRIPT_PATH = "https://www.paypal.com/sdk/js?client-id=ATeA9yKpjfY0iosygdt18p44450XRf7lHqFTondRkPA164vcMWC22zB5eqi_ySikWFQ1VET1X5nTIX4p"
import fetch from 'node-fetch';
import { Globals } from 'src/app/global';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-fo-rentcar-confirm-and-pay',
  templateUrl: './fo-rentcar-confirm-and-pay.component.html',
  styleUrls: ['./fo-rentcar-confirm-and-pay.component.css']
})


export class FoRentcarConfirmAndPayComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  public Vehicle: any;
  public ServiceTariff: number;
  public availableSites;
  public booking: BookingModel;
  public submitted = false;
  public pickupSite: string;
  public deliverSite: string;
  public fromDate: string;
  public toDate: string;
  public notFound = false;
  public creditCard: CreditCardModel;
  public invalidCardDate: boolean = false;
  public invalidCard: boolean = false;
  public cardMask = "";
  public token;
  public error = 'Vehículo no encontrado - 404';
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public loginModal: MatDialog,
    private vehicleService: VehiclesRentCarService,
    private companyPickupService: CompaniesPickupService,
    public clientService: ClientsService,
    private bookingService: BookingsService,
    private renderer: Renderer2,
    private scriptService: ScriptsService,
    private globals: Globals,
    private paymentService: PaymentsService
  ) { }

  ngOnInit(): void {
    this.creditCard = new CreditCardModel();
    this.validateLoggin();
    this.booking = new BookingModel();
    this.submitted = true;
    this.route.params.subscribe(
      (params: Params) => (
        this.booking.vehicleId = params['id']
      )
    );
    this.route.queryParams.subscribe(
      (params: Params) => {

        if (!params.pickupId || !params.deliverId || !params.fromDay || !params.fromMonth || !params.fromYear ||
          !params.toDay || !params.toMonth || !params.toYear || !params.fromHour || !params.toHour
        ) {
          this.notFound = true; this.Vehicle = null;
        }
        this.companyPickupService.GetPickupById(params.pickupId).subscribe((data: any) => {
          if (params.pickupId === params.deliverId) {
            this.pickupSite = this.deliverSite = data.object.name;
            this.booking.pickupSite = this.booking.deliverSite = new LocationSite(params.pickupId, data.object.name);
          }
          else {
            this.booking.pickupSite = new LocationSite(params.pickupId, data.object.name);
            this.pickupSite = data.object.name;
          }

        })
        if (params.pickupId !== params.deliverId) {
          this.companyPickupService.GetPickupById(params.deliverId).subscribe((data: any) => {
            this.deliverSite = data.object.name;
            this.booking.deliverSite = new LocationSite(params.deliverId, data.object.name);
          });
        }


        // this.booking.fromDate = new Date(params.fromDate);
        this.booking.fromDate = new Date(params.fromYear, params.fromMonth, params.fromDay)
        this.booking.toDate = new Date(params.toYear, params.toMonth, params.toDay);
        this.booking.fromDate.setHours(params.fromHour.substring(0, 2));
        this.booking.toDate.setHours(params.toHour.substring(0, 2));


        console.log(this.booking.fromDate);
        console.log(this.booking.toDate);

        // this.booking.toDate = new Date(params.toDate);
        this.booking.days = Math.round(
          (this.booking.toDate.getTime() - this.booking.fromDate.getTime()) /
          (1000 * 60 * 60 * 24)
        );
        this.booking.days++;
        this.fromDate = params.fromDay + "-" + actionsHelpers.getFormattedMonth(this.booking.fromDate) + "-" + params.fromYear;
        this.toDate = params.toDay + "-" + actionsHelpers.getFormattedMonth(this.booking.toDate) + "-" + params.toYear;;
        this.booking.fromHour = params.fromHour;
        this.booking.toHour = params.toHour;
      }
    );
    this.vehicleService.getById(this.booking.vehicleId).subscribe(
      (data: any) => {
        this.submitted = false;
        this.Vehicle = data.object;
        this.Vehicle.name =
          this.Vehicle.make.name +
          ' ' +
          this.Vehicle.model.name +
          ' ' +
          this.Vehicle.year;
        this.booking.vehicleId = this.Vehicle._id;
        this.booking.coinType = this.Vehicle.coinType;
        this.ServiceTariff = this.Vehicle.priceByDay * this.booking.days * 0.10;
      },
      (err) => {
        this.Vehicle = null;
        this.submitted = false;
        this.notFound = true;
      }
    );
    this.initializatePaypalOptions();
    // this.getToken();
  }

  initializatePaypalOptions() {
    const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
    scriptElement.onload = () => {
      return paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
          label: 'pay',

        },
        createOrder: async () => {
          const total = (this.Vehicle.priceByDay * this.booking.days) + this.ServiceTariff;
          const id = await this.getOrderId(total, this.booking.coinType);
          return id;
        },

        onApprove: (data, actions) => {
          console.log(data);
          this.submitted = true;
          const channel = data.paymentSource;
          this.paymentService.captureOrder(data.orderID).subscribe((data: any) => {
            console.log(data);
            this.submitted = true;
            const paymentId = data.object.purchase_units[0].payments.captures[0].id;
            const params = Object.assign(this.booking,
              {
                paymentMethodNonce: paymentId,
                channel: channel === "card" ? "Card" : "Paypal",
                orderId: data.object.id,
                paymentInfo: data.object.payer.email_address
              });
            this.bookingService.postBooking(params).subscribe((data: any) => {
              Swal.fire({
                icon: 'success',
                title: 'En hora buena!',
                text:
                  'Su reserva ha sido confirmada',
              });
              this.submitted = false;

              this.router.navigate(['fo/client/bookings'], { replaceUrl: true });
            }, error => {
              this.submitted = false;
              console.log(error);
              Swal.fire({
                icon: 'error',
                title: error.error.title,
                text: error.error.message,
              });
            })
          }, error => {
            this.submitted = false;
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: error.error.title,
              text: error.error.message,
            });
          });
        },
        onCancel: function () {
          this.submitted = false;
        },

        onError: function (err) {
          this.submitted = false;

        }
      }).render('#paypal-button');
    }
    scriptElement.onerror = () => {
      console.log('Could not load the Paypal API Script!');
    }
  }

  async getOrderId(amount: number, coinType: string): Promise<string> {
    try {
      const response = await this.paymentService.createPaypalOrder(amount, coinType);
      return response.object.id; // respuesta de la solicitud POST
      // haz algo con la respuesta
    } catch (error) {
      console.error(error); // si hay un error
      return "";
    }
  }

  // initializePaypalButton(token: string) {
  //   client.create({
  //     authorization: token
  //   }).then(function (clientInstance) {
  //     return braintree.paypalCheckout.create({
  //       client: clientInstance
  //     });
  //   }).then((paypalCheckoutInstance) => {
  //     return paypal.Buttons({
  //       style: {
  //         shape: 'pill',
  //         color: 'gold',
  //         layout: 'vertical',
  //         label: 'pay',

  //       },
  //       createOrder: () => {
  //         if (!this.clientService.isUserFOLoggedIn) {
  //           this.validateLoggin();
  //           return;
  //         }
  //         return paypalCheckoutInstance.createPayment({
  //           flow: 'checkout',
  //           currency: this.booking.coinType,
  //           amount: (this.Vehicle.priceByDay * this.booking.days) + this.ServiceTariff,
  //           intent: 'capture'
  //         });
  //       },

  //       onApprove: (data, actions) => {
  //         return paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
  //           this.submitted = true;
  //           const params = Object.assign(this.booking, { paymentMethodNonce: payload.nonce, channel: "Paypal" });
  //           this.bookingService.postBooking(params).subscribe((data: any) => {
  //             Swal.fire({
  //               icon: 'success',
  //               title: 'En hora buena!',
  //               text:
  //                 'Su reserva ha sido confirmada',
  //             });
  //             this.submitted = false;
  //             this.router.navigate(['fo/client/bookings']);
  //           }, error => {
  //             this.submitted = false;
  //             console.log(error);
  //             Swal.fire({
  //               icon: 'error',
  //               title: error.error.title,
  //               text: error.error.message,
  //             });
  //           })
  //         });
  //       },
  //       onCancel: function () {
  //       },

  //       onError: function (err) {
  //       }
  //     }).render('#paypal-button');
  //   }).catch(function (err) {
  //     console.error('Error!', err);
  //   });
  //   // Create a client.
  // }

  getToken() {
    this.bookingService.generatePaymentToken().subscribe((data: any) => {
      this.token = data.object.token;
      const scriptElement = this.scriptService.loadJsScript(this.renderer, SCRIPT_PATH);
      scriptElement.onload = () => {
        // this.initializePaypalButton(data.object.token);
      }
      scriptElement.onerror = () => {
        console.log('Could not load the Paypal API Script!');
      }
    }, err => {
      console.log(err);
    })
  }

  validateMask(event: string) {
    if (event.charAt(0) === '4' || event.charAt(0) === '5' || event.charAt(0) === '6') {
      this.cardMask = "0000 0000 0000 0000"
    }
    else if (event.length > 2 && (event.substring(0, 2) === "34" || event.substring(0, 2) === "37")) {
      this.cardMask = "0000 000000 00000"
    }
    else
      this.cardMask = "";

  }

  public openLoginModal() {
    const dialogRef = this.loginModal.open(FoLoginComponent, {
      height: 'auto',
      width: 'auto',
      minWidth: '400px',
      maxHeight: '650px',
      panelClass: 'modal-transparent',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.message == "Ok") {
          this.loginModal.closeAll();
          // this.creditCard.email = result.data.client.email;
        } else {
          this.validateLoggin()
        }
      } else {
        this.validateLoggin();
      }
    });;
  }

  public validateLoggin() {
    if (!this.clientService.setLoginValue()) { this.openLoginModal(); }
  }

  goBack() {
    window.history.go(-1); return false;
  }

  public validateExpiresDate(expiresDate: string): boolean {
    if (expiresDate.length < 7) { this.invalidCardDate = false; return; }
    const today = new Date();
    let someday = new Date();
    someday.setFullYear(parseInt(expiresDate.substring(3, 7)), parseInt(expiresDate.substring(0, 2)), 1);
    this.invalidCardDate = someday < today;
  }

  onCardFocusOut() {
    if (this.creditCard.cardNumber.length > 0) {
      let cardType = actionsHelpers.creditCardType(this.creditCard.cardNumber);
      console.log(cardType);
      this.invalidCard = cardType === undefined;
    }
  }

  // processBooking() {
  //   if (!this.booking)
  //     return;
  //   if (!this.clientService.isUserFOLoggedIn) {
  //     this.validateLoggin();
  //     return;
  //   }
  //   this.submitted = true;
  //   client.create({
  //     authorization: this.token
  //   }, (createErr, clientInstance) => {
  //     if (createErr) {
  //       this.submitted = false;
  //       Swal.fire({
  //         icon: 'error',
  //         title: "Error",
  //         text: "Su pago no ha podido ser procesado",
  //       });
  //       console.log(createErr);
  //     }
  //     var data = {
  //       creditCard: {
  //         number: this.creditCard.cardNumber.replace(/ /g, ''),
  //         cvv: this.creditCard.cvv,
  //         expirationDate: this.creditCard.expiresDate,
  //         cardholderName: this.creditCard.cardholderName,
  //         options: {
  //           validate: false
  //         }
  //       }
  //     };

  //     clientInstance.request({
  //       endpoint: 'payment_methods/credit_cards',
  //       method: 'post',
  //       data: data
  //     }, (requestErr, response) => {
  //       // More detailed example of handling API errors: https://codepen.io/braintree/pen/MbwjdM
  //       if (requestErr) {
  //         this.submitted = false;
  //         Swal.fire({
  //           icon: 'error',
  //           title: "Error",
  //           text: "Su pago no ha podido ser procesado",
  //         });
  //         console.log(requestErr);
  //       }
  //       const params = Object.assign(this.booking, { paymentMethodNonce: response.creditCards[0].nonce, channel: "Card" });
  //       this.bookingService.postBooking(params).subscribe((data: any) => {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'En hora buena!',
  //           text:
  //             'Su reserva ha sido confirmada',
  //         });
  //         this.submitted = false;
  //         this.router.navigate(['fo/client/bookings'], { replaceUrl: true });
  //       }, error => {
  //         this.submitted = false;
  //         console.log(error);
  //         Swal.fire({
  //           icon: 'error',
  //           title: error.error.title,
  //           text: error.error.message,
  //         });
  //       })
  //       // $.ajax({
  //       //   type: 'POST',
  //       //   url: 'http://localhost:8888/api/pay',
  //       //   data: { 'paymentMethodNonce': response.creditCards[0].nonce }
  //       // }).done(function (result) {
  //       //   console.log(result);
  //       // });
  //     });
  //   });



  // }
}
