import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersBOService } from 'src/app/services/users-bo.service';
import { BoChangePasswordComponent } from '../modals/bo-change-password/bo-change-password.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-bo-navbar',
  templateUrl: './bo-navbar.component.html',
  styleUrls: ['./bo-navbar.component.css'],
})
export class BoNavbarComponent implements OnInit {
  public userBoName;
  constructor(private userBoService: UsersBOService, private helperModal: MatDialog) { }

  ngOnInit(): void {
    this.getLoggedInfo();
  }

  public Logout() {
    this.userBoService.logout();
  }

  public getLoggedInfo() {
    this.userBoService.getLoggedInfo().subscribe(
      (data: any) => {
        this.userBoName = data.object.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public openModal() {
    this.helperModal.open(BoChangePasswordComponent, {
      height: 'auto',
    });
  }
}
