import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';
import { BoForgotPasswordComponent } from '../modals/bo-forgot-password/bo-forgot-password.component';

@Component({
  selector: 'app-bo-login',
  templateUrl: './bo-login.component.html',
  styleUrls: ['./bo-login.component.css'],
})
export class BoLoginComponent implements OnInit {
  test: Date = new Date();
  public Password: string;
  public Username: string;
  public submitted = false;
  constructor(
    private router: Router,
    public userBoServices: UsersBOService,
    private helperModal: MatDialog,
    ) { }

  ngOnInit(): void {
    this.Password = '';
    this.Username = '';
  }
  public goHome() {
    this.router.navigate(['fo/rent-cars']);
  }

  Login() {
    if (this.Username == '' || this.Password == '') {
      Swal.fire({
        icon: 'info',
        title: 'Atención',
        text: 'Para continuar por favor llene los campos.',
      });
      return;
    }

    this.submitted = true;
    this.userBoServices.login(this.Username, this.Password).subscribe(
      (data: any) => {
        this.submitted = false;
        localStorage.setItem('TokenBO', data.object);
        this.userBoServices.isUserBOLoggedIn = true;
        this.submitted = false;
        this.router.navigate(['bo/home']);
      },
      (error) => {
        console.log(error);
        this.submitted = false;
        Swal.fire({
          icon: 'error',
          title: error.error.title,
          text: error.error.message,
        });
      }
    );
  }


  public openModal() {
    this.helperModal.open(BoForgotPasswordComponent, {
     height: 'auto',
     // maxWidth: '650px',
     // maxHeight: '700px',
     // minWidth: '350px',
   });
 }
}
