export class Globals {
    // private readonly urlApiGateway = `https://api-node-gateway.herokuapp.com/`;
    // private readonly urlApiGateway = `http://localhost:3999/`;
    a = {
        Pruebas: "http://localhost:8888/",
        PruebasRemote: "https://api-cf-dev.up.railway.app/api/",
        Produccion: " https://carrosfacil-api.ngrok.io/api/",
        API: `http://localhost:3003/api/`,
    };
    public urlApi = this.a["PruebasRemote"];

    public extractData(res: Response) {
        const body = res;
        return body || {};
    }
}
