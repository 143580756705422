import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})
export class VehiclesStatusService {

  constructor(private http: HttpClient, private globals: Globals) { }
  public getAll() {
    const url_api =
      this.globals.urlApi + `vehicles-status`;
    return this.http.get(url_api);
  }

  public putVehicleStatus(vehicleId: string, statusId: string) {
    const data = { vehicleId, statusId };
    const urlAPI = this.globals.urlApi + `vehicles-rent-cars/change-status`;
    return this.http.put(urlAPI, JSON.stringify(data));
  }
}
