import { Routes } from '@angular/router';
import { BoAccountComponent } from './bo/bo-account/bo-account.component';
import { BoHomeComponent } from './bo/bo-home/bo-home.component';
import { BoLoginComponent } from './bo/bo-login/bo-login.component';
import { BoVehiclesAddComponent } from './bo/modals/bo-vehicles-add/bo-vehicles-add.component';
import { BOVehiclesComponent } from './bo/bo-rentcars/bo-vehicles/bo-vehicles.component';
import { BoSingupComponent } from './bo/bo-singup/bo-singup.component';
import { BoPickupConfigComponent } from './bo/bo-rentcars/bo-pickup-config/bo-pickup-config.component';
import { BoAccountInformationComponent } from './bo/bo-account-information/bo-account-information.component';
import { BoSubscriptionComponent } from './bo/bo-subscription/bo-subscription.component';
import { BoBookingsComponent } from './bo/bo-rentcars/bo-bookings/bo-bookings.component';
import { BoResetPasswordComponent } from './bo/bo-reset-password/bo-reset-password.component';
import { BoPaymentsComponent } from './bo/bo-rentcars/bo-payments/bo-payments.component';

export const BoRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        component: BoAccountComponent,
      },
      {
        path: 'login',
        component: BoLoginComponent,
      },
      {
        path: 'signup',
        component: BoSingupComponent,
      },
      {
        path: 'account',
        component: BoAccountComponent,
      },
      {
        path: 'account-information',
        component: BoAccountInformationComponent,
      },
      {
        path: 'rentcars/vehicles',
        component: BOVehiclesComponent,
      },
      {
        path: 'rentcars/vehicles-add',
        component: BoVehiclesAddComponent,
      },
      {
        path: 'rentcars/pickup-config',
        component: BoPickupConfigComponent,
      },
      {
        path: 'rentcars/bookings',
        component: BoBookingsComponent,
      },
      {
        path: 'rentcars/payments',
        component: BoPaymentsComponent,
      },
      {
        path: 'subscription',
        component: BoSubscriptionComponent
      },
      {
        path:'reset-password/:code',
        component: BoResetPasswordComponent
      }
    ],
  },
];
