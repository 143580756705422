<section class="instagram spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="instagram__pic">
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-1.jpg">
                    </div>
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-2.jpg">
                    </div>
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-3.jpg">
                    </div>
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-4.jpg">
                    </div>
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-5.jpg">
                    </div>
                    <div class="instagram__pic__item set-bg">
                        <img src="../../assets/img/instagram/instagram-6.jpg">
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="instagram__text">
                    <h2>Instagram</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                    <h3>#CarrosFacil</h3>
                </div>
            </div>
        </div>
    </div>
</section>