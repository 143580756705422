import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompanyModel } from 'src/app/models/bo/company.model';
import { CitiesService } from 'src/app/services/cities.service';
import { CountriesService } from 'src/app/services/countries.service';
import { UsersBOService } from 'src/app/services/users-bo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-account-information',
  templateUrl: './bo-account-information.component.html',
  styleUrls: ['./bo-account-information.component.css']
})
export class BoAccountInformationComponent implements OnInit {
  public user: CompanyModel;
  public countries;
  public cities;
  public selectCountries = new FormControl('', [Validators.required]);
  public selectCities = new FormControl('', [Validators.required]);
  public submitted = false;
  public mail: string;

  constructor(
    public countryServices: CountriesService,
    public cityServices: CitiesService,
    public route: Router,
    private userBoService: UsersBOService
  ) { }

  ngOnInit(): void {
    this.user = new CompanyModel();
    this.getUserInfo();
  }

  public getCountries() {
    this.countryServices.GetCountries().subscribe(
      (data: any) => {
        this.countries = data.object;
        this.getCities(this.user.city.country._id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public getUserInfo() {
    this.submitted = true;
    this.userBoService.getLoggedInfo().subscribe(
      (data: any) => {
        this.submitted = false;
        this.getCountries();
        this.user = data.object;
        this.mail = data.object.user.email;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public getCities(value: string
  ) {
    this.submitted = true;
    this.cityServices.GetByCountry(value).subscribe(
      (data: any) => {
        this.cities = data.object;
        this.submitted = false;
      },
      (error) => {
        this.submitted = false;
        console.log(error);
      }
    );
  }

  public updateBOUser() {
    this.submitted = true;
    this.user.cityId = this.user.city._id;
    this.userBoService.putBoUser(this.user).subscribe((data: any) => {
      this.submitted = false;
      Swal.fire({
        icon: 'success',
        title: 'Correcto',
        text:
          'Se han guardado sus cambios correctamente',
      });
    }, error => {
      Swal.fire({
        icon: 'error',
        title: "Error",
        text: error.error.message,
      });
      this.submitted = false;
    })
  }

  public cancel() {
    this.route.navigate(['bo/account']);
  }
}
