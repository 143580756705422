<!-- <nav class="navbar navbar-expand-lg bg-white navbar-absolute" color-on-scroll="500"> -->
<nav class="navbar navbar-expand-lg bg-white fixed-top" color-on-scroll="500">
  <div class="container">
    <div class="navbar-translate">
      <a routerLink="/fo/rent-cars" (click)="closeFoLoginModal()"
        ><img
          src="https://res.cloudinary.com/carros-facil/image/upload/v1653004684/assets/FULL_LOGO_HORIZONTAL_ga8nlh.png"
          alt=""
          style="width: 92px"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="collapse navbar-collapse" style="margin-left: 100px">
      <ul class="navbar-nav">
        <!-- <li class="nav-item" routerLinkActive="active">
          <a
            class="nav-link hvr-underline-from-left"
            style="color: black"
            href=""
          >
            <i class="fa fa-home"></i
            ><span style="font-weight: 500">Inicio</span>
          </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active">
          <a
            class="nav-link hvr-underline-from-left"
            style="color: black"
            routerLink="/fo/rent-cars"
            (click)="closeFoLoginModal()"
          >
            <i class="fa fa-credit-card"></i
            ><span style="font-weight: 500">Rent a Car</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a
            class="nav-link hvr-underline-from-left"
            style="color: black"
            routerLink="/fo/dealers"
            (click)="closeFoLoginModal()"
          >
            <i class="fa fa-car"></i
            ><span style="font-weight: 500">Vehículos</span>
          </a>
        </li>
        <li class="nav-item"></li>
      </ul>
      <div class="navbar-nav ml-auto">
        <div class="nav-item" *ngIf="!clientService.isUserFOLoggedIn">
          <a
            class="nav-link hvr-underline-from-left"
            style="color: black; margin-right: 20px"
            routerLink="/bo/login" (click)="closeFoLoginModal()"
          >
            <b>Eres negocio?</b>
          </a>
        </div>
        <div class="nav-item" *ngIf="clientService.isUserFOLoggedIn">
          <a
            class="nav-link hvr-underline-from-left"
            style="color: black; margin-right: 9px"
            routerLink="/fo/client/account"
          >
            <b>{{ name }} ;)</b>
          </a>
        </div>
        <div class="dropdown set-Center login-div">
          <mat-icon>menu</mat-icon>
          <mat-icon style="margin-left: 3px">account_circle</mat-icon>
          <div class="dropdown-content">
            <div *ngIf="!clientService.isUserFOLoggedIn">
              <a
                style="cursor: pointer"
                class="dropdown-item hvr-grow"
                (click)="openFoLoginModal()"
                >Inicia sesión</a
              >
              <a
                style="cursor: pointer"
                class="dropdown-item hvr-grow"
                routerLink="/fo/signup"
                (click)="closeFoLoginModal()"
                >Crea tu cuenta</a 
              >
            </div>
            <div *ngIf="clientService.isUserFOLoggedIn">
              <a
                style="cursor: pointer"
                class="dropdown-item hvr-grow"
                routerLink="/fo/client/account"
                >Tu cuenta</a
              >
              <a
                style="cursor: pointer"
                class="dropdown-item hvr-grow"
                routerLink="/fo/client/bookings"
                >Reservas</a
              >
              <a
                style="cursor: pointer"
                class="dropdown-item hvr-grow"
                (click)="logout()"
                >Cerrar sesión</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
