<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <a routerLink="/fo/rent-cars">
        <img
          class="d-block w-100"
          src="https://res.cloudinary.com/carros-facil/image/upload/v1658924413/assets/rent-car_banner_pzdkfx.png"
          alt="First banner slide"
        />
      </a>
    </div>
    <div class="carousel-item">
      <a routerLink="/fo/rent-cars">
        <img
          class="d-block w-100"
          src="https://res.cloudinary.com/carros-facil/image/upload/v1658924416/assets/rent-car_banner_2_bbbzqn.png"
          alt="Second banner slide"
      /></a>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="#carouselExampleControls"
    role="button"
    data-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#carouselExampleControls"
    role="button"
    data-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<div style="margin-top: 40px; margin-bottom: 40px">
  <app-fo-ins-section> </app-fo-ins-section>
</div>
<app-fo-footer></app-fo-footer>
