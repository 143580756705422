import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VehiclesRentCarService } from 'src/app/services/vehicles-rent-car.service';
import actionsHelpers from "../../helper/actions-helper";
@Component({
  selector: 'app-fo-rentcars-search',
  templateUrl: './fo-rentcars-search.component.html',
  styleUrls: ['./fo-rentcars-search.component.css'],
})
export class FoRentcarsSearchComponent implements OnInit {
  public vehicles: any[];
  public vehiclesCopy: any[];
  public showFiller = true;
  stateCtrl = new FormControl();
  filteredLocations: any[] = [];
  public selectedLocation = "";
  public searchText= "";
  public startDate: Date;
  public endDate: Date;
  public submitted = false;
  public selectedParams: any;
  public today = new Date();
  public filters = [];
  public checkedAll = {
    vehiclesTypes: true,
    vehiclesFuelType: true,
    vehiclesPrices: true,
  }
  constructor(
    private routeActivate: ActivatedRoute,
    private rentCarService: VehiclesRentCarService,
    public router: Router
  ) {
    this.stateCtrl.valueChanges.subscribe((location) => {
      this._filterLocations(location);
    });
  }
  ngOnInit(): void {
    this.routeActivate.queryParams.subscribe(
      (params: Params) => {
        this.selectedParams = {
          fromYear: params.fromYear,
          fromMonth: params.fromMonth,
          fromDay: params.fromDay,
          toYear: params.toYear,
          toMonth: params.toMonth,
          toDay: params.toDay,
          locationId: params.locationId
        };
        if (params.fromYear, params.fromMonth, params.fromDay) {
          this.startDate = new Date(params.fromYear, params.fromMonth, params.fromDay)
          if (!this.startDate) {
            this.startDate = new Date();
          }
        }
        else {
          this.startDate = new Date();
          this.updateQueryParameters();
        }

        if (params.toYear, params.toMonth, params.toDay) {
          this.endDate = new Date(params.toYear, params.toMonth, params.toDay);
        }
        else {
          this.endDate = new Date();
          this.endDate.setDate(this.startDate.getDate() + 1);
          this.updateQueryParameters();
        }
        this.getAvailableVehicles(params.locationId);
      }
    );
    this.routeActivate.params.subscribe(
      (params: Params) => {
        if (params.location) {
          this.stateCtrl.setValue(params.location);
          this.selectedLocation = params.location;
        }

      }
    );

  }
  private _filterLocations(value: string) {
    if (value === '') return [];
    this.rentCarService.getLocationsForPickup(value).subscribe(
      (data: any) => {
        this.filteredLocations = data.object;
      },
      (error) => {
        this.filteredLocations = [];
        console.log(error);
      }
    );
  }

  getAvailableVehicles(pickupId: string) {
    this.submitted = true;
    this.rentCarService.getAvailableVehicles(pickupId, this.startDate, this.endDate).subscribe(
      (data: any) => {

        this.vehicles = data.object;
        for (let v of this.vehicles) {
          v = Object.assign(v, {
            name: v.make.name + ' ' + v.model.name + ' ' + v.year,
          });
        }
        this.vehiclesCopy = this.vehicles;
        this.submitted = false;

      },

      (error) => {
        console.log(error);
        this.submitted = false;
      }
    );
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
  public setSelectedSite(site: any) {
    this.selectedLocation = site;
    const locationId = this.filteredLocations.find(x => x.name === this.selectedLocation ?? "")._id;
    // this.getAvailableVehicles(locationId);
    this.selectedParams.locationId = locationId;
    this.router.navigate([`../${site}`], { relativeTo: this.routeActivate, queryParams: actionsHelpers.cleanObject(this.selectedParams) });
  }

  public setDateParameters() {
    if (this.endDate < this.startDate) {
      this.endDate.setDate(this.startDate.getDate() + 1);
    }

    if (this.startDate) {
      this.selectedParams.fromYear = this.startDate.getUTCFullYear(),
        this.selectedParams.fromMonth = actionsHelpers.getMonth(this.startDate),
        this.selectedParams.fromDay = this.startDate.getUTCDate()
    }
    if (this.endDate) {
      this.selectedParams.toYear = this.endDate.getUTCFullYear(),
        this.selectedParams.toMonth = actionsHelpers.getMonth(this.endDate),
        this.selectedParams.toDay = this.endDate.getUTCDate()
    }
    // this.selectedParams.fromDate = actionsHelpers.setDateToFormat(this.startDate);
    // this.selectedParams.toDate = actionsHelpers.setDateToFormat(this.endDate);
    this.updateQueryParameters();
  }

  public updateQueryParameters() {
    if (!this.startDate) {
      this.startDate = new Date();
    }

    if (!this.endDate) {
      this.endDate.setDate(this.startDate.getDate() + 1);
    }
    let data = {
      fromDay: this.startDate.getUTCDate(),
      fromMonth: actionsHelpers.getMonth(this.startDate),
      fromYear: this.startDate.getUTCFullYear(),
      toDay: this.endDate.getUTCDate(),
      toMonth: actionsHelpers.getMonth(this.endDate),
      toYear: this.endDate.getUTCFullYear(),
    };
    this.router.navigate(
      [],
      {
        relativeTo: this.routeActivate,
        queryParams: actionsHelpers.cleanObject(data),
        queryParamsHandling: 'merge',
      });

  }

  public updateFilters(event: any, type: string, operator: string, typeChild?: string): void {
    this.removeFilter(type);
    if (event.value !== 'All') {
      const obj = !typeChild ? { type, operator, value: event.value } : { type, operator, value: event.value, typeChild }
      this.filters.push(obj);
      this.applyFilters();
    }
  }

  private removeFilter(type: string): void {
    this.filters.forEach(element => {
      if (element.type === type) {
        const item = element;
        const index = this.filters.indexOf(item);
        if (index > -1) {
          this.filters.splice(index, 1);
        }
      }
    });

    this.applyFilters();


  }

  private applyFilters() {
    if (this.filters.length === 0) {
      this.vehiclesCopy = this.vehicles;
      return;
    }
    let filtered = this.vehicles;
    for (let i = 0; i < this.filters.length; i++) {
      filtered = filtered.filter((resultado) => {
        if (this.filters[i].operator === '==')
          return (this.filters[i].typeChild ? resultado[this.filters[i].type][this.filters[i].typeChild] : resultado[this.filters[i].type]) === this.filters[i].value;
        else if (this.filters[i].operator === '>') {
          return (this.filters[i].typeChild ? resultado[this.filters[i].type][this.filters[i].typeChild] : resultado[this.filters[i].type]) >= this.filters[i].value;
        }
        else if (this.filters[i].operator === '<') {
          return (this.filters[i].typeChild ? resultado[this.filters[i].type][this.filters[i].typeChild] : resultado[this.filters[i].type]) <= this.filters[i].value;

        }
      });
    }
    //   if (res.length > 0)
    //     filtered = [...filtered, ...res];
    // }
    //Quitar duplicados
    this.vehiclesCopy = filtered;//.filter((elemento, indice) => filtered.indexOf(elemento) === indice);

  }

  public orderBy(value: string) {
    const obj = value.split(' ');
    if (obj[1] === 'asc') {
      this.vehiclesCopy = this.vehiclesCopy.sort(this.orderByAsc(obj[0]));
    }else{
      this.vehiclesCopy = this.vehiclesCopy.sort(this.orderByDesc(obj[0]));
    }

  }

  private orderByAsc(property) {
    return function (a, b) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  private orderByDesc(property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return -1;
      } else if (a[property] < b[property]) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  filterObjectByProperty(obj, prop, value, propChild?) {
    return obj.filter(function (item) {
      if (propChild)
        return item[prop][propChild] === value;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showFiller = window.screen.availWidth >= 768;
  }
}
