import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingsService } from 'src/app/services/bookings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bo-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.css']
})
export class BookingDetailComponent implements OnInit {
  public booking: any;
  public submitted = false;
  public isRentCar = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<BookingDetailComponent>,
    private service: BookingsService,
  ) { }

  ngOnInit(): void {
    this.isRentCar = this.data.isRentCar;
    this.getBooking(this.data.bookingId);
  }

  private getBooking(id: string) {
    this.submitted = true;
    this.service.getById(id).subscribe((data: any) => {
      this.booking = data.object;
      this.submitted = false;
    }, error => {
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: error.error.title,
        text: error.error.message,
      });
      this.closeDialog();
    });
  }

  public closeDialog() {
    this.dialogRef.close("false");
  }


}
