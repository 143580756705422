import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CompaniesPickupService {

  constructor(
    private http: HttpClient,
    private globals: Globals) {
  }


  public savePickups(sites: any) {
    const urlAPI = this.globals.urlApi + `companies-pickup`;
    return this.http.post(urlAPI, sites);
  }

  public GetPickups() {
    const url_api =
      this.globals.urlApi + `companies-pickup/pickups`;
    return this.http.get(url_api);
  }

  public GetPickupById(id: string) {
    const url_api =
      this.globals.urlApi + `cities-pickup/getById/${id}`;
    return this.http.get(url_api);
  }

  public GetPickupsByCompany(companyId: string) {
    const url_api =
      this.globals.urlApi + `companies-pickup/getPickupsByCompany/${companyId}`;
    return this.http.get(url_api);
  }
}
